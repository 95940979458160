import React from 'react';
import { Solution } from '../../models/SolutionModel';
import { updateDocument } from '../../components/firebase';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  solution: Solution;
}

export function ProjectSolution({ solution }: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(async (payload) => {
    await updateDocument(payload.ref, payload.properties);
  }, 500);

  return (
    <div>
      <div className="mt-2">
        <input
          id="about"
          name="about"
          type="text"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="e.g. Marketology creates the right marketing content for your project."
          defaultValue={solution.title}
          onChange={(e) => {
            debouncedPropertiesUpdate({
              ref: solution.ref,
              properties: {
                title: e.target.value,
              },
            });
          }}
        />
      </div>
    </div>
  );
}
