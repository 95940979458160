import './App.css';

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import React, { useEffect } from 'react';

import Loading from './screens/Loading/Loading';
import { MainLayout } from './layout/MainLayout';
import { SignIn } from './screens/SignIn/SignIn';
import { auth } from './components/firebase';
import dayjs from 'dayjs';
import { selectUser } from './app/userSlice';
import { useAppSelector } from './app/hooks';
import { useAuthState } from 'react-firebase-hooks/auth';

function App() {
  const [user, loading, error] = useAuthState(auth);

  const appUser = useAppSelector(selectUser);

  useEffect(() => {
    if (!appUser) {
      return;
    }

    let userName = appUser.firstName;
    if (appUser.lastName) {
      userName += ' ' + appUser.lastName;
    }

    window.Intercom('boot', {
      api_base: process.env.REACT_APP_INTERCOM_API_BASE,
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: appUser.id,
      name: userName ?? '',
      email: appUser.email ?? '',
      user_hash: appUser.intercomHMAC ?? '',
      created_at: dayjs(appUser.createdAt).unix(),
    });
  }, [appUser]);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!user) {
    return (
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </div>
    );
  }

  return (
    <div>
      <Router>
        <MainLayout />
      </Router>
    </div>
  );
}

export default App;
