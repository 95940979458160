import React, { DetailedHTMLProps, useState } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  htmlId?: string;
  htmlName?: string;
  text: string;
  placeholder?: string;
  textComponentType: DetailedHTMLProps<any, any>;
  styleClasses?: string;
  textareaStyleClasses?: string;
  index?: number;
  isEditing: boolean;
  onValueChange?: (value: string) => void;
  onRemove?: (index?: number) => void;
}

export function EditableTextField({
  htmlId,
  htmlName,
  text,
  placeholder,
  textComponentType,
  styleClasses = '',
  textareaStyleClasses = '',
  index,
  isEditing,
  onValueChange,
  onRemove,
}: Props) {
  const [localText, setLocalText] = useState(text);
  const TextComponent = textComponentType;

  return (
    <div className={`flex text-gray-900`}>
      <div className="relative w-full p-0 m-0">
        <TextComponent
          className={twMerge(
            'p-0 m-0 min-w-[20px] min-h-[15px] whitespace-pre-wrap',
            styleClasses
          )}
        >
          {localText}
        </TextComponent>

        <div className="absolute p-0 m-0 border-0 inset-0 flex-1 justify-center items-center z-1">
          {isEditing && (
            <textarea
              id={htmlId}
              name={htmlName}
              className={twMerge(
                'p-0 m-0 w-full h-full border-0 resize-none overflow-hidden bg-white hover:bg-slate-100 rounded-md',
                styleClasses,
                textareaStyleClasses
              )}
              placeholder={placeholder}
              value={localText}
              onChange={(e) => {
                setLocalText(e.target.value);
                onValueChange?.(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && localText === '') {
                  onRemove?.(index);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
