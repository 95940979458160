import React, { useState } from 'react';

import ConfirmationModal from '../../../components/ConfirmationModal';
import DropdownMenu from '../../../components/DropdownMenu';
import TitleCard from '../../../components/CardComponents/TitleCard';
import { TrashIcon } from '@heroicons/react/20/solid';
import { TwitterTweet } from '../../../models/TwitterTweetModel';
import { deleteDocument } from '../../../components/firebase';

interface Props {
  twitterTweet: TwitterTweet;
  onClick?: () => void;
}

export function TwitterTweetCard({ twitterTweet, onClick }: Props) {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  async function deleteTwitterTweet() {
    await deleteDocument(twitterTweet.ref);
  }

  const card = (
    <li
      key={twitterTweet.id}
      className={`col-span-1 flex flex-col bg-slate-100 rounded-xl shadow`}
    >
      <div className="flex flex-col">
        {/* Title */}

        <div className="flex flex-row flex-wrap align-middle justify-between rounded-md gap-2 mt-1 h-8 text-slate-500">
          <h3 className="my-auto mx-4 text-sm">
            <span className="font-semibold">Tweet</span>
          </h3>

          <div className="flex flex-row align-middle my-auto mr-3">
            <div className="my-auto">
              <DropdownMenu
                items={[
                  // {
                  //   title: 'Export',
                  //   icon: ArrowUpOnSquareStackIcon,
                  //   onClick: async () => {
                  //     exportCard();
                  //   },
                  // },
                  {
                    title: 'Delete',
                    icon: TrashIcon,
                    color: 'text-red-500',
                    onClick: async () => {
                      setDeleteConfirmationOpen(true);
                    },
                  },
                ]}
              />
            </div>
            <ConfirmationModal
              title="Delete Website Page?"
              description="Are you sure you want to delete this website page? You can't undo this action."
              open={deleteConfirmationOpen}
              setOpen={setDeleteConfirmationOpen}
              onConfirm={async () => {
                await deleteTwitterTweet();
              }}
            />
          </div>
        </div>

        <div
          className="flex flex-1 flex-row p-4 pt-1 gap-4 cursor-pointer"
          onClick={onClick}
        >
          <div className="flex flex-col shrink gap-4">
            <div>
              <TitleCard
                text={twitterTweet.tweet || ''}
                docRef={twitterTweet.ref}
                updatePath="title"
                isEditing={false}
              />
            </div>
          </div>
        </div>
      </div>
    </li>
  );

  return card;
}
