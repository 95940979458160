import React, { useEffect, useState } from 'react';
import { db, doc, updateDocument } from '../../../components/firebase';

import { Card } from '../../../components/Card';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { selectUser } from '../../../app/userSlice';
import { useAppSelector } from '../../../app/hooks';

export default function SettingsProfile() {
  const user = useAppSelector(selectUser);

  const [firstName, setFirstName] = useState<string | undefined>(
    user?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(user?.lastName);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
    }
  }, [user]);

  async function saveUserDetails() {
    await updateDocument(doc(db, 'users', user?.id || ''), {
      firstName: firstName || '',
      lastName: lastName || '',
    });

    // let name = firstName || '';
    // if (lastName) {
    //   name += ' ' + lastName;
    // }

    // Intercom('update', { name });
  }

  return (
    <Card shadow={true}>
      <div className="flex flex-col justify-start">
        <div className="flex flex-row flex-grow gap-4 flex-wrap">
          <div className="flex-grow">
            <label className="block text-md font-medium text-gray-700">
              First Name
            </label>
            <div className="mt-2">
              <input
                key={'first-name'}
                id="firstName"
                name="firstName"
                type="text"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                defaultValue={user?.firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex-grow">
            <label className="block text-md font-medium text-gray-700">
              Last Name
            </label>
            <div className="mt-2">
              <input
                key={'last-name'}
                id="lastName"
                name="lastName"
                type="text"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                defaultValue={user?.lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-8">
          <label className="block text-md font-medium text-gray-700">
            Email
          </label>
          <div className="mt-2">
            <input
              key={'email'}
              id="email"
              name="email"
              type="text"
              className="block w-full rounded-md border-gray-300 bg-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={user?.email}
              disabled={true}
            />
          </div>
        </div>

        <div className="mt-8">
          <PrimaryButton
            title={'Save'}
            disabled={
              firstName === user?.firstName && lastName === user?.lastName
            }
            onClick={saveUserDetails}
          />
        </div>
      </div>
    </Card>
  );
}
