import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  shadow?: boolean;
  styleClasses?: string;
  children: React.ReactNode;
}

export function Card({ shadow = true, styleClasses, children }: Props) {
  return (
    <div
      className={twMerge(
        'bg-white p-4 w-full h-full rounded-md',
        shadow ? 'shadow' : '',
        styleClasses
      )}
    >
      {children}
    </div>
  );
}
