import React from 'react';

interface Props {
  title: string;
  icon?: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }
  >;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function SecondaryButton({ title, icon: Icon, onClick }: Props) {
  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 "
      onClick={onClick}
    >
      {Icon && <Icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      {title}
    </button>
  );
}
