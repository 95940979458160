import { Feature } from '../../models/FeatureModel';
import React from 'react';

interface Props {
  feature: Feature;
  payload: any;
  setPayload: (payload: any) => void;
  stateChangedHandler?: (selected: boolean) => void;
}

export function ProjectFeatureSelection({
  feature,
  payload,
  setPayload,
  stateChangedHandler,
}: Props) {
  function updateState(selected: boolean) {
    const features = new Set(payload.features);
    if (selected) {
      features.add(feature.id);
    } else {
      features.delete(feature.id);
    }
    setPayload({ ...payload, features: features });
    stateChangedHandler?.(selected);
  }

  return (
    <div>
      <div className="mt-2">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              key={feature.id}
              id={`item-${feature.id}`}
              aria-describedby={`item-description-${feature.id}`}
              name={`item-${feature.id}`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={payload.features.has(feature.id) || false}
              onChange={(e) => updateState(e.target.checked)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor={`item-${feature.id}`}
              className="font-medium text-gray-700"
            >
              {feature.title}
            </label>
            {feature.description && (
              <p
                id={`item-description-${feature.id}`}
                className="text-gray-500"
              >
                {feature.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
