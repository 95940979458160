import * as React from "react";

interface Props {
  gradient: RadialGradients | string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  width?: string;
  height?: string;
  margin?: string;
}

export enum RadialGradients {
  Purple = "radial-gradient(circle closest-side at 50% 50%,rgba(230,75,255,.04),transparent)",
  Blue = "radial-gradient(circle closest-side at 50% 50%,rgba(0,81,255,.03),transparent)",
  Cyan = "radial-gradient(circle closest-side at 50% 50%,rgba(0,255,255,.04),transparent)",
  Orange = "radial-gradient(circle closest-side at 50% 50%,rgba(255,119,0,.06),transparent)",
}

export function RadialBackgroundGradient({
  gradient,
  top,
  right,
  bottom,
  left,
  width,
  height,
  margin,
}: Props) {
  return (
    <div
      style={{
        position: "absolute",
        top,
        right,
        bottom,
        left,
        width: width || "1000px",
        height: height || "1000px",
        backgroundImage: gradient,
        margin,
      }}
    />
  );
}
