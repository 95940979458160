import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { db, doc } from '../components/firebase';

import { RootState } from './store';
import { User } from '../models/UserModel';
import dayjs from 'dayjs';

export interface UserState {
  user: User | undefined;
}

export const initialUserState: UserState = {
  user: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => {
  const storedUser: any = state.user.user;

  // console.log('returning storedUser: ', storedUser);

  if (!storedUser) {
    return undefined;
  }

  return {
    id: storedUser.id,
    ref: doc(db, storedUser.ref),
    email: storedUser.email,
    firstName: storedUser.firstName,
    lastName: storedUser.lastName,
    profileImageUrl: storedUser.profileImageUrl,
    currentTeam: doc(db, storedUser.currentTeam),
    onboardingChecklist: storedUser.onboardingChecklist,
    intercomHMAC: storedUser.intercomHMAC,
    createdAt: dayjs(storedUser.createdAt).toDate(),
  } as User;
};

export const selectUserId = (state: RootState) => state.user.user?.id;

export default userSlice.reducer;
