import { Document, documentConverter } from '../../models/DocumentModel';
import React, { ReactNode } from 'react';
import { db, doc, updateDocument } from '../../components/firebase';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { EditableTextField } from '../../components/EditableTextField';
import { IconButton } from '../../components/Buttons/IconButton';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import { selectProjectId } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';
import { useDebouncedCallback } from 'use-debounce';
import { useDocumentData } from 'react-firebase-hooks/firestore';

interface Props {
  documentId?: string;
  showBackButton?: boolean;
  setSidebarView?: (node: ReactNode) => void;
  setSidebarOpen?: (open: boolean) => void;
}

export default function DocumentEditor({
  documentId,
  showBackButton = true,
  setSidebarView,
  setSidebarOpen,
}: Props) {
  const { id: paramsDocumentId } = useParams();

  const docId = documentId || paramsDocumentId;

  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);

  const navigate = useNavigate();

  const [document, documentLoading] = useDocumentData<Document | undefined>(
    docId
      ? doc(
          db,
          'teams',
          teamId || '',
          'projects',
          projectId || '',
          'documents',
          docId
        ).withConverter(documentConverter)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateDocument(
        doc(
          db,
          'teams',
          teamId || '',
          'projects',
          projectId || '',
          'documents',
          docId || ''
        ),
        updatedProperties
      );
    },
    300
  );

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="bg-white border-0 border-b border-gray-200  flex flex-row items-center justify-between">
        <div className={showBackButton ? 'ml-4' : 'invisible'}>
          <IconButton
            icon={ArrowLeftIcon}
            onClick={() => {
              setSidebarView?.(undefined);
              setSidebarOpen?.(false);
              navigate('/documents');
            }}
          />
        </div>
        {document && (
          <EditableTextField
            text={document?.title || 'Untitled Document'}
            textComponentType="h2"
            styleClasses={'font-bold text-gray-700 min-h-[1.4em] px-2'}
            isEditing={true}
            onValueChange={(updatedValue) => {
              debouncedPropertiesUpdate({ title: updatedValue });
            }}
          />
        )}

        <div className="invisible">
          <IconButton icon={ArrowLeftIcon} />
        </div>
      </div>
      {document && (
        <div className="h-full flex-grow pb-4">
          <RichTextEditor
            document={document}
            setSidebarView={setSidebarView}
            setSidebarOpen={setSidebarOpen}
          />
        </div>
      )}
    </div>
  );
}
