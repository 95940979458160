import React, { useEffect } from 'react';
import { Solution, solutionConverter } from '../../models/SolutionModel';
import {
  auth,
  collection,
  db,
  doc,
  query,
  useCollectionData,
  where,
} from '../../components/firebase';

import { Card } from './../../components/Card';
import { Problem } from '../../models/ProblemModel';
import { Project } from '../../models/ProjectModel';
import { ProjectSolutionSelection } from './ProjectSolutionSelection';
import { orderBy } from 'firebase/firestore';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
  project: Project;
  problem: Problem;
  payload: any;
  setPayload: (payload: any) => void;
  stateChangedHandler?: (selected: boolean) => void;
}

export function ProjectProblemSelection({
  project,
  problem,
  payload,
  setPayload,
  stateChangedHandler,
}: Props) {
  const teamId = useAppSelector(selectTeamId);

  const [solutions] = useCollectionData<Solution>(
    query(
      collection(
        db,
        'teams',
        teamId || '',
        'projects',
        project?.id || '',
        'problems',
        problem.id,
        'solutions'
      ),
      where('owner', '==', doc(db, 'users', auth.currentUser?.uid || '')),
      orderBy('createdAt', 'asc')
    ).withConverter(solutionConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (solutions) {
      solutions?.forEach((eachSolution) => {
        if (!payload.problems[problem.id]) {
          payload.problems[problem.id] = new Set();
        }
        payload.problems[problem.id].add(eachSolution.id);
      });
      setPayload({ ...payload });
    }
  }, [solutions]);

  function updateState(selected: boolean) {
    if (selected) {
      payload.problems[problem.id] = new Set();
      solutions?.forEach((eachSolution) => {
        payload.problems[problem.id].add(eachSolution.id);
      });
    } else {
      delete payload.problems[problem.id];
    }
    setPayload({ ...payload });
    stateChangedHandler?.(selected);
  }

  return (
    <Card shadow={false}>
      <div>
        <label
          htmlFor="about"
          className="block text-md font-medium text-gray-700"
        >
          Problem
        </label>
        <div>
          <div className="mt-2">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={`item-${problem.id}`}
                  aria-describedby={`item-description-${problem.id}`}
                  name={`item-${problem.id}`}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={payload.problems[problem.id] !== undefined || false}
                  onChange={(e) => updateState(e.target.checked)}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={`item-${problem.id}`}
                  className="font-medium text-gray-700"
                >
                  {problem.title}
                </label>
                {problem.description && (
                  <p
                    id={`item-description-${problem.id}`}
                    className="text-gray-500"
                  >
                    {problem.description}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {solutions && solutions.length > 0 && (
        <div className="pt-4 pl-7">
          <label
            htmlFor="about"
            className="block text-md font-medium text-gray-700"
          >
            Solutions
          </label>

          {solutions.map((eachSolution) => (
            <ProjectSolutionSelection
              key={eachSolution.id}
              problemId={problem.id}
              solution={eachSolution}
              payload={payload}
              setPayload={setPayload}
            />
          ))}
        </div>
      )}
    </Card>
  );
}
