import React, { useEffect, useRef, useState } from 'react';

interface Props {
  value: string;
  onChange: Function;
  numInputs: number;
  onChangeRegex: RegExp;
  autoFocus: boolean;
  isTypeNumber: boolean;
  hasErrored: boolean;
  isDisabled: boolean;
}

export default function OtpField({
  value = '',
  onChange,
  numInputs = 6,
  onChangeRegex,
  autoFocus = false,
  isTypeNumber = false,
  hasErrored = false,
  isDisabled = false,
}: Props) {
  const defaultValues = generateDefaultValues(numInputs, value.split(''));
  const [values, setValues] = useState(defaultValues);
  const [focusInput, setFocusInput] = useState(autoFocus ? 0 : null);
  const inputRefs = useRef<any[]>([]);

  useEffect(() => {
    setValues(defaultValues);
  }, [value, numInputs]);

  useEffect(() => {
    const input = inputRefs.current[focusInput || 0];
    !!input &&
      input.focus({
        cursor: 'end',
      });
  }, [focusInput]);

  const handleChange = (inputValue: string, index: number) => {
    if (!!onChangeRegex && !isValid(onChangeRegex, inputValue)) return;

    const newValues = [...values];

    let j = 0;
    values.forEach((element, i) => {
      const isNewValuesAndAnyEmptyInput = !element && !!inputValue;
      const isActionRemoveInputValue = !!element && index === i && !inputValue;

      if (isNewValuesAndAnyEmptyInput) {
        newValues[i] = inputValue.split('')[!values[index] ? j : j + 1] || '';
        j++;
      } else if (isActionRemoveInputValue) {
        newValues[i] = '';
      }
    });

    if (inputValue) {
      focusOnNextInput(newValues, values, setFocusInput);
    }

    onChange(newValues.join(''));
  };

  const onKeyPressed = (key: string, index: number) => {
    switch (key) {
      case 'Backspace':
      case 'ArrowLeft':
        return setFocusInput(index - 1);
      case 'ArrowRight':
        return setFocusInput(index + 1);
      default:
        return;
    }
  };

  return (
    <div className="flex justify-center text-center">
      {values.map((element, index) => (
        <div key={index} className="flex justify-center text-center">
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            type={isTypeNumber ? 'number' : 'text'}
            value={element}
            onChange={(e) => handleChange(e.target.value, index)}
            className="m-2 border border-gray-300 h-10 w-10 text-center form-control rounded-md disabled:bg-gray-100"
            onKeyDown={({ key }) => onKeyPressed(key, index)}
            disabled={isDisabled}
          />
        </div>
      ))}
    </div>
  );
}

const generateDefaultValues = (length: number, inputValues: string[]) => {
  if (length < 1) return [];
  return Array.from({ length }, (_, i) => inputValues[i] || '');
};

const isValid = (regex: RegExp, value: string) => regex.test(value);

const focusOnNextInput = (
  newValues: string[],
  currentValues: string[],
  setFocusInput: Function
) => {
  for (let [i, element] of newValues.entries()) {
    if (!element || i === currentValues.length - 1) {
      setFocusInput(i);
      break;
    }
  }
};
