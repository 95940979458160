import {
  RadialBackgroundGradient,
  RadialGradients,
} from '../components/Gradients/RadialBackgroundGradient';
import React, { useState } from 'react';
import Steps, { StepStatus } from './Steps';

import Divider from '../components/Divider';
import { Modal } from './Modal';

interface Props {
  title: string;
  steps: {
    name: string;
    description: string;
    status: StepStatus;
    children: React.ReactNode;
    footer?: React.ReactNode;
  }[];
  currentStep?: number;
  setCurrentStep?: (currentStep: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  gradientBackground?: boolean;
  stepsClickable?: boolean;
  minStepClickable?: number;
  maxStepClickable?: number;
}

export function StepsModal({
  title,
  steps,
  currentStep,
  setCurrentStep,
  open,
  setOpen,
  gradientBackground = false,
  stepsClickable = false,
  minStepClickable,
  maxStepClickable,
}: Props) {
  const [currentLocalStep, setCurrentLocalStep] = useState(currentStep || 0);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="h-[calc(100vh_-_128px)] flex flex-col">
        <h2 className="text-2xl">{title}</h2>
        <div className="flex flex-col pt-2 pb-4">
          <Steps
            steps={steps}
            clickable={stepsClickable}
            minStepClickable={minStepClickable}
            maxStepClickable={maxStepClickable}
            currentStep={currentStep || currentLocalStep}
            setCurrentStep={setCurrentStep || setCurrentLocalStep}
          />
        </div>

        {/* Background Gradients */}
        {gradientBackground && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
              backgroundColor: '#F9FAFD',
            }}
          >
            <RadialBackgroundGradient
              gradient={RadialGradients.Purple}
              left="-100%"
              top="-80%"
              width="200%"
              height="200%"
            />
            <RadialBackgroundGradient
              gradient={RadialGradients.Cyan}
              right="-100%"
              top="-80%"
              width="200%"
              height="200%"
            />
            <RadialBackgroundGradient
              gradient={RadialGradients.Orange}
              bottom="-100%"
              width="200%"
              height="200%"
              left="-100%"
              right="-100%"
              margin="auto"
            />
          </div>
        )}

        <div className="h-full overflow-y-scroll">
          {steps[currentStep || currentLocalStep].children}
        </div>

        {/* Sticky footer */}
        {steps[currentStep || currentLocalStep].footer && (
          <div className="flex-shrink-0 items-center">
            <Divider type="horizontal" />

            <div className="mt-4">
              {steps[currentStep || currentLocalStep].footer}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
