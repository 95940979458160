import {
  MarketingChannel,
  marketingChannelFromStreamingContent,
} from '../../models/MarketingChannelModel';
import React, { useState } from 'react';
import {
  StreamingMarketingChannel,
  streamingMarketingChannelFromStreamingContent,
} from '../../models/StreamingMarketingChannelModel';
import {
  getIdToken,
  updateProjectCompletedChecklistStep,
} from '../../components/firebase';
import { getMaxStepsClickable, updateStatus } from '../../utils/utils';

import { CustomerPersona } from '../../models/CustomerPersonaModel';
import CustomerPersonas from '../CustomerPersonas/CustomerPersonas';
import { OnboardingChecklistStepType } from '../../models/OnboardingChecklistStepModel';
import { Project } from '../../models/ProjectModel';
import { StepStatus } from '../../layout/Steps';
import { StepsModal } from '../../layout/StepsModal';
import StreamingMarketingChannels from './StreamingMarketingChannels';
import WorkCompleted from '../ProjectDetails/WorkCompleted';
import { selectTeamId } from '../../app/teamSlice';
import untruncateJson from 'untruncate-json';
import { useAppSelector } from '../../app/hooks';

interface Props {
  project: Project;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateNewMarketingChannelModal({
  project,
  open,
  setOpen,
}: Props) {
  const teamId = useAppSelector(selectTeamId);

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPersona, setSelectedPersona] =
    useState<CustomerPersona | null>(null);

  const [payload, setPayload] = useState({
    projectId: project.id,
    teamId: teamId,
    problems: {},
    features: new Set(),
  });
  const [isWorking, setIsWorking] = useState(false);

  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);

  const [streamingContent, setStreamingContent] = useState<
    StreamingMarketingChannel[]
  >([]);

  async function addNewMarketingChannel(
    projectId: string,
    teamId: string,
    personaId: string
  ) {
    setIsWorking(true);
    setCurrentStep(1);
    updateStatus(0, StepStatus.Done, setStepStatuses);
    updateStatus(1, StepStatus.InProgress, setStepStatuses);

    let problems: { [key: string]: any } = {};
    Object.entries(payload.problems).forEach(
      ([problemId, problemSolutionIds]) => {
        problems[problemId] = Array.from(problemSolutionIds as string[]);
      }
    );

    const formattedPayload = {
      projectId,
      teamId,
      personaId,
      numberOfGenerations: 1,
      problems: {},
      features: [],
    };

    const idToken = await getIdToken();

    if (!idToken) {
      setIsWorking(false);
      setCurrentStep(0);
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          '/marketing-channels/create-marketing-channels',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify(formattedPayload),
        }
      );

      // Process the streaming response as text
      const reader = response?.body?.getReader();
      if (!reader) {
        return;
      }

      let completion = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const chunk = new TextDecoder('utf-8').decode(value);

        completion += chunk;

        const jsonString = untruncateJson(completion);
        try {
          const json = JSON.parse(jsonString);
          const marketingChannels = json?.map((channel: any) => {
            return streamingMarketingChannelFromStreamingContent(channel);
          });

          setStreamingContent(marketingChannels);
        } catch (error) {}
      }

      updateStatus(1, StepStatus.Done, setStepStatuses);
      updateStatus(2, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedMarketingChannel,
          project
        );
      }

      setCurrentStep(2);
    } catch (error: any) {
      console.error('Error creating the new markketing channel:', error);
      alert(error.message);
      setSelectedPersona(null);
      setCurrentStep(0);
      updateStatus(0, StepStatus.NotStarted, setStepStatuses);
      updateStatus(1, StepStatus.NotStarted, setStepStatuses);
    } finally {
      setIsWorking(false);
    }
  }

  async function handlePersonaSelected(persona: CustomerPersona) {
    if (
      !teamId ||
      isWorking ||
      stepStatuses[stepStatuses.length - 1] === StepStatus.Done
    ) {
      return;
    }

    setSelectedPersona(persona);

    await addNewMarketingChannel(project.id, teamId, persona.id);
  }

  return (
    <StepsModal
      title="Find New Marketing Channel"
      open={open}
      setOpen={setOpen}
      steps={[
        {
          name: 'Select Persona',
          description: 'Channel is for a specific persona.',
          status: stepStatuses[0],
          children: (
            <div className="py-8">
              <CustomerPersonas onClick={handlePersonaSelected} />
            </div>
          ),
        },
        {
          name: 'Generate Channel',
          description: 'Generate the channel.',
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingContent && streamingContent.length > 0 && (
                <StreamingMarketingChannels
                  streamingMarketingChannels={streamingContent || []}
                  relatedPersonas={selectedPersona ? [selectedPersona] : []}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[2],
          children: (
            <WorkCompleted
              title={'Your Marketing Channel Is Ready!'}
              subtitle={'You can close this window now.'}
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={isWorking || stepStatuses[2] === StepStatus.Done}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
