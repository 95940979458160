import React from 'react';
import { Solution } from '../../models/SolutionModel';

interface Props {
  problemId: string;
  solution: Solution;
  payload: any;
  setPayload: (payload: any) => void;
  stateChangedHandler?: (selected: boolean) => void;
}

export function ProjectSolutionSelection({
  problemId,
  solution,
  payload,
  setPayload,
  stateChangedHandler,
}: Props) {
  function updateState(selected: boolean) {
    if (selected) {
      if (!payload.problems[problemId]) {
        payload.problems[problemId] = new Set();
      }
      payload.problems[problemId].add(solution.id);
    } else {
      payload.problems[problemId].delete(solution.id);
      if (payload.problems[problemId].size === 0) {
        delete payload.problems[problemId];
      }
    }
    setPayload({ ...payload });
    stateChangedHandler?.(selected);
  }

  return (
    <div>
      <div className="mt-2">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              key={solution.id}
              id={`item-${solution.id}`}
              aria-describedby={`item-description-${solution.id}`}
              name={`item-${solution.id}`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={payload.problems[problemId]?.has(solution.id) || false}
              onChange={(e) => updateState(e.target.checked)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor={`item-${solution.id}`}
              className="font-medium text-gray-700"
            >
              {solution.title}
            </label>
            {solution.description && (
              <p
                id={`item-description-${solution.id}`}
                className="text-gray-500"
              >
                {solution.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
