import {
  Benefit,
  benefitFromStreamingContent,
} from '../../models/BenefitModel';
import { Feature, featureConverter } from '../../models/FeatureModel';
import React, { useState } from 'react';
import {
  collection,
  db,
  query,
  updateProjectCompletedChecklistStep,
  useCollectionData,
} from '../../components/firebase';
import { getMaxStepsClickable, updateStatus } from '../../utils/utils';

import { MagicButton } from '../../components/Buttons/MagicButton';
import { OnboardingChecklistStepType } from '../../models/OnboardingChecklistStepModel';
import ProjectSelection from '../../components/ProjectContentSelection/ProjectSelection';
import { StepStatus } from '../../layout/Steps';
import { StepsModal } from '../../layout/StepsModal';
import StreamingBenefits from './StreamingBenefits';
import WorkCompleted from '../ProjectDetails/WorkCompleted';
import { getIdToken } from '../../components/firebase';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import untruncateJson from 'untruncate-json';
import { useAppSelector } from '../../app/hooks';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateNewBenefitModal({ open, setOpen }: Props) {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);

  const [currentStep, setCurrentStep] = useState(0);

  const [payload, setPayload] = useState({
    projectId: project?.id || '',
    teamId: teamId,
    problems: {},
    features: new Set(),
  });
  const [isWorking, setIsWorking] = useState(false);

  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);

  const [streamingContent, setStreamingContent] = useState<Benefit[]>([]);

  const [features] = useCollectionData<Feature>(
    project?.id
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            project?.id || '',
            'features'
          ),
          orderBy('createdAt', 'asc')
        ).withConverter(featureConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  function getRelatedFeatureRefs(benefit: any) {
    const relatedFeatureIds = benefit.featureIds || [];

    return features
      ?.filter((eachFeature) => relatedFeatureIds.includes(eachFeature.ref.id))
      .map((eachFeature) => eachFeature.ref);
  }

  async function addNewBenefit(payload: any) {
    setIsWorking(true);
    setCurrentStep(1);
    updateStatus(0, StepStatus.Done, setStepStatuses);
    updateStatus(1, StepStatus.InProgress, setStepStatuses);

    console.log('payload', payload);

    let problems: { [key: string]: any } = {};
    Object.entries(payload.problems).forEach(
      ([problemId, problemSolutionIds]) => {
        problems[problemId] = Array.from(problemSolutionIds as string[]);
      }
    );

    const formattedPayload = {
      projectId: payload.projectId,
      teamId: payload.teamId,
      numberOfGenerations: 1,
      problems: problems,
      features: Array.from(payload.features),
    };

    const idToken = await getIdToken();

    if (!idToken) {
      setIsWorking(false);
      setCurrentStep(0);
      updateStatus(0, StepStatus.NotStarted, setStepStatuses);
      updateStatus(1, StepStatus.NotStarted, setStepStatuses);
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + '/benefits/create-benefits',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify(formattedPayload),
        }
      );

      const reader = response?.body?.getReader();
      if (!reader) {
        return;
      }

      let completion = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const chunk = new TextDecoder('utf-8').decode(value);

        completion += chunk;

        const jsonString = untruncateJson(completion);
        try {
          const json = JSON.parse(jsonString);
          const benefits = json?.map((benefit: any) => {
            return benefitFromStreamingContent({
              title: benefit.benefitTitle,
              description: benefit.benefitDescription,
              relatedFeatures: getRelatedFeatureRefs(benefit),
            });
          });

          setStreamingContent(benefits);
        } catch (error) {}
      }
      updateStatus(1, StepStatus.Done, setStepStatuses);
      updateStatus(2, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedBenefit,
          project
        );
      }

      setCurrentStep(2);
    } catch (error: any) {
      console.error('Error creating the new project:', error);
      alert(error.message);
      setCurrentStep(0);
      updateStatus(0, StepStatus.NotStarted, setStepStatuses);
      updateStatus(1, StepStatus.NotStarted, setStepStatuses);
    } finally {
      setIsWorking(false);
    }
  }

  function getButtonTitle(): string {
    return isWorking ? 'Working...' : 'Generate New Benefit';
  }

  return (
    <StepsModal
      title="Create New Benefit"
      open={open}
      setOpen={setOpen}
      // setOpen={(open: boolean) => {
      //   setOpen(open);
      //   setCurrentStep(0);
      // }}
      steps={[
        {
          name: 'Describe Project',
          description: 'Start by describing your project.',
          status: stepStatuses[0],
          children: (
            <div className="py-8 max-w-2xl mx-auto">
              {project && (
                <ProjectSelection
                  project={project}
                  payload={payload}
                  setPayload={setPayload}
                />
              )}
            </div>
          ),
          footer: (
            <MagicButton
              title={getButtonTitle()}
              disabled={
                isWorking ||
                stepStatuses[stepStatuses.length - 1] === StepStatus.Done
              }
              onClick={async () => {
                await addNewBenefit(payload);
              }}
            />
          ),
        },
        {
          name: 'Generating Benefit',
          description: 'Working hard...',
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingContent && streamingContent.length > 0 && (
                <StreamingBenefits
                  benefits={streamingContent || []}
                  features={features || []}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[2],
          children: (
            <WorkCompleted
              title={'Your Benefit Is Ready!'}
              subtitle={'Enjoy! Please give us feedback on how you find it.'}
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={isWorking || stepStatuses[2] === StepStatus.Done}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
