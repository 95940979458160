import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  cardTitle?: string;
  text: string;
  textStyleClasses?: string;
  description?: string;
  docRef: DocumentReference;
  updatePath: string;
  isEditing: boolean;
}

export default function TitleCard({
  cardTitle,
  text,
  textStyleClasses,
  description,
  docRef,
  updatePath,
  isEditing,
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateDocument(docRef, updatedProperties);
    },
    300
  );

  return (
    <Card shadow={false} styleClasses="bg-slate-50">
      <div className="flex flex-col justify-center align-middle text-center">
        {cardTitle && (
          <h3 className="mx-auto text-sm font-bold uppercase text-gray-500">
            {cardTitle}
          </h3>
        )}

        <h2 className="mx-auto mt-1.5">
          <EditableTextField
            text={text}
            textComponentType="h4"
            styleClasses={twMerge(
              'text-3xl font-bold text-gray-700 min-h-[1.4em] bg-slate-50 text-center',
              textStyleClasses
            )}
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties[updatePath] = updatedValue;
              debouncedPropertiesUpdate(properties);
            }}
          />
        </h2>

        <p className="mx-auto mt-1.5 text-sm text-gray-500">{description}</p>
      </div>
    </Card>
  );
}
