import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// ChatSession
//

type ChatSession = {
  id: string;
  ref: DocumentReference<DocumentData>;
  title?: string;
  createdAt: Date;
};

const chatSessionConverter: FirestoreDataConverter<ChatSession> = {
  toFirestore(chatSession: WithFieldValue<ChatSession>): DocumentData {
    return {
      title: chatSession.title,
      createdAt: chatSession.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ChatSession {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      title: data.title,
      createdAt: data.createdAt,
    };
  },
};

function chatSessionFromFirestore(document: any): ChatSession {
  return {
    id: document.id,
    ref: document.ref,
    title: document.title,
    createdAt: document.createdAt,
  };
}

export type { ChatSession };
export { chatSessionConverter, chatSessionFromFirestore };
