import React, { useState } from 'react';
import { auth, sendSignInEmail, verifyOtp } from './../../components/firebase';

import { LockClosedIcon } from '@heroicons/react/20/solid';
import OtpField from './OtpField';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

const STEP_1_ENTER_EMAIL = 'enterEmail';
const STEP_2_REGISTERING_EMAIL = 'registeringEmail';
const STEP_3_ENTER_CODE = 'enterCode';
const STEP_4_VALIDATING_CODE = 'validatingCode';
const STEP_5_ERROR_VALIDATING_CODE = 'errorValidatingCode';
const STEP_6_DONE = 'done';

interface Props {}

export function SignIn({}: Props) {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [currentStep, setCurrentStep] = useState(STEP_1_ENTER_EMAIL);

  const [emailValue, setEmailValue] = useState('');
  const [otpValue, setOtpValue] = useState('');

  function getCurrentSubtitle() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
      case STEP_2_REGISTERING_EMAIL:
        return 'We’ll email you a magic code for a password-free registration.';
      case STEP_3_ENTER_CODE:
      case STEP_4_VALIDATING_CODE:
        return 'Enter the code we sent to your email address.';
      case STEP_6_DONE:
        return '';
      default:
        return 'Think this is an error?';
    }
  }

  function getCurrentButtonTitle() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
        return 'Send My Code';
      case STEP_2_REGISTERING_EMAIL:
        return 'Working...';
      case STEP_3_ENTER_CODE:
        return 'Validate';
      case STEP_4_VALIDATING_CODE:
        return 'Validating...';
      case STEP_6_DONE:
        return '';
      default:
        return 'Think this is an error?';
    }
  }

  function isOtpCodeVisible() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
      case STEP_2_REGISTERING_EMAIL:
        return false;
      default:
        return true;
    }
  }

  const handleFormSubmitted = () => {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
        signIn(emailValue);
        break;
      case STEP_3_ENTER_CODE:
        break;
      default:
        break;
    }
  };

  const handleFormSubmitFailed = () => {
    switch (currentStep) {
      case STEP_2_REGISTERING_EMAIL:
        setCurrentStep(STEP_1_ENTER_EMAIL);
        break;
      case STEP_4_VALIDATING_CODE:
        setCurrentStep(STEP_3_ENTER_CODE);
        break;
      default:
        break;
    }
  };

  async function updateOtpValue(value: string) {
    setOtpValue(value);
    if (value.length === 6) {
      setCurrentStep(STEP_4_VALIDATING_CODE);
      await verifyCode(value, emailValue);
    }
  }

  async function signIn(email: string) {
    setCurrentStep(STEP_2_REGISTERING_EMAIL);
    try {
      await sendSignInEmail(email);
      setCurrentStep(STEP_3_ENTER_CODE);
    } catch (error) {
      setCurrentStep(STEP_1_ENTER_EMAIL);
    }
  }

  async function verifyCode(code: string, email: string) {
    setCurrentStep(STEP_4_VALIDATING_CODE);
    try {
      await verifyOtp(code, email);
      setCurrentStep(STEP_6_DONE);
      setTimeout(() => {
        navigate(0);
      }, 2000);
    } catch (error) {
      setCurrentStep(STEP_5_ERROR_VALIDATING_CODE);
      setOtpValue('');
    }
  }

  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="flex min-h-full max-w-lg items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            <div>
              <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
                Marketology.ai
              </h2>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>
            <form
              className="mt-8 space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                handleFormSubmitted();
              }}
            >
              <div className="-space-y-px rounded-md">
                <div className="shadow-sm">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-gray-100 sm:text-sm"
                    placeholder="Email address"
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                    disabled={currentStep !== STEP_1_ENTER_EMAIL}
                  />
                </div>

                <div className="flex items-center">
                  <p className="mt-3 text-center text-sm text-gray-600">
                    {getCurrentSubtitle()}
                  </p>
                </div>
              </div>

              {isOtpCodeVisible() && (
                <div>
                  <OtpField
                    value={otpValue}
                    onChange={updateOtpValue}
                    numInputs={6}
                    onChangeRegex={/^([0-9]{0,})$/}
                    autoFocus={true}
                    isTypeNumber={false}
                    hasErrored={currentStep === STEP_5_ERROR_VALIDATING_CODE}
                    isDisabled={currentStep === STEP_4_VALIDATING_CODE}
                  />
                </div>
              )}

              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  {getCurrentButtonTitle()}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
