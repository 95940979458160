import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { listenerMiddleware, serializerMiddleware } from './middleware';

import chatSessionReducer from './chatSessionSlice';
import documentReducer from './documentSlice';
import { initialProjectState } from './projectSlice';
import { initialTeamState } from './teamSlice';
import projectReducer from './projectSlice';
import teamReducer from './teamSlice';
import userReducer from './userSlice';

const selectedTeamState = JSON.parse(localStorage.getItem('team') || 'null');
const selectedProjectState = JSON.parse(
  localStorage.getItem('project') || 'null'
);

const rootReducer = combineReducers({
  team: teamReducer,
  project: projectReducer,
  user: userReducer,
  chatSession: chatSessionReducer,
  document: documentReducer,
});

export const store = configureStore({
  preloadedState: {
    team: selectedTeamState === null ? initialTeamState : selectedTeamState,
    project:
      selectedProjectState === null
        ? initialProjectState
        : selectedProjectState,
  },
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    serializerMiddleware,
    ...getDefaultMiddleware(),
    listenerMiddleware.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
