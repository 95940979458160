//
// DataCollectionBuildingBlock
//

export enum DataCollectionBuildingBlockType {
  SingleLineTextInput = 'singleLineTextInput',
  MultiLineTextInput = 'multiLineTextInput',
}

export type DataCollectionBuildingBlock = {
  type: DataCollectionBuildingBlockType;
  payloadPath: string;
  title: string;
  initialValue?: string;
  placeholder?: string;
  description?: string;
};
