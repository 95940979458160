import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// ChatMessage
//

type ChatMessage = {
  id: string;
  ref: DocumentReference<DocumentData>;
  role: string;
  content: string;
  createdAt: Date;
};

const chatMessageConverter: FirestoreDataConverter<ChatMessage> = {
  toFirestore(chatMessage: WithFieldValue<ChatMessage>): DocumentData {
    return {
      role: chatMessage.role,
      content: chatMessage.content,
      createdAt: chatMessage.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ChatMessage {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      role: data.role,
      content: data.content,
      createdAt: data.createdAt,
    };
  },
};

function chatMessageFromFirestore(document: any): ChatMessage {
  return {
    id: document.id,
    ref: document.ref,
    role: document.role,
    content: document.content,
    createdAt: document.createdAt,
  };
}

export type { ChatMessage };
export { chatMessageConverter, chatMessageFromFirestore };
