import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// MarketingChannel
//

type MarketingChannel = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  title?: string;
  type: string;
  description?: string;
  keyMetrics: string[];
  strengths: string[];
  challenges: string[];
  optimizationTips: string[];
  createdAt: Date;
};

const marketingChannelConverter: FirestoreDataConverter<MarketingChannel> = {
  toFirestore(
    marketingChannel: WithFieldValue<MarketingChannel>
  ): DocumentData {
    return {
      owner: marketingChannel.owner,
      title: marketingChannel.title,
      type: marketingChannel.type,
      description: marketingChannel.description,
      keyMetrics: marketingChannel.keyMetrics,
      strengths: marketingChannel.strengths,
      challenges: marketingChannel.challenges,
      optimizationTips: marketingChannel.optimizationTips,
      createdAt: marketingChannel.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): MarketingChannel {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      owner: data.owner,
      title: data.title,
      type: data.type,
      description: data.description,
      keyMetrics: data.keyMetrics,
      strengths: data.strengths,
      challenges: data.challenges,
      optimizationTips: data.optimizationTips,
      createdAt: data.createdAt,
    };
  },
};

function marketingChannelFromFirestore(document: any): MarketingChannel {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    title: document.title,
    type: document.type,
    description: document.description,
    keyMetrics: document.keyMetrics,
    strengths: document.strengths,
    challenges: document.challenges,
    optimizationTips: document.optimizationTips,
    createdAt: document.createdAt,
  };
}

function marketingChannelFromStreamingContent(
  partialDocument: any
): MarketingChannel {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    owner: partialDocument.owner || '',
    title: partialDocument.title || 'Thinking...',
    type: partialDocument.type || '',
    description: partialDocument.description || '',
    keyMetrics: partialDocument.keyMetrics || [],
    strengths: partialDocument.strengths || [],
    challenges: partialDocument.challenges || [],
    optimizationTips: partialDocument.optimizationTips || [],
    createdAt: partialDocument.createdAt || new Date(),
  };
}

export type { MarketingChannel };
export {
  marketingChannelConverter,
  marketingChannelFromFirestore,
  marketingChannelFromStreamingContent,
};
