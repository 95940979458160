import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  title?: string;
  description: string;
  docRef: DocumentReference;
  updatePath: string;
  isEditing: boolean;
}

export default function ParagraphCard({
  title,
  description,
  docRef,
  updatePath,
  isEditing,
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateDocument(docRef, updatedProperties);
    },
    300
  );

  return (
    <Card shadow={false}>
      <div className="flex flex-col justify-start align-top text-left">
        {title && (
          <h3 className="mr-auto text-xl font-bold text-gray-700">{title}</h3>
        )}

        <div className="mt-1.5">
          <EditableTextField
            text={description}
            textComponentType="h4"
            styleClasses={'text-sm font-regular text-gray-700 min-h-[1.4em]'}
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties[updatePath] = updatedValue;
              debouncedPropertiesUpdate(properties);
            }}
          />
        </div>
      </div>
    </Card>
  );
}
