import React, { JSXElementConstructor, ReactElement } from 'react';

import Tippy from '@tippyjs/react';

interface Props {
  content: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
}

export default function Tooltip({ content, placement, children }: Props) {
  return (
    <Tippy content={content} placement={placement} delay={100} offset={[0, 20]}>
      {children}
    </Tippy>
  );
}
