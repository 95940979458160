import {
  ChatSession,
  chatSessionConverter,
  chatSessionFromFirestore,
} from '../../models/ChatSessionModel';
import React, { useEffect } from 'react';
import { Timestamp, orderBy } from 'firebase/firestore';
import {
  addDocument,
  collection,
  db,
  doc,
  query,
  useCollectionData,
  where,
} from '../../components/firebase';
import {
  selectChatSession,
  setSelectedChatSession,
} from '../../app/chatSessionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { ChatSessionCard } from './ChatSessionCard';
import { Modal } from '../../layout/Modal';
import dayjs from 'dayjs';
import { selectProject } from '../../app/projectSlice';
import { selectTeam } from '../../app/teamSlice';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function SelectChatSessionModal({ open, setOpen }: Props) {
  const currentlySelectedChatSession = useAppSelector(selectChatSession);
  const currentTeam = useAppSelector(selectTeam);
  const currentProject = useAppSelector(selectProject);
  const dispatch = useAppDispatch();

  const [
    chatSessions,
    chatSessionsLoading,
    chatSessionsError,
    chatSessionsSnapshot,
  ] = useCollectionData<ChatSession>(
    currentTeam && currentProject
      ? query(
          collection(
            db,
            'teams',
            currentTeam?.id || '',
            'projects',
            currentProject?.id || '',
            'chatSessions'
          ),
          orderBy('createdAt', 'desc')
        ).withConverter(chatSessionConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  function formatTimestamp(timestamp: any) {
    const ts = timestamp as Timestamp;
    return dayjs(ts.toDate()).format('HH:mm - DD MMM');
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {/* <li className="col-span-1 flex flex-col text-center bg-white rounded-lg ">
          <AddNewItemCard
            onClick={async () => {
              await addNewChatSession();
            }}
          />
        </li> */}

        {chatSessions &&
          chatSessions.map((eachChatSession) => (
            <ChatSessionCard
              key={eachChatSession.id}
              title={eachChatSession.title}
              subtitle={formatTimestamp(eachChatSession.createdAt)}
              selected={currentlySelectedChatSession?.id === eachChatSession.id}
              onClick={() => {
                // selectionChangedHandler(eachChatSession);
                dispatch(setSelectedChatSession(eachChatSession));
                setOpen(false);
              }}
            />
          ))}
      </ul>
    </Modal>
  );
}
