import React from 'react';

interface Props {
  icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function IconButton({ icon: Icon, onClick }: Props) {
  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md border border-transparent bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:shadow-sm hover:bg-gray-50 active:bg-gray-100"
      onClick={onClick}
    >
      {Icon && <Icon className="h-4 w-4" aria-hidden="true" />}
    </button>
  );
}
