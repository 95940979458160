import { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';

import ConfirmationModal from './ConfirmationModal';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

interface Props {
  items: {
    title: string;
    icon?: React.ForwardRefExoticComponent<
      React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
        title?: string;
        titleId?: string;
      } & React.RefAttributes<SVGSVGElement>
    >;
    color?: string;
    confirmationTitle?: string;
    confirmationDescription?: string;
    onConfirm?: () => void;
    onClick: () => void;
  }[];
}

export default function DropdownMenu({ items }: Props) {
  function getMenuButton(item: any): ReactNode {
    return (
      <button
        key={'menu-item-button-' + item.title}
        className={`inline-block px-4 py-2 text-left text-sm ${
          item.color ? ` ${item.color}` : ''
        }`}
        onClick={item.onClick}
      >
        <span className="whitespace-nowrap">
          <div className="flex flex-row gap-2 align-middle">
            {item.icon && (
              <div className="my-auto">
                <item.icon className="h-4 w-4" aria-hidden="true" />
              </div>
            )}
            <div className="my-auto">{item.title}</div>
          </div>
        </span>
      </button>
    );
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full bg-transparent text-gray-400 hover:text-gray-600 active:text-gray-900">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item key={'menu-item-' + item.title}>
                {({ active }) => (
                  <div
                    key={'menu-item-div-' + item.title}
                    className={`${
                      active ? 'bg-gray-50 text-gray-700' : 'text-gray-500'
                    }`}
                  >
                    {item.confirmationTitle && item.confirmationDescription ? (
                      // Doesn't work yet - the modal gets closed immediately
                      <ConfirmationModal
                        key={'menu-item-modal-' + item.title}
                        title={item.confirmationTitle}
                        description={item.confirmationDescription}
                        onConfirm={item.onConfirm}
                      >
                        {getMenuButton(item)}
                      </ConfirmationModal>
                    ) : (
                      getMenuButton(item)
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
