import React from 'react';

interface Props {
  children?: React.ReactNode;
  title?: string;
  icon?: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }
  >;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function TextButton({ children, title, icon: Icon, onClick }: Props) {
  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:bg-gray-50"
      onClick={onClick}
    >
      {Icon && <Icon className="h-4 w-4" aria-hidden="true" />}
      {title && title}
      {children && children}
    </button>
  );
}
