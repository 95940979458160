import './SafeHtmlRenderer.css';

import React, { forwardRef, useMemo } from 'react';

// import * as DOMPurify from 'dompurify';
import DOMPurify from 'dompurify';

interface Props {
  htmlString: string;
}

export const SafeHtmlRenderer = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { htmlString } = props;
    const sanitizedHtml = useMemo(() => {
      return DOMPurify.sanitize(htmlString);
    }, [htmlString]);

    return (
      <div
        ref={ref}
        className="m9y-safe-html-renderer"
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    );
  }
);
