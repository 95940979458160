import { EditableTextField } from '../EditableTextField';
import React from 'react';

interface Props {
  text: string;
  index: number;
  isEditing: boolean;
  onValueChange?: (value: string) => void;
  onRemove?: (index: number) => void;
}

export default function HighlightedListRow({
  text,
  index,
  isEditing,
  onValueChange,
  onRemove,
}: Props) {
  return (
    <li className="mr-auto mt-2 text-sm text-left font-regular text-gray-700">
      <EditableTextField
        text={text}
        textComponentType="h4"
        styleClasses={'text-sm font-bold text-blue-600 min-h-[1.4em]'}
        isEditing={isEditing}
        onValueChange={onValueChange}
        onRemove={() => onRemove?.(index)}
      />
    </li>
  );
}
