import React, { useState } from 'react';

import { GhostButton } from '../Buttons/GhostButton';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { twMerge } from 'tailwind-merge';

const styleClasses = 'text-sm font-regular text-gray-700 min-h-[2.6em]';

interface Props {
  onClearChat?: () => void;
  onSend?: (text: string) => void;
}

export default function ChatInput({ onClearChat, onSend }: Props) {
  const [localText, setLocalText] = useState('');

  return (
    <div className="w-full bg-slate-50">
      <div className="pt-4 flex flex-col">
        <div className="relative p-0 m-0">
          <p
            className={twMerge(
              'p-2 m-0 min-w-full min-h-[15px] whitespace-pre-wrap',
              styleClasses
            )}
          >
            {localText}
          </p>

          <div className="absolute p-0 m-0 border-0 inset-0 flex-1 justify-center items-center  z-1">
            <textarea
              key={'chat-input'}
              id="chatInput"
              name="chatInput"
              className={twMerge(
                'p-2 m-0 w-full h-full border-0 resize-none overflow-hidden bg-white rounded-md',
                styleClasses
              )}
              value={localText}
              onChange={(e) => {
                setLocalText(e.target.value);
              }}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter' && localText !== '') {
              //     onSend?.(localText);
              //     setLocalText('');
              //   }
              // }}
            />
          </div>
        </div>

        <div className="flex flex-row justify-between mt-4">
          <GhostButton
            title={'Clear Chat'}
            onClick={() => {
              setLocalText('');
              onClearChat?.();
            }}
          />
          <PrimaryButton
            title={'Send'}
            onClick={() => {
              onSend?.(localText);
              setLocalText('');
            }}
          />
        </div>
      </div>
    </div>
  );
}
