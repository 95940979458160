import {
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import React, { useState } from 'react';

import CompanyBulletPointsCard from '../../components/CardComponents/CompanyBulletPointsCard';
import CompanyHighlightedListCard from '../../components/CardComponents/CompanyHighlightedListCard';
import CompanyParagraphCard from '../../components/CardComponents/CompanyParagraphCard';
import ConfirmationModal from '../../components/ConfirmationModal';
import DropdownMenu from '../../components/DropdownMenu';
import { IdealCustomerProfile } from '../../models/IdealCustomerProfileModel';
import TitleCard from '../../components/CardComponents/TitleCard';
import { deleteDocument } from '../../components/firebase';
import { saveElementAsPNG } from '../../utils/screenshotUtils';

interface Props {
  idealCustomerProfile: IdealCustomerProfile;
  onClick: () => void;
}

export function IcpCard({ idealCustomerProfile, onClick }: Props) {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  async function deleteIdealCustomerProfile() {
    await deleteDocument(idealCustomerProfile.ref);
  }

  async function saveAsPNG() {
    const element = document.getElementById(
      `icp-card-${idealCustomerProfile.id}`
    );
    if (element) {
      await saveElementAsPNG(element, 'marketology-ideal-customer-profile');
    }
  }

  return (
    <li
      id={`icp-card-${idealCustomerProfile.id}`}
      className={`col-span-1 flex flex-col rounded-xl bg-white text-center shadow`}
      onClick={onClick}
    >
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex flex-row flex-wrap align-middle justify-between rounded-md gap-2 mt-1 h-8 text-slate-500">
          <h3 className="my-auto mx-4 text-sm">
            <span className="font-semibold">Ideal Customer Profile</span>
          </h3>

          <div className="flex flex-row align-middle my-auto mr-3">
            <div className={`mr-4 ${isEditing ? '' : 'invisible'}`}>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Done
              </button>
            </div>

            <div className="my-auto">
              <DropdownMenu
                items={[
                  {
                    title: 'Edit',
                    icon: PencilSquareIcon,
                    onClick: () => {
                      setIsEditing(true);
                    },
                  },
                  {
                    title: 'Save as PNG',
                    icon: PhotoIcon,
                    onClick: async () => {
                      if (isEditing) {
                        setIsEditing(false);
                      }
                      setTimeout(() => {
                        saveAsPNG();
                      }, 1000);
                    },
                  },
                  {
                    title: 'Delete',
                    icon: TrashIcon,
                    color: 'text-red-500',
                    onClick: async () => {
                      setDeleteConfirmationOpen(true);
                    },
                  },
                ]}
              />
            </div>
            <ConfirmationModal
              title="Delete Ideal Customer Profile?"
              description="Are you sure you want to delete this ideal customer profile? You can't undo this action."
              open={deleteConfirmationOpen}
              setOpen={setDeleteConfirmationOpen}
              onConfirm={async () => {
                await deleteIdealCustomerProfile();
              }}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-row px-4 py-1 gap-4 rounded-xl">
          {/* Left Column */}
          <div className="flex flex-col shrink w-[30%] gap-4 align-middle my-auto">
            <div>
              <CompanyParagraphCard
                title="Buying Process"
                description={idealCustomerProfile.companyBuyingProcess || ''}
                docRef={idealCustomerProfile.ref}
                updatePath="description.companyBuyingProcess"
                isEditing={isEditing}
              />
            </div>

            {/* Pain Points */}
            <div>
              <CompanyBulletPointsCard
                title="Pain Points"
                items={idealCustomerProfile.companyPainPoints}
                docRef={idealCustomerProfile.ref}
                updatePath="description.companyPainPoints"
                isEditing={isEditing}
              />
            </div>
          </div>

          {/* Center Column */}
          <div className="flex flex-col shrink w-[40%] gap-4 align-middle my-auto">
            {/* Location */}
            <div>
              <TitleCard
                cardTitle="Location"
                text={
                  (Array.isArray(
                    idealCustomerProfile.regionOrGeographicalLocation
                  )
                    ? idealCustomerProfile.regionOrGeographicalLocation.join(
                        ', '
                      )
                    : idealCustomerProfile.regionOrGeographicalLocation) || ''
                }
                textStyleClasses="text-xl"
                docRef={idealCustomerProfile.ref}
                updatePath="description.regionOrGeographicalLocation"
                isEditing={isEditing}
              />
            </div>

            {/* Industry */}
            <div>
              <TitleCard
                cardTitle="Industry"
                text={idealCustomerProfile.industry || ''}
                docRef={idealCustomerProfile.ref}
                updatePath="description.industry"
                isEditing={isEditing}
              />
            </div>

            <div className="flex flex-row gap-4">
              {/* Revenue */}
              <div>
                <TitleCard
                  cardTitle="Estimated Revenue"
                  text={idealCustomerProfile.estimatedRevenue || ''}
                  textStyleClasses="text-xl"
                  docRef={idealCustomerProfile.ref}
                  updatePath="description.estimatedRevenue"
                  isEditing={isEditing}
                />
              </div>

              {/* Employees */}
              <div>
                <TitleCard
                  cardTitle="Number of Employees"
                  text={idealCustomerProfile.numberOfEmployees || ''}
                  textStyleClasses="text-xl"
                  docRef={idealCustomerProfile.ref}
                  updatePath="description.numberOfEmployees"
                  isEditing={isEditing}
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="flex flex-col w-[30%] gap-4 align-middle my-auto">
            <div className="">
              <CompanyHighlightedListCard
                title="Tech Stack"
                items={idealCustomerProfile.techStack}
                docRef={idealCustomerProfile.ref}
                updatePath="description.techStack"
                isEditing={isEditing}
              />
            </div>

            {/* Goals */}
            <div className="flex flex-row gap-4">
              <CompanyBulletPointsCard
                title="Goals"
                items={idealCustomerProfile.companyGoals}
                docRef={idealCustomerProfile.ref}
                updatePath="description.companyGoals"
                isEditing={isEditing}
              />
            </div>

            {/* Footer */}
            {/* <div className="flex flex-row flex-wrap align-middle justify-end rounded-md gap-2 text-slate-500">
              <h3 className="text-sm">
                Created with{' '}
                <span className="font-semibold">Marketology.ai</span>
              </h3>
            </div> */}
          </div>
        </div>

        {/* Footer */}
        <div className="flex flex-row flex-wrap align-middle justify-end rounded-md gap-2 mb-1 h-8 text-slate-500">
          <h3 className="my-auto mx-4 text-sm">
            Created with <span className="font-semibold">Marketology.ai</span>
          </h3>
        </div>
      </div>

      {/* <div className="flex flex-col p-8">
        <h3 className="mt-6 text-2xl font-medium text-gray-900">{icp.title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dd className="text-sm text-gray-500 text-ellipsis overflow-hidden">
            <p>{JSON.stringify(icp.description)}</p>
          </dd>
        </dl>
      </div> */}
    </li>
  );
}
