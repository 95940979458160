import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import { ImageDetailsChip } from '../ImageDetailsChip';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { TextChip } from '../TextChip';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  firstName: string;
  lastName: string;
  jobTitle: string;
  imageUrl: string;
  demographics: { [key: string]: string };
  psychographics: string[];
  docRef: DocumentReference;
  updatePath: string;
  isEditing: boolean;
}

export default function ProfileCard({
  firstName,
  lastName,
  jobTitle,
  imageUrl,
  demographics,
  psychographics,
  docRef,
  updatePath,
  isEditing,
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateDocument(docRef, updatedProperties);
    },
    300
  );

  async function addPsychographic() {
    let properties: any = {};
    properties['description.psychographics.attitudes'] = [
      ...psychographics,
      '',
    ];
    await updateDocument(docRef, properties);
  }

  async function deletePsychographic(index: number) {
    let properties: any = {};
    properties['description.psychographics.attitudes'] = psychographics.filter(
      (item, i) => i !== index
    );
    await updateDocument(docRef, properties);
  }

  return (
    <Card shadow={false}>
      <div className="flex flex-col justify-center">
        {/* Profile Image */}
        <div className="rounded-md aspect-square overflow-clip">
          {imageUrl && imageUrl.length > 0 && (
            <img
              className="object-cover"
              src={imageUrl}
              alt="Customer persona profile"
            />
          )}

          {(!imageUrl || imageUrl.length === 0) && (
            <div className="flex flex-col justify-center items-center h-full bg-slate-50">
              <div className="m-auto">
                <p className="text-gray-500">Loading...</p>
              </div>
            </div>
          )}
        </div>

        {/* Name and Job Title */}
        <div className="flex flex-col text-center mt-6">
          {/* Name */}
          <div className="flex flex-row justify-center gap-2">
            <EditableTextField
              text={firstName}
              textComponentType="h3"
              styleClasses={
                'text-2xl font-bold text-gray-700 whitespace-pre-wrap hover:bg-slate-100'
              }
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.firstName'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />

            <EditableTextField
              text={lastName}
              textComponentType="h3"
              styleClasses={
                'text-2xl font-bold text-gray-700 whitespace-pre-wrap hover:bg-slate-100'
              }
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.lastName'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />
          </div>

          {/* Job Title */}
          <div className="mt-2 mx-auto">
            <EditableTextField
              text={jobTitle}
              textComponentType="h4"
              styleClasses={
                'text-sm font-bold uppercase text-blue-600 text-center whitespace-pre-wrap hover:bg-slate-100'
              }
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.career.jobTitle'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />
          </div>
        </div>

        {/* Demographics */}
        <div className="flex flex-row flex-wrap justify-center gap-4 mt-6">
          <ImageDetailsChip
            emoji="🗓️"
            text={demographics.age}
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties['description.demographics.age'] = updatedValue;
              debouncedPropertiesUpdate(properties);
            }}
          />
          <ImageDetailsChip
            emoji="👤"
            text={demographics.gender}
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties['description.demographics.gender'] = updatedValue;
              debouncedPropertiesUpdate(properties);
            }}
          />
          <ImageDetailsChip
            emoji="💰"
            text={demographics.income}
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties['description.demographics.income'] = updatedValue;
              debouncedPropertiesUpdate(properties);
            }}
          />
          <ImageDetailsChip
            emoji="📍"
            text={demographics.location}
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties['description.demographics.location'] = updatedValue;
              debouncedPropertiesUpdate(properties);
            }}
          />
        </div>

        {/* Psychographics */}
        <div
          key={`${psychographics.length}`}
          className="flex flex-row flex-wrap justify-center gap-2 align-top mt-6"
        >
          {psychographics &&
            psychographics.length > 0 &&
            psychographics.map((eachItem: string, index: number) => (
              <TextChip
                key={index}
                title={eachItem}
                index={index}
                isEditing={isEditing}
                onValueChange={(updatedValue) => {
                  const updatedItems = psychographics.map((item) =>
                    item === eachItem ? updatedValue : item
                  );
                  let properties: any = {};
                  properties['description.psychographics.attitudes'] =
                    updatedItems;
                  debouncedPropertiesUpdate(properties);
                }}
                onRemove={async (index: number) => {
                  await deletePsychographic(index);
                }}
              />
            ))}

          {isEditing && (
            <button
              type="button"
              className={`inline-flex items-center rounded-md border border-transparent bg-transparent px-0 py-0 my-0 text-sm font-medium leading-4 text-gray-700 hover:shadow-sm hover:bg-gray-50 active:bg-gray-100`}
              onClick={async () => {
                await addPsychographic();
              }}
            >
              <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    </Card>
  );
}
