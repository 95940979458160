import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// EditorSuggestion
//

export type EditorSuggestion = {
  id: string;
  ref: DocumentReference<DocumentData>;
  content: string;
  createdAt: Date;
};

export const editorSuggestionConverter: FirestoreDataConverter<EditorSuggestion> =
  {
    toFirestore(
      editorSuggestion: WithFieldValue<EditorSuggestion>
    ): DocumentData {
      return {
        content: editorSuggestion.content,
        createdAt: editorSuggestion.createdAt,
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): EditorSuggestion {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        ref: snapshot.ref,
        content: data.content,
        createdAt: data.createdAt,
      };
    },
  };

export function editorSuggestionFromFirestore(document: any): EditorSuggestion {
  return {
    id: document.id,
    ref: document.ref,
    content: document.content,
    createdAt: document.createdAt,
  };
}

export function editorSuggestionFromStreamingContent(
  partialDocument: any
): EditorSuggestion {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    content: partialDocument.content || 'Thinking...',
    createdAt: partialDocument.createdAt || new Date(),
  };
}
