import {
  CustomerPersona,
  customerPersonaFromStreamingContent,
} from '../../models/CustomerPersonaModel';
import React, { useState } from 'react';
import {
  getIdToken,
  updateProjectCompletedChecklistStep,
} from '../../components/firebase';
import { getMaxStepsClickable, updateStatus } from '../../utils/utils';

import { MagicButton } from '../../components/Buttons/MagicButton';
import { OnboardingChecklistStepType } from '../../models/OnboardingChecklistStepModel';
import ProjectSelection from '../../components/ProjectContentSelection/ProjectSelection';
import { StepStatus } from '../../layout/Steps';
import { StepsModal } from '../../layout/StepsModal';
import StreamingCustomerPersonas from './StreamingCustomerPersonas';
import WorkCompleted from '../ProjectDetails/WorkCompleted';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import untruncateJson from 'untruncate-json';
import { useAppSelector } from '../../app/hooks';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateNewCustomerPersonaModal({ open, setOpen }: Props) {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);

  const [currentStep, setCurrentStep] = useState(0);

  const [payload, setPayload] = useState({
    projectId: project?.id,
    teamId: teamId,
    problems: {},
    features: new Set(),
  });

  const [isWorking, setIsWorking] = useState(false);

  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);

  const [streamingContent, setStreamingContent] = useState<CustomerPersona[]>(
    []
  );

  async function addNewCustomerPersona(payload: any) {
    setIsWorking(true);
    setCurrentStep(1);
    updateStatus(0, StepStatus.Done, setStepStatuses);
    updateStatus(1, StepStatus.InProgress, setStepStatuses);

    let problems: { [key: string]: any } = {};
    Object.entries(payload.problems).forEach(
      ([problemId, problemSolutionIds]) => {
        problems[problemId] = Array.from(problemSolutionIds as string[]);
      }
    );

    const formattedPayload = {
      projectId: payload.projectId,
      teamId: payload.teamId,
      numberOfGenerations: 1,
      problems: problems,
      features: Array.from(payload.features),
    };

    const idToken = await getIdToken();

    if (!idToken) {
      setIsWorking(false);
      setCurrentStep(0);
      updateStatus(0, StepStatus.NotStarted, setStepStatuses);
      updateStatus(1, StepStatus.NotStarted, setStepStatuses);
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          '/customer-personas/create-customer-personas',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify(formattedPayload),
        }
      );

      const reader = response?.body?.getReader();
      if (!reader) {
        return;
      }

      let completion = '';

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          break;
        }

        const chunk = new TextDecoder('utf-8').decode(value);

        let jsonString = '[]';

        if (chunk.length < 100) {
          completion += chunk;
          jsonString = untruncateJson(completion);
        } else {
          jsonString = untruncateJson(chunk);
        }
        try {
          const json = JSON.parse(jsonString);
          const personas = json?.map((persona: any) => {
            return customerPersonaFromStreamingContent(persona);
          });

          setStreamingContent(personas);
        } catch (error) {}
      }

      updateStatus(1, StepStatus.Done, setStepStatuses);
      updateStatus(2, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedPersona,
          project
        );
      }

      setCurrentStep(2);
    } catch (error: any) {
      console.error('Error creating the new customer persona:', error);
      alert(error.message);
      setCurrentStep(0);
      updateStatus(0, StepStatus.NotStarted, setStepStatuses);
      updateStatus(1, StepStatus.NotStarted, setStepStatuses);
    } finally {
      setIsWorking(false);
    }
  }

  function getButtonTitle(): string {
    return isWorking ? 'Working...' : 'Create New Customer Persona';
  }

  return (
    <StepsModal
      title="Create New Customer Persona"
      open={open}
      setOpen={setOpen}
      steps={[
        {
          name: 'Describe Project',
          description: 'Start by describing your project.',
          status: stepStatuses[0],
          children: (
            <div className="py-8 max-w-2xl mx-auto">
              {project && (
                <ProjectSelection
                  project={project}
                  payload={payload}
                  setPayload={setPayload}
                />
              )}
            </div>
          ),
          footer: (
            <MagicButton
              title={getButtonTitle()}
              disabled={
                isWorking ||
                stepStatuses[stepStatuses.length - 1] === StepStatus.Done
              }
              onClick={async () => {
                await addNewCustomerPersona(payload);
              }}
            />
          ),
        },
        {
          name: 'Generating Persona',
          description: 'Working hard...',
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingContent && streamingContent.length > 0 && (
                <StreamingCustomerPersonas
                  streamingCustomerPersonas={streamingContent || []}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[2],
          children: (
            <WorkCompleted
              title={'Your Customer Persona Is Ready!'}
              subtitle={'Enjoy! Please give us feedback on how you find it.'}
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={isWorking || stepStatuses[2] === StepStatus.Done}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
