import React from 'react';
import SelectMenu from '../SelectMenu';

interface Props {
  title: string;
  description?: string;
  options: { id: string | number; title: string }[];
  defaultSelected?: string | number;
  onSelectionChange?: (option: { id: string | number; title: string }) => void;
}

export default function FormSelectMenu({
  title,
  description,
  options,
  defaultSelected,
  onSelectionChange,
}: Props) {
  return (
    <div>
      <label className="block text-md font-medium text-gray-700">
        {title} <span className="text-purple-500">*</span>
      </label>
      <div className="mt-2">
        <SelectMenu
          key={title + defaultSelected}
          options={options}
          defaultSelected={defaultSelected}
          onSelectionChange={onSelectionChange}
        />
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
}
