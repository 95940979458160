import {
  DocumentType,
  getDescriptionFromDocumentType,
  getTitleFromDocumentType,
} from '../../models/DocumentModel';
import React, { useState } from 'react';

import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';

interface Props {
  documentType: DocumentType;
  onClick?: (documentType: DocumentType) => void;
}

export default function DocumentTypeCard({ documentType, onClick }: Props) {
  return (
    <li
      className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center cursor-pointer border border-slate-200`}
      onClick={() => {
        if (onClick) {
          onClick(documentType);
        }
      }}
    >
      <div className="flex flex-1 flex-col p-8">
        <div className="mx-auto h-24 w-24 flex-shrink-0 rounded-full overflow-clip">
          <div className="h-24 w-24 bg-slate-200 rounded-full flex items-center justify-center">
            <ChatBubbleBottomCenterTextIcon className="h-12 w-12 text-white " />
          </div>
        </div>
        <h3 className="mt-6 text-2xl font-medium text-gray-900">
          {getTitleFromDocumentType(documentType)}
        </h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dd className="text-sm text-gray-500 max-h-60 text-ellipsis overflow-hidden">
            {getDescriptionFromDocumentType(documentType)}
          </dd>
        </dl>
      </div>
    </li>
  );
}
