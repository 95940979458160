import React from 'react';

interface Props {
  title?: string;
  icon?: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }
  >;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function GhostButton({ title, icon: Icon, onClick }: Props) {
  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50"
      onClick={onClick}
    >
      {Icon && <Icon className="h-4 w-4" aria-hidden="true" />}
      {title && title}
    </button>
  );
}
