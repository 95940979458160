import {
  OnboardingChecklistStepType,
  OnboardingChecklistSteps,
  activeProjectChecklistSteps,
  activeUserChecklistSteps,
} from '../../models/OnboardingChecklistStepModel';
import React, { useEffect, useState } from 'react';

import Divider from '../Divider';
import OnboardingChecklistItem from './OnboardingChecklistItem';
import { selectProject } from '../../app/projectSlice';
import { selectUser } from '../../app/userSlice';
import { twMerge } from 'tailwind-merge';
import { useAppSelector } from '../../app/hooks';

interface Props {
  type: 'modal' | 'page';
  onStepClick?: (stepType: OnboardingChecklistStepType) => void;
}

export default function OnboardingChecklist({ type, onStepClick }: Props) {
  const user = useAppSelector(selectUser);
  const project = useAppSelector(selectProject);

  const [remainingSteps, setRemainingSteps] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [progress, setProgress] = useState(0);

  const [checklistSteps, setChecklistSteps] =
    useState<OnboardingChecklistSteps>({});

  useEffect(() => {
    let steps: OnboardingChecklistSteps = {};

    if (user) {
      const { onboardingChecklist } = user;
      activeUserChecklistSteps.forEach((step: string) => {
        const completed = onboardingChecklist && onboardingChecklist[step];
        steps[step] = completed;
      });
    }

    if (project) {
      const { onboardingChecklist } = project;
      activeProjectChecklistSteps.forEach((step: string) => {
        steps[step] = onboardingChecklist && onboardingChecklist[step];
      });
    }

    let stepsDone = Object.values(steps).filter((step) => step).length;
    setCompletedSteps(stepsDone);
    setRemainingSteps(Object.values(steps).length - stepsDone);
    setProgress((stepsDone / Object.values(steps).length) * 100);

    setChecklistSteps(steps);
  }, [user, project, project?.onboardingChecklist, user?.onboardingChecklist]);

  function getTitle(): string {
    if (remainingSteps === 0) {
      return 'You’re a rock star!';
    } else if (remainingSteps === 1) {
      return 'Just one more!';
    } else if (remainingSteps === 2) {
      return 'You can see the finish line!';
    } else if (completedSteps === remainingSteps) {
      return 'You’re almost there!';
    } else if (completedSteps === 0) {
      return "Let's get you started!";
    } else {
      return 'You got this!';
    }
  }

  function getSubtitle(): string {
    if (remainingSteps === 0) {
      return 'All done';
    } else {
      return `${remainingSteps} step${remainingSteps === 1 ? '' : 's'} to go`;
    }
  }

  return (
    <div
      className={twMerge(
        'flex flex-col max-w-lg mx-auto h-full',
        type === 'modal' ? 'bg-white' : ''
      )}
    >
      <div
        className={twMerge(
          'flex flex-col items-center gap-4 py-4 ',
          type === 'modal' ? 'px-4' : 'px-0'
        )}
      >
        <div className="w-full flex flex-row items-center justify-between">
          <h2 className="flex-shrink-0 font-semibold">{getTitle()}</h2>
          <p className="flex-shrink-0 text-sm text-gray-700">{getSubtitle()}</p>
        </div>

        <div className="w-full h-2 bg-gray-100 rounded-full">
          <div
            className="h-2 bg-indigo-500 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>

      {type === 'modal' && <Divider type="horizontal" />}

      <div
        className={twMerge(
          'flex flex-col max-w-lg mx-auto w-full h-full overflow-auto',
          type === 'modal' ? 'gap-y-0' : 'gap-y-4'
        )}
      >
        {Object.entries(checklistSteps).map(([step, completed]) => {
          return (
            <div
              key={step}
              className={twMerge(
                type === 'modal'
                  ? 'overflow-clip'
                  : 'rounded-lg shadow-md overflow-clip'
              )}
            >
              <OnboardingChecklistItem
                stepType={step as OnboardingChecklistStepType.CompletedProfile}
                completed={completed}
                onClick={onStepClick}
              />
              {type === 'modal' && <Divider type="horizontal" />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
