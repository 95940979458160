import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  type: 'horizontal' | 'vertical';
}

export default function Divider({ type }: Props) {
  return (
    <div
      className={twMerge(
        'border-0 border-gray-200',
        type === 'horizontal' ? 'border-b' : 'border-l'
      )}
    />
  );
}
