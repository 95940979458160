import { Feature } from '../../models/FeatureModel';
import React from 'react';
import { updateDocument } from '../../components/firebase';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  feature: Feature;
}

export function ProjectFeature({ feature }: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(async (payload) => {
    await updateDocument(payload.ref, payload.properties);
  }, 500);

  return (
    <div>
      <div className="mt-2">
        <input
          id="about"
          name="about"
          type="text"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="e.g. Create website content for your project."
          defaultValue={feature.title}
          onChange={(e) => {
            debouncedPropertiesUpdate({
              ref: feature.ref,
              properties: {
                title: e.target.value,
              },
            });
          }}
        />
      </div>
    </div>
  );
}
