import { Solution, solutionConverter } from '../../models/SolutionModel';
import {
  addDocument,
  auth,
  collection,
  db,
  doc,
  query,
  updateDocument,
  useCollectionData,
  where,
} from '../../components/firebase';

import { Card } from './../../components/Card';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Problem } from '../../models/ProblemModel';
import { Project } from '../../models/ProjectModel';
import { ProjectSolution } from './ProjectSolution';
import React from 'react';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton';
import { orderBy } from 'firebase/firestore';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  project: Project;
  problem: Problem;
}

export function ProjectProblem({ project, problem }: Props) {
  const teamId = useAppSelector(selectTeamId);

  const [solutions, solutionsLoading, solutionsError, solutionsSnapshot] =
    useCollectionData<Solution>(
      query(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'problems',
          problem.id,
          'solutions'
        ),
        where('owner', '==', doc(db, 'users', auth.currentUser?.uid || '')),
        orderBy('createdAt', 'asc')
      ).withConverter(solutionConverter),
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );

  async function addNewSolution() {
    const newSolution = {
      title: '',
    };

    try {
      await addDocument(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project.id,
          'problems',
          problem.id,
          'solutions'
        ),
        newSolution
      );
      // const solution: Solution = solutionFromFirestore(document);
    } catch (error) {
      console.error('Error creating the new project:', error);
    }
  }

  const debouncedPropertiesUpdate = useDebouncedCallback(async (payload) => {
    await updateDocument(payload.ref, payload.properties);
  }, 500);

  return (
    <Card>
      <div>
        <label
          htmlFor="about"
          className="block text-md font-medium text-gray-700"
        >
          Problem
        </label>
        <div className="mt-2">
          <input
            id="about"
            name="about"
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="e.g. A lot of indie hackers don't know how to market their projects effectively."
            defaultValue={problem.title}
            onChange={(e) => {
              debouncedPropertiesUpdate({
                ref: problem.ref,
                properties: {
                  title: e.target.value,
                },
              });
            }}
          />
        </div>
      </div>

      <div className="pt-4">
        <label
          htmlFor="about"
          className="block text-md font-medium text-gray-700"
        >
          Solutions
        </label>

        {solutions &&
          solutions.map((eachSolution) => (
            <ProjectSolution key={eachSolution.id} solution={eachSolution} />
          ))}
      </div>

      <div className="flex justify-center pt-4">
        <SecondaryButton
          title="Add New Solution"
          icon={PlusIcon}
          onClick={async () => {
            await addNewSolution();
          }}
        />
      </div>
    </Card>
  );
}
