import React from 'react';

interface Props {
  text?: string;
}

export default function ChatMessageWriting({ text }: Props) {
  return (
    <div className="flex flex-col gap-y-1.5">
      <div className="py-4 px-6 rounded-xl bg-indigo-500 text-white mr-auto whitespace-pre-wrap">
        {text || 'Thinking... 🤔'}
      </div>
      <div className="text-xs text-gray-500 text-left">Assistant</div>
    </div>
  );
}
