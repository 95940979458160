import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface Props {
  onClick: () => void;
}

export function AddNewItemCard({ onClick }: Props) {
  return (
    <div
      className="h-full flex flex-col rounded-xl shadow cursor-pointer"
      onClick={onClick}
    >
      <div className="m-auto">
        <PlusIcon className="h-10 w-10 text-gray-500" aria-hidden="true" />
      </div>
    </div>
  );
}
