import { Document, documentConverter } from '../../models/DocumentModel';
import React, { useState } from 'react';
import {
  collection,
  db,
  query,
  useCollectionData,
} from '../../components/firebase';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { CreateNewDocumentModal } from './CreateNewDocumentModal';
import { DocumentCard } from './DocumentCard';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

interface Props {}

export default function Documents({}: Props) {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const navigate = useNavigate();

  const [documents] = useCollectionData<Document>(
    project &&
      query(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'documents'
        ),
        orderBy('createdAt', 'desc')
      ).withConverter(documentConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 space-y-4">
      {project && (
        <div>
          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 min-h-[200px]">
            <li className="col-span-1 flex flex-col min-h-[5em] text-center bg-white rounded-lg ">
              <AddNewItemCard
                key="add-new-document"
                onClick={async () => {
                  setNewItemModalOpen(true);
                }}
              />
            </li>

            {documents &&
              documents.map((eachDocument) => (
                <DocumentCard
                  key={eachDocument.id}
                  document={eachDocument}
                  onClick={() => {
                    navigate(`/documents/${eachDocument.id}`);
                  }}
                />
              ))}
          </ul>

          <CreateNewDocumentModal
            project={project}
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
          />
        </div>
      )}
    </div>
  );
}
