import {
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import {
  saveElementAsPDF,
  saveElementAsPNG,
} from '../../utils/screenshotUtils';

import BulletPointsCard from '../../components/CardComponents/BulletPointsCard';
import ConfirmationModal from '../../components/ConfirmationModal';
import { CustomerPersona } from '../../models/CustomerPersonaModel';
import DropdownMenu from '../../components/DropdownMenu';
import HighlightedListCard from '../../components/CardComponents/HighlightedListCard';
import ParagraphCard from '../../components/CardComponents/ParagraphCard';
import PersonalityCard from '../../components/CardComponents/PersonalityCard';
import ProfileCard from '../../components/CardComponents/ProfileCard';
import { deleteDocument } from '../../components/firebase';
import { twMerge } from 'tailwind-merge';

interface Props {
  customerPersona: CustomerPersona;
  onClick?: (customerPersona: CustomerPersona) => void;
}

export function CustomerPersonaCard({ customerPersona, onClick }: Props) {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  async function deleteCustomerPersona() {
    await deleteDocument(customerPersona.ref);
  }

  async function saveAsPNG() {
    const element = document.getElementById(
      `customer-persona-card-${customerPersona.id}`
    );
    if (element) {
      await saveElementAsPNG(element, 'marketology-customer-persona');
    }
  }

  return (
    <li
      key={customerPersona.id}
      id={`customer-persona-card-${customerPersona.id}`}
      className={twMerge(
        `col-span-1 flex flex-col bg-slate-100 rounded-xl shadow`,
        onClick ? 'cursor-pointer' : ''
      )}
      onClick={() => {
        onClick?.(customerPersona);
      }}
    >
      <div className="flex flex-col">
        {/* Title */}

        <div className="flex flex-row flex-wrap align-middle justify-between rounded-md gap-2 mt-1 h-8 text-slate-500">
          <h3 className="my-auto mx-4 text-sm">
            <span className="font-semibold">Customer Persona Card</span>
          </h3>

          <div className="flex flex-row align-middle my-auto mr-3">
            <div className={`mr-4 ${isEditing ? '' : 'invisible'}`}>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Done
              </button>
            </div>

            <div className="my-auto">
              <DropdownMenu
                items={[
                  {
                    title: 'Edit',
                    icon: PencilSquareIcon,
                    onClick: () => {
                      setIsEditing(true);
                    },
                  },
                  {
                    title: 'Save as PNG',
                    icon: PhotoIcon,
                    onClick: async () => {
                      if (isEditing) {
                        setIsEditing(false);
                      }
                      setTimeout(() => {
                        saveAsPNG();
                      }, 1000);
                    },
                  },
                  {
                    title: 'Delete',
                    icon: TrashIcon,
                    color: 'text-red-500',
                    onClick: async () => {
                      setDeleteConfirmationOpen(true);
                    },
                  },
                ]}
              />
            </div>
            <ConfirmationModal
              title="Delete Customer Persona?"
              description="Are you sure you want to delete this customer persona? You can't undo this action."
              open={deleteConfirmationOpen}
              setOpen={setDeleteConfirmationOpen}
              onConfirm={async () => {
                await deleteCustomerPersona();
              }}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-row p-4 pt-1 gap-4 rounded-xl">
          {/* Left Column */}
          <div className="w-1/4">
            <ProfileCard
              firstName={customerPersona.firstName}
              lastName={customerPersona.lastName}
              jobTitle={
                customerPersona.career?.jobTitle ||
                customerPersona.career?.companyType ||
                ''
              }
              imageUrl={customerPersona.imageUrl || ''}
              demographics={customerPersona.demographics}
              psychographics={customerPersona.psychographics.attitudes || []}
              docRef={customerPersona.ref}
              updatePath={''}
              isEditing={isEditing}
            />
          </div>

          {/* Center Column */}
          <div className="flex flex-col shrink w-[37.5%] gap-4">
            <div>
              <ParagraphCard
                title="Bio"
                description={customerPersona.shortBio || ''}
                docRef={customerPersona.ref}
                updatePath="shortBio"
                isEditing={isEditing}
              />
            </div>

            <div className="">
              <HighlightedListCard
                title="Products Used"
                items={customerPersona.otherProductsTheyUse}
                docRef={customerPersona.ref}
                updatePath="otherProductsTheyUse"
                isEditing={isEditing}
              />
            </div>

            {/* Goals */}
            <div className="flex flex-row gap-4">
              <BulletPointsCard
                title="Goals"
                items={customerPersona.goals}
                docRef={customerPersona.ref}
                updatePath="goals"
                isEditing={isEditing}
              />
            </div>

            {/* Passions */}
            <div className="flex flex-row gap-4">
              <BulletPointsCard
                title="Hobbies"
                items={customerPersona.hobbies}
                docRef={customerPersona.ref}
                updatePath="hobbies"
                isEditing={isEditing}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="flex flex-col w-[37.5%] gap-4">
            <div className="flex flex-col justify-start">
              <PersonalityCard
                title="Personality"
                psychographics={
                  customerPersona.psychographics.personality || []
                }
                traits={customerPersona.personality}
                docRef={customerPersona.ref}
                updatePath={'personality'}
                isEditing={isEditing}
              />
            </div>

            {/* Pain Points */}
            <div className="flex flex-row gap-4">
              <BulletPointsCard
                title="Pain Points"
                items={customerPersona.painPoints}
                docRef={customerPersona.ref}
                updatePath="painPoints"
                isEditing={isEditing}
              />
            </div>

            {/* Footer */}
            <div className="flex flex-row flex-wrap align-middle justify-end rounded-md gap-2 text-slate-500">
              <h3 className="text-sm">
                Created with{' '}
                <span className="font-semibold">Marketology.ai</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
