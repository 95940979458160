import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function MagicButton({
  title = 'Start Generating',
  disabled = false,
  onClick,
}: Props) {
  return (
    <button
      type="button"
      className={twMerge(
        'mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white',
        disabled ? 'bg-gray-400' : 'cursor-pointer'
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="m-auto py-4 px-8">
        <p>{`${title} -> 🪄🎩`}</p>
      </div>
    </button>
  );
}
