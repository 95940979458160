import React, { useState } from 'react';
import {
  StreamingDocument,
  StreamingDocumentStatus,
} from '../../models/StreamingDocumentModel';
import {
  addDocument,
  collection,
  db,
  updateProjectCompletedChecklistStep,
} from '../../components/firebase';
import { getMaxStepsClickable, updateStatus } from '../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { CreateNewGenericDocumentDetails } from './CreateNewGenericDocumentDetails';
import { DocumentType } from '../../models/DocumentModel';
import DocumentTypeCard from './DocumentTypeCard';
import { MagicButton } from '../../components/Buttons/MagicButton';
import { OnboardingChecklistStepType } from '../../models/OnboardingChecklistStepModel';
import { Project } from '../../models/ProjectModel';
import { StepStatus } from '../../layout/Steps';
import { StepsModal } from '../../layout/StepsModal';
import WorkCompleted from '../ProjectDetails/WorkCompleted';
import { selectProjectId } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { setStreamingDocument } from '../../app/documentSlice';
import { useNavigate } from 'react-router-dom';

const docTypes: DocumentType[] = [
  DocumentType.Blank,
  DocumentType.Generic,
  DocumentType.LinkedInPost,
  DocumentType.TwitterPost,
  // DocumentType.TwitterThread,
  DocumentType.FacebookPost,
  DocumentType.InstagramPostCaption,
  DocumentType.PressRelease,
];

interface Props {
  project: Project;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateNewDocumentModal({ project, open, setOpen }: Props) {
  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);

  const [generationPayload, setGenerationPayload] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(0);

  const [isWorking, setIsWorking] = useState(false);

  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);

  const [documentType, setDocumentType] = useState<DocumentType | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function addNewDocument() {
    setIsWorking(true);
    updateStatus(1, StepStatus.Done, setStepStatuses);
    setCurrentStep(2);

    const formattedPayload = {
      type: documentType,
      title: generationPayload.documentTitle,
    };

    try {
      const newDocument = await addDocument(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          projectId || '',
          'documents'
        ),
        formattedPayload
      );

      if (!newDocument) {
        updateStatus(1, StepStatus.NotStarted, setStepStatuses);
        setCurrentStep(0);
      }

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedDocument,
          project
        );
      }

      const streamingDocument: StreamingDocument = {
        id: newDocument?.id || '',
        title: generationPayload.documentTitle,
        type: documentType || DocumentType.Generic,
        status:
          documentType !== DocumentType.Blank
            ? StreamingDocumentStatus.Pending
            : StreamingDocumentStatus.Done,
        prompt: {
          documentTitle: generationPayload.documentTitle,
          documentType: documentType || DocumentType.Generic,
          generationDescription: generationPayload.description,
          toneOfVoice:
            generationPayload.toneOfVoice || 'Professional, Friendly',
        },
      };
      dispatch(setStreamingDocument(streamingDocument));
      navigate(`/documents/${newDocument?.id}`);
    } catch (error: any) {
      console.error('Error creating the new document:', error);
      alert(error.message);
      setCurrentStep(1);
    } finally {
      setIsWorking(false);
    }
  }

  async function handleDocumentTypeSelected(type: DocumentType) {
    if (
      !teamId ||
      isWorking ||
      stepStatuses[stepStatuses.length - 1] === StepStatus.Done
    ) {
      return;
    }

    setDocumentType(type);
    updateStatus(0, StepStatus.Done, setStepStatuses);
    setCurrentStep(1);
  }

  return (
    <StepsModal
      title="Create Document"
      open={open}
      setOpen={setOpen}
      steps={[
        {
          name: 'Select Document Type',
          description: 'The document type.',
          status: stepStatuses[0],
          children: (
            <div className="py-8">
              <h3 className="text-xl font-medium text-gray-900">
                Select Document Type:
              </h3>

              <ul className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                {docTypes.map((documentType) => (
                  <DocumentTypeCard
                    key={documentType}
                    documentType={documentType}
                    onClick={(type: DocumentType) => {
                      handleDocumentTypeSelected(type);
                    }}
                  />
                ))}
              </ul>
            </div>
          ),
        },
        {
          name: 'Document Details',
          description: 'More document details.',
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center max-w-4xl mx-auto">
              <CreateNewGenericDocumentDetails
                documentType={documentType || DocumentType.Generic}
                onPayloadChange={(payload: any) => {
                  setGenerationPayload(payload);
                }}
              />
            </div>
          ),
          footer: (
            <MagicButton
              title={
                documentType === DocumentType.Blank
                  ? 'Create Document'
                  : 'Start Generating'
              }
              disabled={isWorking || !documentType}
              onClick={async () => {
                await addNewDocument();
              }}
            />
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[2],
          children: (
            <WorkCompleted
              title={'Your Document Content Is Ready!'}
              subtitle={
                'You can close this now - your new document is in the Twitter Documents section.'
              }
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={stepStatuses[0] === StepStatus.Done}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
