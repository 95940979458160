import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// CustomerPersona
//

type CustomerPersona = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  firstName: string;
  lastName: string;
  shortBio: string;
  demographics: {
    age: string;
    gender: string;
    location: string;
    income: string;
  };
  career: {
    companyType: string;
    jobTitle: string;
  };
  psychographics: {
    values: string[];
    attitudes: string[];
    personality: string[];
  };
  personality: {
    introvertOrExtrovertOutOf5: number;
    analyticalOrCreativeOutOf5: number;
    conservativeOrLiberalOutOf5: number;
    passiveOrActiveOutOf5: number;
  };
  painPoints: string[];
  goals: string[];
  hobbies: string[];
  otherProductsTheyUse: string[];
  imageUrl?: string;
  createdAt: Date;
};

const customerPersonaConverter: FirestoreDataConverter<CustomerPersona> = {
  toFirestore(customerPersona: WithFieldValue<CustomerPersona>): DocumentData {
    return {
      owner: customerPersona.owner,
      firstName: customerPersona.firstName,
      lastName: customerPersona.lastName,
      shortBio: customerPersona.shortBio,
      demographics: customerPersona.demographics,
      career: customerPersona.career,
      psychographics: customerPersona.psychographics,
      personality: customerPersona.personality,
      painPoints: customerPersona.painPoints,
      goals: customerPersona.goals,
      hobbies: customerPersona.hobbies,
      otherProductsTheyUse: customerPersona.otherProductsTheyUse,
      imageUrl: customerPersona.imageUrl,
      createdAt: customerPersona.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): CustomerPersona {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      owner: data.owner,
      firstName: data.firstName,
      lastName: data.lastName,
      shortBio: data.shortBio,
      demographics: data.demographics,
      career: data.career,
      psychographics: data.psychographics,
      personality: data.personality,
      painPoints: data.painPoints,
      goals: data.goals,
      hobbies: data.hobbies,
      otherProductsTheyUse: data.otherProductsTheyUse,
      imageUrl: data.imageUrl,
      createdAt: data.createdAt,
    };
  },
};

function customerPersonaFromFirestore(document: any): CustomerPersona {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    firstName: document.firstName,
    lastName: document.lastName,
    shortBio: document.shortBio,
    demographics: document.demographics,
    career: document.career,
    psychographics: document.psychographics,
    personality: document.personality,
    painPoints: document.painPoints,
    goals: document.goals,
    hobbies: document.hobbies,
    otherProductsTheyUse: document.otherProductsTheyUse,
    imageUrl: document.imageUrl,
    createdAt: document.createdAt,
  };
}

function customerPersonaFromStreamingContent(
  partialDocument: any
): CustomerPersona {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    owner: partialDocument.owner || '',
    firstName: partialDocument.firstName || 'Writing',
    lastName: partialDocument.lastName || 'in progress...',
    shortBio: partialDocument.shortBio || 'Bio coming up...',
    demographics: partialDocument.demographics || {},
    career: partialDocument.career || {},
    psychographics: partialDocument.psychographics || {},
    personality: partialDocument.personality || {},
    painPoints: partialDocument.painPoints || [],
    goals: partialDocument.goals || [],
    hobbies: partialDocument.hobbies || [],
    otherProductsTheyUse: partialDocument.otherProductsTheyUse || [],
    imageUrl: partialDocument.imageUrl || '',
    createdAt: partialDocument.createdAt || new Date(),
  };
}

export type { CustomerPersona };
export {
  customerPersonaConverter,
  customerPersonaFromFirestore,
  customerPersonaFromStreamingContent,
};
