import {
  StreamingMarketingChannel,
  channelPersonaLinkFromStreamingMC,
  marketingChannelFromStreamingMC,
} from '../../models/StreamingMarketingChannelModel';

import { CustomerPersona } from '../../models/CustomerPersonaModel';
import { MarketingChannelCard } from './MarketingChannelCard';
import React from 'react';

interface Props {
  streamingMarketingChannels: StreamingMarketingChannel[];
  relatedPersonas?: CustomerPersona[];
}

export default function MarketingChannels({
  streamingMarketingChannels,
  relatedPersonas,
}: Props) {
  return (
    <div className="flex flex-col max-w-7xl mx-auto">
      {(!streamingMarketingChannels ||
        streamingMarketingChannels?.length === 0) && (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No Marketing Channels had been generated yet.
          </h3>
        </div>
      )}

      {streamingMarketingChannels && streamingMarketingChannels.length > 0 && (
        <div>
          <ul className="grid grid-cols-1 gap-6">
            {streamingMarketingChannels.map((eachStreamingMC) => (
              <MarketingChannelCard
                key={`${JSON.stringify(eachStreamingMC).length}}`}
                marketingChannel={marketingChannelFromStreamingMC(
                  eachStreamingMC
                )}
                relatedPersona={
                  relatedPersonas?.filter(
                    (_) => _.id === eachStreamingMC.personas[0]?.id
                  )[0]
                }
                streamingChannelPersonaLink={channelPersonaLinkFromStreamingMC(
                  eachStreamingMC
                )}
                onClick={() => {}}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
