import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  faHeading,
  faListOl,
  faListUl,
  faParagraph,
} from '@fortawesome/free-solid-svg-icons';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Editor } from '@tiptap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

const options = [
  {
    id: 'h1',
    title: 'Heading 1',
    icon: (
      <>
        <FontAwesomeIcon icon={faHeading} />
        <span className="font-medium">1</span>
      </>
    ),
  },
  {
    id: 'h2',
    title: 'Heading 2',
    icon: (
      <>
        <FontAwesomeIcon icon={faHeading} />
        <span className="font-medium">2</span>
      </>
    ),
  },
  {
    id: 'h3',
    title: 'Heading 3',
    icon: (
      <>
        <FontAwesomeIcon icon={faHeading} />
        <span className="font-medium">3</span>
      </>
    ),
  },
  {
    id: 'p',
    title: 'Paragraph',
    icon: (
      <>
        <FontAwesomeIcon icon={faParagraph} />
      </>
    ),
  },
  {
    id: 'ul',
    title: 'Bullets',
    icon: (
      <>
        <FontAwesomeIcon icon={faListUl} />
      </>
    ),
  },
  {
    id: 'ol',
    title: 'List',
    icon: (
      <>
        <FontAwesomeIcon icon={faListOl} />
      </>
    ),
  },
];

interface Props {
  editor: Editor | null;
}

export default function RichTextEditorTextStyleMenu({ editor }: Props) {
  const [selected, setSelected] = useState<string | number | undefined>(
    getTextStyle()
  );

  useEffect(() => {
    setSelected(getTextStyle());
  }, [
    editor?.isActive('heading', { level: 1 }),
    editor?.isActive('heading', { level: 2 }),
    editor?.isActive('heading', { level: 3 }),
    editor?.isActive('bulletList'),
    editor?.isActive('orderedList'),
    editor?.isActive('paragraph'),
  ]);

  function getTextStyle() {
    if (editor?.isActive('heading', { level: 1 })) {
      return 'h1';
    } else if (editor?.isActive('heading', { level: 2 })) {
      return 'h2';
    } else if (editor?.isActive('heading', { level: 3 })) {
      return 'h3';
    } else if (editor?.isActive('bulletList')) {
      return 'ul';
    } else if (editor?.isActive('orderedList')) {
      return 'ol';
    } else {
      return 'p';
    }
  }

  function handleSelectedStyle(selectedId: string) {
    if (selectedId === 'h1') {
      editor?.chain().focus().setHeading({ level: 1 }).run();
    } else if (selectedId === 'h2') {
      editor?.chain().focus().setHeading({ level: 2 }).run();
    } else if (selectedId === 'h3') {
      editor?.chain().focus().setHeading({ level: 3 }).run();
    } else if (selectedId === 'ul') {
      editor?.chain().focus().toggleBulletList().run();
    } else if (selectedId === 'ol') {
      editor?.chain().focus().toggleOrderedList().run();
    } else {
      editor?.chain().focus().setParagraph().run();
    }
  }

  return (
    <Listbox
      key={selected}
      value={selected}
      onChange={(selection: any) => {
        handleSelectedStyle(selection);
      }}
    >
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="relative flex flex-row w-full align-middle items-center justify-between cursor-pointer rounded-md bg-white py-1.5 pl-4 pr-2 text-gray-900 sm:text-sm sm:leading-6 min-w-[65px] hover:bg-gray-50">
            <span className="">
              {options.filter((option) => option.id === selected)[0]?.icon ||
                '...'}
            </span>
            <span className="pointer-events-none inset-y-0 right-0 flex items-center">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options className="absolute z-10 mt-1 -mx-2 max-h-60 w-[calc(100%_+_16px)] overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    twMerge(
                      active ? 'bg-gray-50' : 'text-gray-900',
                      'relative cursor-pointer select-none py-2 text-center'
                    )
                  }
                  value={option.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={twMerge(
                          selected ? 'text-gray-900' : 'text-gray-400',
                          'block truncate'
                        )}
                      >
                        {option.icon}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
