import { ChatMessage } from '../../models/ChatMessageModel';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  message:
    | ChatMessage
    | {
        role: 'assistant' | 'user';
        content: string;
      };
}

export default function ChatMessageBubble({ message }: Props) {
  return (
    <div className="flex flex-col gap-y-1.5">
      <div
        className={`${
          message.role === 'assistant'
            ? 'bg-indigo-500 text-white mr-auto'
            : 'bg-gray-200 ml-auto'
        } py-4 px-6 rounded-xl whitespace-pre-wrap`}
      >
        {message.content}
      </div>
      <div
        className={twMerge(
          'text-xs text-gray-500',
          message.role === 'assistant' ? 'text-left' : 'text-right'
        )}
      >
        {message.role === 'assistant' ? 'Assistant' : 'You'}
      </div>
    </div>
  );
}
