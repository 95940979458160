import React, { useEffect } from 'react';
import {
  db,
  doc,
  updateDocument,
  updateProjectCompletedChecklistStep,
} from './firebase';

import { Card } from './Card';
import FormMultiLineTextInput from './InputFields/FormMultiLineTextInput';
import FormSelectMenu from './InputFields/FormSelectMenu';
import FormSingleLineTextInput from './InputFields/FormSingleLineTextInput';
import { OnboardingChecklistStepType } from '../models/OnboardingChecklistStepModel';
import { selectProject } from '../app/projectSlice';
import { selectTeamId } from '../app/teamSlice';
import { useAppSelector } from '../app/hooks';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  shadow?: boolean;
}

export function ProjectHeader({ shadow = true }: Props) {
  const currentTeamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);

  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      const docId = updatedProperties.id;
      delete updatedProperties.id;

      await updateDocument(
        doc(db, 'teams', currentTeamId || '', 'projects', docId),
        updatedProperties
      );
    },
    100
  );

  useEffect(() => {
    if (!project) {
      return;
    }

    if (
      (project?.title?.length || 0) >= 2 &&
      (project?.description?.length || 0) > 10 &&
      (project?.toneOfVoice?.length || 0) >= 3 &&
      (project?.businessType?.length || 0) > 0
    ) {
      updateProjectCompletedChecklistStep(
        OnboardingChecklistStepType.CompletedProjectDescription,
        project,
        true
      );
    } else {
      updateProjectCompletedChecklistStep(
        OnboardingChecklistStepType.CompletedProjectDescription,
        project,
        false
      );
    }
  }, [
    project,
    project?.title,
    project?.description,
    project?.toneOfVoice,
    project?.businessType,
  ]);

  async function updateBusinessType(businessType: string) {
    await updateDocument(
      doc(db, 'teams', currentTeamId || '', 'projects', project?.id || ''),
      { businessType }
    );
  }

  return (
    <Card shadow={shadow}>
      <div className="flex flex-col justify-start">
        <div>
          <FormSingleLineTextInput
            htmlId="projectName"
            htmlName="projectName"
            title="Project Name"
            text={project?.title}
            placeholder="e.g. Marketology.ai"
            onChange={(value: string) => {
              debouncedPropertiesUpdate({
                id: project?.id,
                title: value,
              });
            }}
          />
        </div>

        <div className="mt-4">
          <FormMultiLineTextInput
            htmlId="projectDescription"
            htmlName="projectDescription"
            title="A Brief Description"
            text={project?.description}
            placeholder="e.g. Marketology is the AI marketing buddy designed for startups and indie hackers. Discover your ideal clients and marketing channels, craft impactful personas, turn your features into sexy benefits and write spot-on content. All you need to do is describe your product."
            description="Around 100 words is ideal."
            onChange={(value: string) => {
              debouncedPropertiesUpdate({
                id: project?.id,
                description: value,
              });
            }}
          />
        </div>

        <div className="flex flex-col mt-4 min-w-[300px] mr-auto">
          <FormSelectMenu
            title="Business Type"
            options={[
              { id: 'B2B', title: 'Business to Business (B2B)' },
              { id: 'B2C', title: 'Business to Consumer (B2C)' },
            ]}
            defaultSelected={project?.businessType}
            onSelectionChange={async (option: {
              id: string | number;
              title: string;
            }) => {
              await updateBusinessType(option.id as string);
            }}
          />
        </div>

        <div className="mt-4">
          <FormSingleLineTextInput
            title="Tone of Voice"
            text={project?.toneOfVoice}
            placeholder="Authorative, Casual, Funny, etc."
            onChange={(value: string) => {
              debouncedPropertiesUpdate({
                id: project?.id,
                toneOfVoice: value,
              });
            }}
          />
        </div>
      </div>
    </Card>
  );
}
