import { Feature, featureConverter } from '../../models/FeatureModel';
import React, { useEffect } from 'react';
import {
  addDocument,
  collection,
  db,
  query,
  useCollectionData,
} from '../../components/firebase';

import { PlusIcon } from '@heroicons/react/24/outline';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import { ProjectFeature } from './ProjectFeature';
import { orderBy } from 'firebase/firestore';
import { selectProjectId } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
  onFeatureCountChange?: (count: number) => void;
}

export default function ProjectFeaturesSection({
  onFeatureCountChange,
}: Props) {
  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);

  const [features, featuresLoading, featuresError, featuresSnapshot] =
    useCollectionData<Feature>(
      projectId
        ? query(
            collection(
              db,
              'teams',
              teamId || '',
              'projects',
              projectId || '',
              'features'
            ),
            orderBy('createdAt', 'asc')
          ).withConverter(featureConverter)
        : null,
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );

  useEffect(() => {
    onFeatureCountChange?.(features?.length || 0);
  }, [features]);

  async function addNewFeature() {
    const newFeature = {
      title: '',
    };

    try {
      await addDocument(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          projectId || '',
          'features'
        ),
        newFeature
      );
    } catch (error) {
      console.error('Error creating the new feature:', error);
    }
  }

  return (
    <div className="flex flex-col space-y-4">
      {projectId && (
        <div className="">
          <h2 className="text-2xl pt-4">Features</h2>
          <p className="mt-1 text-sm text-gray-500">
            What are this project's features?
          </p>

          {features &&
            features.map((eachFeature) => (
              <ProjectFeature key={eachFeature.id} feature={eachFeature} />
            ))}

          <div className="flex justify-center mt-5">
            <PrimaryButton
              title="New Feature"
              icon={PlusIcon}
              onClick={async () => {
                await addNewFeature();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
