import { IcpCard } from './IcpCard';
import { IdealCustomerProfile } from '../../models/IdealCustomerProfileModel';
import React from 'react';

interface Props {
  streamingIcps: IdealCustomerProfile[];
}

export default function IdealCustomerProfiles({ streamingIcps }: Props) {
  return (
    <div className="flex flex-col max-w-7xl mx-auto">
      {(!streamingIcps || streamingIcps?.length === 0) && (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No ICPs had been generated yet.
          </h3>
        </div>
      )}

      {streamingIcps && streamingIcps.length > 0 && (
        <div>
          <ul className="grid grid-cols-1 gap-6">
            {streamingIcps.map((eachIcp) => (
              <IcpCard
                key={eachIcp.id + `${JSON.stringify(eachIcp).length}}`}
                idealCustomerProfile={eachIcp}
                onClick={() => {}}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
