import {
  Project,
  projectConverter,
  projectFromFirestore,
} from '../../models/ProjectModel';
import {
  addDocument,
  collection,
  db,
  query,
  updateUserCompletedChecklistStep,
  useCollectionData,
} from '../../components/firebase';
import { selectProject, setSelectedProject } from '../../app/projectSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { Modal } from '../../layout/Modal';
import { OnboardingChecklistStepType } from '../../models/OnboardingChecklistStepModel';
import { ProjectCard } from './ProjectCard';
import React from 'react';
import { selectTeam } from '../../app/teamSlice';
import { selectUser } from '../../app/userSlice';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function SelectProjectModal({ open, setOpen }: Props) {
  const user = useAppSelector(selectUser);
  const currentlySelectedProject = useAppSelector(selectProject);
  const currentTeam = useAppSelector(selectTeam);
  const dispatch = useAppDispatch();

  const [projects] = useCollectionData<Project>(
    currentTeam
      ? query(
          collection(db, 'teams', currentTeam?.id || '', 'projects')
        ).withConverter(projectConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  async function addNewProject() {
    const newProject = {
      title: '',
    };

    try {
      const document = await addDocument(
        collection(db, 'teams', currentTeam?.id || '', 'projects'),
        newProject
      );
      const project: Project = projectFromFirestore(document);
      dispatch(setSelectedProject(project));
      if (user) {
        await updateUserCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedProject,
          user
        );
      }
      setOpen(false);
    } catch (error) {
      console.error('Error creating the new project:', error);
    }
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li className="col-span-1 flex flex-col text-center bg-white rounded-lg ">
          <AddNewItemCard
            onClick={async () => {
              await addNewProject();
            }}
          />
        </li>

        {projects &&
          projects.map((eachProject) => (
            <ProjectCard
              key={eachProject.id}
              title={eachProject.title}
              description={eachProject.description}
              imageUrl={eachProject.imageUrl}
              selected={currentlySelectedProject?.id === eachProject.id}
              onClick={() => {
                dispatch(setSelectedProject(eachProject));
                setOpen(false);
              }}
            />
          ))}
      </ul>
    </Modal>
  );
}
