import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// Problem
//

type Problem = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  title?: string;
  description?: string;
  createdAt: Date;
};

const problemConverter: FirestoreDataConverter<Problem> = {
  toFirestore(problem: WithFieldValue<Problem>): DocumentData {
    return {
      owner: problem.owner,
      title: problem.title,
      description: problem.description,
      createdAt: problem.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Problem {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      owner: data.owner,
      title: data.title,
      description: data.description,
      createdAt: data.createdAt,
    };
  },
};

function problemFromFirestore(document: any): Problem {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    title: document.title,
    description: document.description,
    createdAt: document.createdAt,
  };
}

export type { Problem };
export { problemConverter, problemFromFirestore };
