import React, { useState } from 'react';
import {
  TwitterTweet,
  TwitterTweetType,
  twitterTweetFromStreamingContent,
} from '../../../models/TwitterTweetModel';
import { getMaxStepsClickable, updateStatus } from '../../../utils/utils';

import { ComputerDesktopIcon } from '@heroicons/react/24/solid';
import { CreateNewTwitterTweetDetails } from './CreateNewTwitterTweetDetails';
import { Project } from '../../../models/ProjectModel';
import { StepStatus } from '../../../layout/Steps';
import { StepsModal } from '../../../layout/StepsModal';
import StreamingTwitterTweets from './StreamingTwitterTweets';
import TwitterTweetTypeCard from './TwitterTweetTypeCard';
import WorkCompleted from '../../ProjectDetails/WorkCompleted';
import { getIdToken } from '../../../components/firebase';
import { selectTeamId } from '../../../app/teamSlice';
import untruncateJson from 'untruncate-json';
import { useAppSelector } from '../../../app/hooks';

const tweetTypes: TwitterTweetType[] = [
  TwitterTweetType.Generic,
  TwitterTweetType.UnpopularOpinion,
  TwitterTweetType.Thinking,
  TwitterTweetType.HotTake,
  TwitterTweetType.AskMeAnything,
  TwitterTweetType.ThisOrThat,
  TwitterTweetType.Top3,
  TwitterTweetType.Confession,
];

interface Props {
  project: Project;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateNewTwitterTweetModal({ project, open, setOpen }: Props) {
  const teamId = useAppSelector(selectTeamId);

  const [currentStep, setCurrentStep] = useState(0);

  const [isWorking, setIsWorking] = useState(true);

  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);

  const [streamingContent, setStreamingContent] = useState<TwitterTweet[]>();

  const [tweetType, setTweetType] = useState<TwitterTweetType | null>(null);

  async function addNewTwitterTweet(
    projectId: string,
    teamId: string,
    tweetType: TwitterTweetType
  ) {
    setIsWorking(true);
    setCurrentStep(1);
    updateStatus(0, StepStatus.Done, setStepStatuses);
    updateStatus(1, StepStatus.InProgress, setStepStatuses);

    const formattedPayload = {
      projectId,
      teamId,
      tweetType,
      problems: {},
      features: [],
    };

    const idToken = await getIdToken();

    if (!idToken) {
      setIsWorking(false);
      setCurrentStep(0);
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + '/twitter/create-tweet',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify(formattedPayload),
        }
      );

      const reader = response?.body?.getReader();
      if (!reader) {
        return;
      }

      let completion = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const chunk = new TextDecoder('utf-8').decode(value);

        completion += chunk;

        const jsonString = untruncateJson(completion);

        try {
          const contentJson = JSON.parse(jsonString);
          const twitterTweets = contentJson?.map((tweet: any) => {
            return twitterTweetFromStreamingContent(tweet);
          });

          setStreamingContent(twitterTweets);
        } catch (error) {
          console.error('Error parsing the streaming content:', error);
        }
      }

      updateStatus(1, StepStatus.Done, setStepStatuses);
      updateStatus(2, StepStatus.Done, setStepStatuses);
      setCurrentStep(2);
    } catch (error: any) {
      console.error('Error creating the new landing page:', error);
      alert(error.message);
      setCurrentStep(0);
    } finally {
      setIsWorking(false);
    }
  }

  async function handleTweetTypeSelected(type: TwitterTweetType) {
    if (
      !teamId ||
      isWorking ||
      stepStatuses[stepStatuses.length - 1] === StepStatus.Done
    ) {
      return;
    }

    setTweetType(type);
    setCurrentStep(1);

    await addNewTwitterTweet(project.id, teamId, type);
  }

  return (
    <StepsModal
      title="Create Tweet"
      open={open}
      setOpen={setOpen}
      steps={[
        {
          name: 'Select Tweet Type',
          description: 'The tweet type.',
          status: stepStatuses[0],
          children: (
            <div className="py-8">
              <h3 className="text-xl font-medium text-gray-900">
                Select Tweet Type:
              </h3>

              <ul className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                {tweetTypes.map((tweetType) => (
                  <TwitterTweetTypeCard
                    tweetType={tweetType}
                    onClick={(type: TwitterTweetType) => {
                      setTweetType(type);
                      setCurrentStep(1);
                    }}
                  />
                ))}
              </ul>
            </div>
          ),
        },
        {
          name: 'Tweet Details',
          description: 'More tweet details.',
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center max-w-4xl mx-auto">
              <CreateNewTwitterTweetDetails
                tweetType={tweetType || TwitterTweetType.Generic}
              />
            </div>
          ),
        },
        {
          name: 'Generate Tweet',
          description: 'Generate the landing page.',
          status: stepStatuses[2],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingContent && (
                <StreamingTwitterTweets streamingTweets={streamingContent} />
              )}
            </div>
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[3],
          children: (
            <WorkCompleted
              title={'Your Tweet Content Is Ready!'}
              subtitle={
                'You can close this now - your new tweet is in the Twitter Tweets section.'
              }
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={isWorking || stepStatuses[2] === StepStatus.Done}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
