import { PrimaryButton } from './Buttons/PrimaryButton';
import React from 'react';
import dayjs from 'dayjs';
import { selectTeam } from '../app/teamSlice';
import { twMerge } from 'tailwind-merge';
import { useAppSelector } from '../app/hooks';
import { useNavigate } from 'react-router-dom';

const STARTER_PLAN_ID = 'STARTER';
const GROWTH_TRIAL_PLAN_ID = 'GROWTH_TRIAL';

export default function UpgradePlanCard() {
  const team = useAppSelector(selectTeam);
  const [isVisible, setIsVisible] = React.useState(true);
  const navigate = useNavigate();

  const daysLeftInTrial: number = (() => {
    if (!team) {
      return 0;
    }

    const trialEnd = dayjs(team.createdAt).endOf('day').add(14, 'day');
    return trialEnd.diff(dayjs().endOf('day'), 'day');
  })();

  const cardContent:
    | {
        message: string;
        backgroundColor: string;
        borderColor: string;
      }
    | undefined = (() => {
    let message = 'Get unlimited access.';
    let backgroundColor = 'bg-indigo-100';
    let borderColor = 'border-indigo-300';

    switch (team?.subscriptionPlan) {
      case STARTER_PLAN_ID:
        message =
          "You're on the free plan. Upgrade today for unlimited access.";
        backgroundColor = 'bg-indigo-100';
        borderColor = 'border-indigo-300';
        break;
      case GROWTH_TRIAL_PLAN_ID:
        if (daysLeftInTrial < 0) {
          message =
            "Your free trial has expired and you've been downgraded to the Starter plan. Upgrade to get access to everything Marketology has to offer.";
          backgroundColor = 'bg-red-100';
          borderColor = 'border-red-300';
        } else if (daysLeftInTrial === 0) {
          message =
            "Your Free Trial expires today and you'll lose access to most of the tools. Upgrade now to keep access to everything Marketology has to offer.";
          backgroundColor = 'bg-red-100';
          borderColor = 'border-red-300';
        } else {
          let daysLeftString =
            daysLeftInTrial === 1
              ? `${daysLeftInTrial} day`
              : `${daysLeftInTrial} days`;

          if (daysLeftInTrial <= 5) {
            message = `Your Free Trial expires in ${daysLeftString} and you'll lose access to most of the tools. Upgrade now to keep your full access to Marketology.`;
            backgroundColor = 'bg-red-100';
            borderColor = 'border-red-300';
          } else if (daysLeftInTrial <= 10) {
            message = `Your Free Trial expires in ${daysLeftString}. Upgrade now to keep your full access to Marketology.`;
            backgroundColor = 'bg-yellow-100';
            borderColor = 'border-yellow-300';
          } else {
            message = `Your Free Trial expires in ${daysLeftString}. Upgrade now to keep your full access to Marketology.`;
          }
        }
        break;
      default:
        return undefined;
    }
    return {
      message,
      backgroundColor,
      borderColor,
    };
  })();

  return (
    <>
      {cardContent && isVisible && (
        <div className="py-4">
          <div
            className={twMerge(
              'rounded-lg border',
              cardContent.backgroundColor,
              cardContent.borderColor
            )}
          >
            <div className="flex flex-col items-start">
              <div className="mt-4 mx-4 text-sm text-gray-800">
                <p>{cardContent.message}</p>
              </div>

              <div className="p-4 flex flex-row">
                <PrimaryButton
                  title={'Upgrade'}
                  onClick={() => navigate('/settings/billing')}
                />
                <button
                  type="button"
                  className="inline-flex items-center bg-transparent px-4 py-2 text-sm font-medium leading-4 text-gray-700 hover:text-gray-90"
                  onClick={() => setIsVisible(false)}
                >
                  Hide
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
