import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import { OnboardingChecklistSteps } from './OnboardingChecklistStepModel';

//
// Project
//

type Project = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  title?: string;
  description?: string;
  websiteUrl?: string;
  imageUrl?: string;
  businessType?: string;
  toneOfVoice?: string;
  onboardingChecklist: OnboardingChecklistSteps;
  createdAt: Date;
};

const projectConverter: FirestoreDataConverter<Project> = {
  toFirestore(project: WithFieldValue<Project>): DocumentData {
    return {
      owner: project.owner,
      title: project.title,
      description: project.description,
      websiteUrl: project.websiteUrl,
      imageUrl: project.imageUrl,
      businessType: project.businessType,
      toneOfVoice: project.toneOfVoice,
      onboardingChecklist: project.onboardingChecklist,
      createdAt: project.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Project {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      owner: data.owner,
      title: data.title,
      description: data.description,
      websiteUrl: data.websiteUrl,
      imageUrl: data.imageUrl,
      businessType: data.businessType,
      toneOfVoice: data.toneOfVoice,
      onboardingChecklist: data.onboardingChecklist,
      createdAt: data.createdAt,
    };
  },
};

function projectFromFirestore(document: any): Project {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    title: document.title,
    description: document.description,
    websiteUrl: document.websiteUrl,
    imageUrl: document.imageUrl,
    businessType: document.businessType,
    toneOfVoice: document.toneOfVoice,
    onboardingChecklist: document.onboardingChecklist,
    createdAt: document.createdAt,
  };
}

export type { Project };
export { projectConverter, projectFromFirestore };
