// utils

import { StepStatus } from '../layout/Steps';
import { getIdToken } from '../components/firebase';
import untruncateJson from 'untruncate-json';

export function titleCase(str: string) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export async function streamResponse(
  url: string,
  payload: any,
  itemTransformer: any,
  itemStreamingHandler: React.SetStateAction<any>
) {
  const idToken = await getIdToken();

  if (!idToken) {
    return;
  }

  const response = await fetch(process.env.REACT_APP_API_BASE_URL + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken,
    },
    body: JSON.stringify(payload),
  });

  const reader = response?.body?.getReader();
  if (!reader) {
    return;
  }

  let completion = '';

  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }

    const chunk = new TextDecoder('utf-8').decode(value);

    let jsonString = '[]';

    // If the chunk is larger than 100 characters, it's
    // a complete answer, at the end of the stream
    if (chunk.length > 100) {
      jsonString = untruncateJson(chunk);
    } else {
      completion += chunk;
      jsonString = untruncateJson(completion);
    }

    try {
      const json = JSON.parse(jsonString);
      const items = json?.map(itemTransformer);
      itemStreamingHandler(items);
    } catch (error) {}
  }
}

export function updateStatus(
  index: number,
  newStatus: StepStatus,
  setStepStatuses: React.Dispatch<React.SetStateAction<StepStatus[]>>
) {
  setStepStatuses((prevStepStatuses) =>
    prevStepStatuses.map((status, i) => (i === index ? newStatus : status))
  );
}

export function getMaxStepsClickable(stepStatuses: StepStatus[]): number {
  if (stepStatuses.length === 0) {
    return 0;
  }

  return Math.max(
    0,
    stepStatuses[stepStatuses.length - 1] === StepStatus.Done
      ? stepStatuses.length - 1
      : stepStatuses.length - 2
  );
}

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}
