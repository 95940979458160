import {
  Benefit,
  benefitFromStreamingContent,
} from '../../models/BenefitModel';
import {
  CustomerPersona,
  customerPersonaConverter,
  customerPersonaFromStreamingContent,
} from '../../models/CustomerPersonaModel';
import { Feature, featureConverter } from '../../models/FeatureModel';
import {
  IdealCustomerProfile,
  idealCustomerProfileFromStreamingContent,
} from '../../models/IdealCustomerProfileModel';
import React, { useEffect, useState } from 'react';
import {
  StreamingMarketingChannel,
  streamingMarketingChannelFromStreamingContent,
} from '../../models/StreamingMarketingChannelModel';
import {
  collection,
  db,
  query,
  updateProjectCompletedChecklistStep,
  useCollectionData,
} from '../../components/firebase';
import {
  getMaxStepsClickable,
  streamResponse,
  updateStatus,
} from '../../utils/utils';
import { selectProject, selectProjectId } from '../../app/projectSlice';

import { MagicButton } from '../../components/Buttons/MagicButton';
import { OnboardingChecklistStepType } from '../../models/OnboardingChecklistStepModel';
import ProjectFeaturesSection from './ProjectFeaturesSection';
import { ProjectHeader } from '../../components/ProjectHeader';
import ProjectProblemsSection from './ProjectProblemsSection';
import { StepStatus } from '../../layout/Steps';
import { StepsModal } from '../../layout/StepsModal';
import StreamingBenefits from '../Benefits/StreamingBenefits';
import StreamingCustomerPersonas from '../CustomerPersonas/StreamingCustomerPersonas';
import StreamingIdealCustomerProfiles from '../IdealCustomerProfiles/StreamingIdealCustomerProfiles';
import StreamingMarketingChannels from '../MarketingChannels/StreamingMarketingChannels';
import WorkCompleted from './WorkCompleted';
import { orderBy } from 'firebase/firestore';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function ProjectWizardModal({ open, setOpen }: Props) {
  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);
  const project = useAppSelector(selectProject);

  const [currentStep, setCurrentStep] = useState(0);
  const [problemCount, setProblemCount] = useState(0);
  const [featureCount, setFeatureCount] = useState(0);

  const [generatedBenefits, setGeneratedBenefits] = useState(false);
  const [generatedICPs, setGeneratedICPs] = useState(false);
  const [generatedPersonas, setGeneratedPersonas] = useState(false);
  const [generatedChannels, setGeneratedChannels] = useState(false);

  const [isWorking, setIsWorking] = useState(false);
  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);
  const [completed, setCompleted] = useState(false);

  const [streamingBenefits, setStreamingBenefits] = useState<Benefit[]>([]);
  const [streamingICPs, setStreamingICPs] = useState<IdealCustomerProfile[]>(
    []
  );
  const [streamingPersonas, setStreamingPersonas] = useState<CustomerPersona[]>(
    []
  );
  const [streamingChannels, setStreamingChannels] = useState<
    StreamingMarketingChannel[]
  >([]);

  const [features] = useCollectionData<Feature>(
    projectId
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            projectId || '',
            'features'
          ),
          orderBy('createdAt', 'asc')
        ).withConverter(featureConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [customerPersonas] = useCollectionData<CustomerPersona>(
    projectId
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            projectId || '',
            'customerPersonas'
          )
        ).withConverter(customerPersonaConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  async function createTheTopBenefits() {
    updateStatus(1, StepStatus.InProgress, setStepStatuses);

    const formattedPayload = {
      projectId,
      teamId,
      numberOfGenerations: 1,
      problems: {},
      features: [],
    };

    try {
      await streamResponse(
        '/benefits/create-benefits',
        formattedPayload,
        (item: any) => {
          return benefitFromStreamingContent({
            title: item.benefitTitle,
            description: item.benefitDescription,
            relatedFeatures: getRelatedFeatureRefs(item),
          });
        },
        setStreamingBenefits
      );
      updateStatus(1, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedBenefit,
          project
        );
      }

      setGeneratedBenefits(true);
    } catch (error: any) {
      console.error('Error creating the benefits:', error);
      alert(error.message);
    }
  }

  function getRelatedFeatureRefs(benefit: any) {
    const relatedFeatureIds = benefit.featureIds || [];

    return features
      ?.filter((eachFeature) => relatedFeatureIds.includes(eachFeature.ref.id))
      .map((eachFeature) => eachFeature.ref);
  }

  async function createTopICPs() {
    updateStatus(2, StepStatus.InProgress, setStepStatuses);
    const formattedPayload = {
      projectId,
      teamId,
      numberOfGenerations: 1,
      problems: {},
      features: [],
    };

    try {
      await streamResponse(
        '/ideal-customer-profiles/create-ideal-customer-profiles',
        formattedPayload,
        (item: any) => {
          return idealCustomerProfileFromStreamingContent(item);
        },
        setStreamingICPs
      );

      updateStatus(2, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedICP,
          project
        );
      }

      setGeneratedICPs(true);
    } catch (error: any) {
      console.error('Error creating the top ideal customer profiles:', error);
      alert(error.message);
    }
  }

  async function createTopPersonas() {
    updateStatus(3, StepStatus.InProgress, setStepStatuses);
    const formattedPayload = {
      projectId,
      teamId,
      numberOfGenerations: 1,
      problems: {},
      features: [],
    };

    try {
      await streamResponse(
        '/customer-personas/create-customer-personas',
        formattedPayload,
        (item: any) => {
          return customerPersonaFromStreamingContent(item);
        },
        setStreamingPersonas
      );

      updateStatus(3, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedPersona,
          project
        );
      }

      setGeneratedPersonas(true);
    } catch (error: any) {
      console.error('Error creating the top customer personas:', error);
      alert(error.message);
    }
  }

  async function createTopChannels() {
    updateStatus(4, StepStatus.InProgress, setStepStatuses);
    const formattedPayload = {
      projectId,
      teamId,
      numberOfGenerations: 1,
      problems: {},
      features: [],
    };

    try {
      await streamResponse(
        '/marketing-channels/create-marketing-channels',
        formattedPayload,
        (item: any) => {
          return streamingMarketingChannelFromStreamingContent(item);
        },
        setStreamingChannels
      );

      updateStatus(4, StepStatus.Done, setStepStatuses);

      if (project) {
        await updateProjectCompletedChecklistStep(
          OnboardingChecklistStepType.CreatedMarketingChannel,
          project
        );
      }

      setGeneratedChannels(true);
    } catch (error: any) {
      console.error('Error creating the top marketing channel:', error);
      alert(error.message);
    }
  }

  async function startGenerating() {
    if (!teamId || !projectId) {
      return;
    }

    if (problemCount === 0) {
      alert('Please add at least one problem.');
      return;
    }

    if (featureCount === 0) {
      alert('Please add at least one feature.');
      return;
    }

    setCurrentStep(1);
    updateStatus(0, StepStatus.Done, setStepStatuses);

    setIsWorking(true);

    await Promise.all([
      createTheTopBenefits(),
      createTopICPs(),
      createTopPersonas(),
    ]);

    try {
      await createTopChannels();
      setGeneratedChannels(true);
    } catch (error: any) {
      console.error('Error creating the top channels:', error);
    }

    updateStatus(5, StepStatus.Done, setStepStatuses);
    setCompleted(true);
    setIsWorking(false);
    setCurrentStep(5);
  }

  return (
    <StepsModal
      title="Project Wizard"
      open={open}
      setOpen={setOpen}
      steps={[
        {
          name: 'Describe Project',
          description: 'Start by describing your project.',
          status: stepStatuses[0],
          children: (
            <div className="py-8 max-w-2xl mx-auto">
              <ProjectHeader />

              <div className="mt-5">
                <ProjectProblemsSection
                  onProblemCountChange={setProblemCount}
                />
              </div>

              <div className="my-5">
                <ProjectFeaturesSection
                  onFeatureCountChange={setFeatureCount}
                />
              </div>
            </div>
          ),
          footer: (
            <MagicButton
              disabled={isWorking || completed}
              onClick={startGenerating}
            />
          ),
        },
        {
          name: 'Features -> Benefits',
          description: "Let's write the benefits.",
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingBenefits && streamingBenefits.length > 0 && (
                <StreamingBenefits
                  benefits={streamingBenefits || []}
                  features={features || []}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Ideal Customer Profiles',
          description: 'How does the company look like?',
          status: stepStatuses[2],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingICPs && streamingICPs.length > 0 && (
                <StreamingIdealCustomerProfiles
                  streamingIcps={streamingICPs || []}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Customer Personas',
          description: 'How does the person purchasing look like?',
          status: stepStatuses[3],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingPersonas && streamingPersonas.length > 0 && (
                <StreamingCustomerPersonas
                  streamingCustomerPersonas={streamingPersonas || []}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Marketing Channels',
          description: 'Generate your ideal Marketing Channels.',
          status: stepStatuses[4],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingChannels && streamingChannels.length > 0 && (
                <StreamingMarketingChannels
                  streamingMarketingChannels={streamingChannels || []}
                  relatedPersonas={customerPersonas}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[5],
          children: (
            <WorkCompleted
              title={'Your Content Is Ready!'}
              subtitle={
                'You can view the generated Benefits, ICPs, Personas, and Marketing Channels in their respective sections in the sidebar.'
              }
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={isWorking || completed}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
