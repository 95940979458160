import {
  Bars3BottomLeftIcon,
  ChartBarIcon,
  ChatBubbleBottomCenterTextIcon,
  ComputerDesktopIcon,
  FaceSmileIcon,
  FolderIcon,
  HomeIcon,
  SparklesIcon,
  StarIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Project, projectConverter } from './../models/ProjectModel';
import {
  RadialBackgroundGradient,
  RadialGradients,
} from '../components/Gradients/RadialBackgroundGradient';
import {
  SparklesIcon as SparklesIconFilled,
  UserCircleIcon,
} from '@heroicons/react/24/solid';
import { Team, teamConverter } from './../models/TeamModel';
import { User, userConverter } from './../models/UserModel';
import { auth, db } from './../components/firebase';
import { selectProjectId, setSelectedProject } from '../app/projectSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import CustomerPersonas from '../screens/CustomerPersonas/CustomerPersonas';
import Dashboard from '../screens/Dashboard/Dashboard';
import DocumentEditor from '../screens/Documents/DocumentEditor';
import Documents from '../screens/Documents/Documents';
import FeaturesToBenefits from '../screens/Benefits/Benefits';
import IdealCustomerProfiles from '../screens/IdealCustomerProfiles/IdealCustomerProfiles';
import MarketingChannels from '../screens/MarketingChannels/MarketingChannels';
import NavigationMenuItem from './NavigationMenuItem';
import OnboardingChecklistModal from '../components/Onboarding/OnboardingChecklistModal';
import Pricing from '../screens/Settings/Plan/Pricing';
import ProjectDetails from '../screens/ProjectDetails/ProjectDetails';
import { ProjectSelectionDropdown } from './ProjectSelectionDropdown';
import { RightSidebar } from './RightSidebar';
import Settings from '../screens/Settings/Settings';
import TextGeneration from '../screens/Documents/DocumentEditor';
import TwitterTweets from '../screens/Twitter/Tweets/TwitterTweets';
import UpgradePlanCard from '../components/UpgradePlanCard';
import WebsiteContent from '../screens/WebsiteContent/WebsiteContent';
import WebsitePreview from '../screens/WebsiteContent/WebsitePreview';
import { doc } from 'firebase/firestore';
import { logOut } from '../components/firebase';
import { setSelectedTeam } from '../app/teamSlice';
import { setUser } from '../app/userSlice';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const navigationItems = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },

  {
    name: 'Project Details',
    href: '/project-details',
    icon: FolderIcon,
  },
  {
    name: 'Benefits',
    href: '/benefits',
    icon: StarIcon,
  },
  {
    name: 'Ideal Customer Profiles',
    href: '/ideal-customer-profiles',
    icon: UsersIcon,
  },
  {
    name: 'Customer Personas',
    href: '/personas',
    icon: FaceSmileIcon,
  },
  {
    name: 'Marketing Channels',
    href: '/marketing-channels',
    icon: ChartBarIcon,
  },
  // {
  //   name: 'Twitter Tweets',
  //   href: '/twitter-tweets',
  //   icon: ChatBubbleBottomCenterTextIcon,
  // },
  {
    name: 'Website Content',
    href: '/website-content',
    icon: ComputerDesktopIcon,
  },
  {
    name: 'Documents',
    href: '/documents',
    icon: ChatBubbleBottomCenterTextIcon,
  },
];
const profileNavigationItems = [
  { name: 'Your Profile', href: '/settings/profile' },
  { name: 'Your Team', href: '/settings/team' },
  { name: 'Billing', href: '/settings/billing' },
  { name: 'Sign out', href: '/logout' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function MainLayout() {
  const [user] = useAuthState(auth);
  const [sideNavigationOpen, setSideNavigationOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarView, setSidebarView] = useState<any>(undefined);
  const [headerTitle, setHeaderTitle] = useState('Hello');
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  let location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  // const selectedTeamId = useAppSelector(selectTeamId);
  const selectedProjectId = useAppSelector(selectProjectId);

  // const [projects, projectsLoading, projectsError, projectsSnapshot] =
  //   useCollectionData<Project>(
  //     query(
  //       collection(db, 'projects'),
  //       where('owner', '==', doc(db, 'users', user?.uid || ''))
  //     ).withConverter(projectConverter),
  //     {
  //       snapshotListenOptions: { includeMetadataChanges: true },
  //     }
  //   );

  const [userProfile, userProfileLoading] = useDocumentData<User | undefined>(
    user?.uid
      ? doc(db, 'users', user.uid || '').withConverter(userConverter)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [selectedTeam, teamLoading] = useDocumentData<Team | undefined>(
    userProfile?.currentTeam
      ? doc(db, 'teams', userProfile?.currentTeam.id || '').withConverter(
          teamConverter
        )
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [selectedProject, projectLoading] = useDocumentData<
    Project | undefined
  >(
    selectedProjectId && selectedTeam
      ? doc(
          db,
          'teams',
          selectedTeam?.id || '',
          'projects',
          selectedProjectId || ''
        ).withConverter(projectConverter)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    let selectedIndex = getSelectedSidebarMenuIndex();
    setSelectedMenuIndex(selectedIndex);
    if (selectedIndex >= 0) {
      setPageTitle(navigationItems[selectedIndex]?.name || '');
    } else {
      selectedIndex = getSelectedProfileMenuIndex();
      if (selectedIndex >= 0) {
        // setPageTitle(profileNavigationItems[selectedIndex]?.name || '');
        setPageTitle('Settings');
      }
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (location.pathname.startsWith('/text-editor') && !sidebarOpen) {
  //     setSidebarOpen(true);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (userProfile && !userProfileLoading) {
      dispatch(setUser(userProfile));
    }
  }, [userProfile, userProfileLoading]);

  useEffect(() => {
    if (selectedTeam && !teamLoading) {
      dispatch(setSelectedTeam(selectedTeam));
    }
  }, [selectedTeam, teamLoading]);

  useEffect(() => {
    if (selectedProject && !projectLoading) {
      dispatch(setSelectedProject(selectedProject));
    }
  }, [selectedProject, projectLoading]);

  // useEffect(() => {
  //   if (sidebarView && !sidebarOpen) {
  //     setSidebarOpen(true);
  //   }
  // }, [sidebarView, sidebarOpen]);

  function getSelectedSidebarMenuIndex() {
    const path = location.pathname;
    return navigationItems.findIndex((item) => path.startsWith(item.href));
  }

  function getSelectedProfileMenuIndex() {
    const path = location.pathname;
    return profileNavigationItems.findIndex((item) =>
      path.startsWith(item.href)
    );
  }

  function setPageTitle(title: string) {
    setHeaderTitle(title);
    document.title = 'Marketology.AI | ' + title;
  }

  function sidebarMenuOnClick(href: string, menuTitle: string) {}

  async function userMenuOnClick(href: string, menuTitle: string) {
    if (href === '/logout') {
      await logOut();
    } else {
      navigate(href);
    }
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div>
        {/* Mobile sidebar */}
        <Transition.Root show={sideNavigationOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSideNavigationOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSideNavigationOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    {/* <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt="Marketology.ai Logo"
                    /> */}
                    <h1 className="text-2xl font-serif text-slate-500">
                      Marketology.ai
                    </h1>
                  </div>

                  <div className="mt-5">
                    <ProjectSelectionDropdown />
                  </div>

                  <div className="mt-5 flex flex-grow overflow-y-auto">
                    <nav className="flex-1 space-y-1 px-2">
                      {navigationItems.map((item, index) => (
                        <NavigationMenuItem
                          key={item.href}
                          item={{
                            ...item,
                            selected: selectedMenuIndex === index,
                          }}
                          onClick={() =>
                            sidebarMenuOnClick(item.href, item.name)
                          }
                        />
                      ))}
                    </nav>
                  </div>

                  {/* <div className="flex flex-column grow"></div> */}

                  <div className="px-4">
                    <UpgradePlanCard />
                  </div>

                  <div className="flex flex-column shrink p-4 text-gray-700 text-sm">
                    <p>
                      Questions or feedback?{' '}
                      <a
                        className="underline"
                        href="mailto:hello@marketology.ai"
                      >
                        hello@marketology.ai
                      </a>
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-60 lg:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              /> */}
              <h1 className="text-2xl font-serif text-slate-500">
                Marketology.ai
              </h1>
            </div>

            <div className="mt-5">
              <ProjectSelectionDropdown />
            </div>

            <div className="mt-5 flex flex-grow flex-col">
              <nav className="flex-1 space-y-1 px-2 pb-4">
                {navigationItems.map((item, index) => (
                  <NavigationMenuItem
                    key={item.href}
                    item={{ ...item, selected: selectedMenuIndex === index }}
                    onClick={() => sidebarMenuOnClick(item.href, item.name)}
                  />
                ))}
              </nav>
            </div>

            {/* <div className="flex flex-column grow"></div> */}

            <div className="px-4">
              <UpgradePlanCard />
            </div>

            <div className="flex flex-col shrink p-4 text-gray-700 text-sm">
              <p>
                Questions or feedback?{' '}
                <a className="underline" href="mailto:hello@marketology.ai">
                  hello@marketology.ai
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col lg:pl-60">
          {/* Background Gradients */}
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: -1,
              backgroundColor: '#F9FAFD',
            }}
          >
            <RadialBackgroundGradient
              gradient={RadialGradients.Purple}
              left="-100%"
              top="-80%"
              width="200%"
              height="200%"
            />
            <RadialBackgroundGradient
              gradient={RadialGradients.Cyan}
              right="-100%"
              top="-80%"
              width="200%"
              height="200%"
            />
            <RadialBackgroundGradient
              gradient={RadialGradients.Orange}
              bottom="-100%"
              width="200%"
              height="200%"
              left="-100%"
              right="-100%"
              margin="auto"
            />
          </div>

          {/* Header */}
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
              onClick={() => setSideNavigationOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex flex-1 justify-between px-4">
              <div className="flex">
                <div className="my-auto max-w-7xl">
                  <h1 className="text-2xl font-semibold text-slate-600">
                    {headerTitle}
                  </h1>
                </div>
              </div>
              <div className="ml-4 flex flex-row gap-4 items-center">
                <OnboardingChecklistModal />

                <a
                  className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm leading-4 hover:bg-indigo-700"
                  href="https://cal.com/raduoprea/30min"
                  target="_blank"
                  rel="noreferrer"
                >
                  Book a Demo
                </a>

                {selectedProject && (
                  <button
                    type="button"
                    className="rounded-full bg-white p-1 text-purple-500 hover:text-purple-700"
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                  >
                    <span className="sr-only">View AI Assistant</span>

                    {!sidebarOpen && (
                      <SparklesIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                    {sidebarOpen && (
                      <SparklesIconFilled
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                )}

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm">
                      <span className="sr-only">Open user menu</span>
                      {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                      <UserCircleIcon className="h-8 w-8 text-gray-400 hover:text-gray-500" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            {auth.currentUser?.email}
                          </p>
                        )}
                      </Menu.Item>
                      {profileNavigationItems.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <div
                              // href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                              onClick={async () => {
                                await userMenuOnClick(item.href, item.name);
                              }}
                            >
                              {item.name}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          {/* Content */}

          <div className="flex flex-row min-h-full h-full overflow-hidden">
            <main className="flex-grow h-[calc(100vh_-_64px)] overflow-y-scroll">
              {/* <main className="flex-grow h-[calc(100vh_-_64px)]"> */}
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />

                <Route path="/project-details" element={<ProjectDetails />} />

                <Route path="/benefits" element={<FeaturesToBenefits />} />

                <Route
                  path="/ideal-customer-profiles"
                  element={<IdealCustomerProfiles />}
                />

                <Route
                  path="/personas"
                  element={<CustomerPersonas showNewItemButton={true} />}
                />

                <Route
                  path="/marketing-channels"
                  element={<MarketingChannels />}
                />

                <Route path="/twitter-tweets" element={<TwitterTweets />} />

                <Route path="/documents" element={<Documents />} />

                <Route
                  path="/documents/:id"
                  element={
                    <DocumentEditor
                      setSidebarView={setSidebarView}
                      setSidebarOpen={setSidebarOpen}
                    />
                  }
                />

                <Route path="/website-content" element={<WebsiteContent />} />
                <Route
                  path="/website-content/:id"
                  element={<WebsitePreview showBackButton />}
                />

                <Route path="/settings/*" element={<Settings />} />

                <Route path="/pricing" element={<Pricing />} />

                <Route
                  path="*"
                  element={<Navigate replace to="/project-details" />}
                />
              </Routes>
            </main>
            {
              <RightSidebar
                sidebarOpen={sidebarOpen}
                sidebarView={sidebarView}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
}
