import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { RangeSlider } from '../RangeSlider';
import React from 'react';
import { TextChip } from '../TextChip';
import { titleCase } from '../../utils/utils';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  title: string;
  psychographics: string[];
  traits: { [key: string]: number };
  docRef: DocumentReference;
  updatePath: string;
  isEditing: boolean;
}

export default function PersonalityCard({
  title,
  psychographics,
  traits,
  docRef,
  updatePath,
  isEditing,
}: Props) {
  function getTraitComponents(
    trait: string,
    value: number
  ): {
    firstTrait: string;
    lastTrait: string;
    value: number;
    updatePath: string;
  } {
    let traitComponents = {
      firstTrait: '',
      lastTrait: '',
      value: 3,
      updatePath: '',
    };
    const axes = trait.slice(0, -6).split('Or');
    traitComponents = {
      firstTrait: titleCase(axes[0]),
      lastTrait: titleCase(axes[1]),
      value: value,
      updatePath: trait,
    };
    return traitComponents;
  }

  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      // console.log('updatedProperties', updatedProperties);
      await updateDocument(docRef, updatedProperties);
    },
    300
  );

  async function addPsychographic() {
    let properties: any = {};
    properties['description.psychographics.personality'] = [
      ...psychographics,
      '',
    ];
    await updateDocument(docRef, properties);
  }

  async function deletePsychographic(index: number) {
    let properties: any = {};
    properties['description.psychographics.personality'] =
      psychographics.filter((item, i) => i !== index);
    await updateDocument(docRef, properties);
  }

  return (
    <Card shadow={false}>
      <div className="flex flex-col justify-start align-top">
        <h3 className="mr-auto text-xl font-bold text-left text-gray-700">
          {title}
        </h3>

        <div
          key={`${psychographics.length}`}
          className="flex flex-row flex-wrap gap-2 justify-start align-top mt-2 text-left"
        >
          {psychographics &&
            psychographics.length > 0 &&
            psychographics.map((eachItem: string, index: number) => (
              <TextChip
                key={index}
                title={eachItem}
                index={index}
                isEditing={isEditing}
                onValueChange={(updatedValue) => {
                  const updatedItems = psychographics.map((item) =>
                    item === eachItem ? updatedValue : item
                  );
                  let properties: any = {};
                  properties['description.psychographics.personality'] =
                    updatedItems;
                  debouncedPropertiesUpdate(properties);
                }}
                onRemove={async (index: number) => {
                  await deletePsychographic(index);
                }}
              />
            ))}

          {isEditing && (
            <button
              type="button"
              className={`inline-flex items-center rounded-md border border-transparent bg-transparent px-0 py-0 my-0 text-sm font-medium leading-4 text-gray-700 hover:shadow-sm hover:bg-gray-50 active:bg-gray-100`}
              onClick={async () => {
                await addPsychographic();
              }}
            >
              <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          )}
        </div>

        <div className="flex flex-col gap-2 mt-1">
          {traits &&
            Object.entries(traits)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([trait, value]) => {
                const components = getTraitComponents(trait, value);
                return (
                  <RangeSlider
                    key={trait}
                    leftLabel={components.firstTrait}
                    rightLabel={components.lastTrait}
                    min={0}
                    max={5}
                    step={1}
                    defaultValue={value}
                    isEditing={isEditing}
                    valueChanged={function (value: number): void {
                      let properties: any = {};
                      properties[`${updatePath}.${components.updatePath}`] =
                        value;
                      debouncedPropertiesUpdate(properties);
                    }}
                  />
                );
              })}
        </div>
      </div>
    </Card>
  );
}
