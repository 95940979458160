import React, { useState } from 'react';

import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { TwitterTweetType } from '../../../models/TwitterTweetModel';

interface Props {
  tweetType: TwitterTweetType;
  onClick?: (tweetType: TwitterTweetType) => void;
}

export default function TwitterTweetTypeCard({ tweetType, onClick }: Props) {
  function getTitle() {
    switch (tweetType) {
      case TwitterTweetType.Generic:
        return 'Generic Tweet';
      case TwitterTweetType.UnpopularOpinion:
        return 'Unpopular Opinion';
      case TwitterTweetType.Thinking:
        return "I've Been Thinking";
      case TwitterTweetType.HotTake:
        return 'Hot Take';
      case TwitterTweetType.AskMeAnything:
        return 'Ask Me Anything';
      case TwitterTweetType.ThisOrThat:
        return 'This or That';
      case TwitterTweetType.Top3:
        return 'Top 3';
      case TwitterTweetType.Confession:
        return 'Confession';
    }
  }

  function getDescription() {
    switch (tweetType) {
      case TwitterTweetType.Generic:
        return 'A generic tweet for anything, really.';
      case TwitterTweetType.UnpopularOpinion:
        return 'A tweet that expresses an unpopular opinion.';
      case TwitterTweetType.Thinking:
        return 'A tweet that expresses a thought or idea.';
      case TwitterTweetType.HotTake:
        return 'A tweet that expresses a hot take.';
      case TwitterTweetType.AskMeAnything:
        return 'A tweet that asks your audience to ask you anything.';
      case TwitterTweetType.ThisOrThat:
        return 'A tweet that asks your audience to choose between two options.';
      case TwitterTweetType.Top3:
        return 'A tweet where you list your top 3.';
      case TwitterTweetType.Confession:
        return 'A tweet where you confess something.';
    }
  }

  return (
    <li
      className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center cursor-pointer border border-slate-200`}
      onClick={() => {
        if (onClick) {
          onClick(tweetType);
        }
      }}
    >
      <div className="flex flex-1 flex-col p-8">
        <div className="mx-auto h-24 w-24 flex-shrink-0 rounded-full overflow-clip">
          <div className="h-24 w-24 bg-slate-200 rounded-full flex items-center justify-center">
            <ChatBubbleBottomCenterTextIcon className="h-12 w-12 text-white " />
          </div>
        </div>
        <h3 className="mt-6 text-2xl font-medium text-gray-900">
          {getTitle()}
        </h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dd className="text-sm text-gray-500 max-h-60 text-ellipsis overflow-hidden">
            {getDescription()}
          </dd>
        </dl>
      </div>
    </li>
  );
}
