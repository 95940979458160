import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import { ImageDetailsChip } from '../ImageDetailsChip';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  title: string;
  type: string;
  keyMetrics: string[];
  confidenceRelevanceScore: number;
  channelRef: DocumentReference;
  channelPersonaLinkRef?: DocumentReference;
  isEditing: boolean;
}

export default function MarketingChannelOverviewCard({
  title,
  type,
  confidenceRelevanceScore,
  channelRef,
  channelPersonaLinkRef,
  isEditing,
}: Props) {
  const debouncedChannelPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateDocument(channelRef, updatedProperties);
    },
    300
  );

  const debouncedChannelPersonaLinkPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      if (!channelPersonaLinkRef) {
        return;
      }
      await updateDocument(channelPersonaLinkRef, updatedProperties);
    },
    300
  );

  return (
    <Card shadow={false}>
      <div className="flex flex-col justify-center py-4">
        {/* Title */}
        <div className="flex flex-col text-center">
          {/* Title */}
          <div className="mx-auto">
            <EditableTextField
              text={title}
              textComponentType="h2"
              styleClasses={
                'text-3xl font-bold text-center text-blue-600 hover:bg-slate-100'
              }
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['title'] = updatedValue;
                debouncedChannelPropertiesUpdate(properties);
              }}
            />
          </div>

          {/* Type */}
          <div className="mt-4 mx-auto">
            <EditableTextField
              text={type}
              textComponentType="h4"
              styleClasses={
                'text-sm font-bold uppercase text-blue-600 text-center hover:bg-slate-100'
              }
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['type'] = updatedValue;
                debouncedChannelPropertiesUpdate(properties);
              }}
            />
          </div>
        </div>

        {/* Confidence/Relevance Score */}
        <div className="flex flex-row flex-wrap justify-center gap-4 mt-4">
          <ImageDetailsChip
            emoji="⚖️ Relevance:"
            text={
              confidenceRelevanceScore !== -1
                ? (confidenceRelevanceScore * 100).toFixed(0).toString() + '%'
                : 'N/A'
            }
            isEditing={isEditing}
            onValueChange={(updatedValue) => {
              let properties: any = {};
              properties['confidenceRelevanceScore'] = updatedValue;
              debouncedChannelPersonaLinkPropertiesUpdate(properties);
            }}
          />
        </div>
      </div>
    </Card>
  );
}
