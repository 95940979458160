import {
  CustomerPersona,
  customerPersonaConverter,
} from '../../models/CustomerPersonaModel';
import React, { useState } from 'react';
import {
  collection,
  db,
  query,
  useCollectionData,
} from '../../components/firebase';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { CreateNewCustomerPersonaModal } from './CreateNewCustomerPersonaModal';
import { CustomerPersonaCard } from './CustomerPersonaCard';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
  showNewItemButton?: boolean;
  onClick?: (customerPersona: CustomerPersona) => void;
}
export default function CustomerPersonas({
  showNewItemButton = false,
  onClick,
}: Props) {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);

  const [customerPersonas, customerPersonasLoading] =
    useCollectionData<CustomerPersona>(
      project &&
        query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            project?.id || '',
            'customerPersonas'
          ),
          orderBy('createdAt', 'desc')
        ).withConverter(customerPersonaConverter),
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );

  return (
    <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 space-y-4">
      {project && (
        <>
          <ul className="grid grid-cols-1 gap-6">
            {showNewItemButton && (
              <li className="col-span-1 flex flex-col min-h-[5em] text-center bg-white rounded-lg ">
                <AddNewItemCard
                  onClick={async () => {
                    setNewItemModalOpen(true);
                  }}
                />
              </li>
            )}

            {customerPersonas &&
              customerPersonas.map((eachIcp) => (
                <CustomerPersonaCard
                  key={eachIcp.id}
                  customerPersona={eachIcp}
                  onClick={onClick}
                />
              ))}
          </ul>

          <CreateNewCustomerPersonaModal
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
          />
        </>
      )}
    </div>
  );
}
