import {
  MarketingChannel,
  marketingChannelConverter,
} from '../../models/MarketingChannelModel';
import React, { useEffect, useState } from 'react';
import {
  WebsitePage,
  websitePageConverter,
} from '../../models/WebsitePageModel';
import {
  collection,
  db,
  query,
  useCollectionData,
} from '../../components/firebase';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { CreateNewLandingPageModal } from './CreateNewLandingPageModal';
import GradientsLandingPage from './Templates/GradientsLandingPage';
import { WebsitePageCard } from './WebsitePageCard';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

export default function WebsiteContent() {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const navigate = useNavigate();

  const [websitePages, websitePagesLoading] = useCollectionData<WebsitePage>(
    project &&
      query(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'websitePages'
        ),
        orderBy('createdAt', 'desc')
      ).withConverter(websitePageConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 space-y-4">
      {project && (
        <div>
          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 min-h-[200px]">
            <li className="col-span-1 flex flex-col min-h-[5em] text-center bg-white rounded-lg ">
              <AddNewItemCard
                onClick={async () => {
                  setNewItemModalOpen(true);
                }}
              />
            </li>

            {websitePages &&
              websitePages.map((eachWebsitePage) => (
                <WebsitePageCard
                  key={eachWebsitePage.id}
                  websitePage={eachWebsitePage}
                  onClick={() => {
                    navigate('/website-content/' + eachWebsitePage.id);
                  }}
                />
              ))}
          </ul>

          <CreateNewLandingPageModal
            project={project}
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
          />
        </div>
      )}
    </div>
  );
}
