import React, { useState } from 'react';

import { EditableTextField } from '../EditableTextField';

interface Props {
  htmlId?: string;
  htmlName?: string;
  title: string;
  description?: string;
  text?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export default function FormMultiLineTextInput({
  htmlId,
  htmlName,
  title,
  description,
  text = '',
  placeholder,
  onChange,
}: Props) {
  return (
    <div>
      <label className="block text-md font-medium text-gray-700">
        {title} <span className="text-purple-500">*</span>
      </label>
      <div className="mt-2">
        <EditableTextField
          htmlId={htmlId}
          htmlName={htmlName}
          text={text || ''}
          placeholder={placeholder}
          textComponentType="p"
          styleClasses={
            'block p-3 min-w-full w-full min-h-[100px] rounded-md border border-gray-300 hover:bg-white focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          }
          isEditing={true}
          onValueChange={(updatedValue) => {
            // setLocalText(e.target.value);
            onChange(updatedValue);
          }}
        />
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
}
