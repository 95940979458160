import {
  MarketingChannel,
  marketingChannelConverter,
} from '../../models/MarketingChannelModel';
import React, { useEffect, useState } from 'react';
import {
  collection,
  db,
  query,
  useCollectionData,
} from '../../components/firebase';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { CreateNewMarketingChannelModal } from './CreateNewMarketingChannelModal';
import { MarketingChannelCard } from './MarketingChannelCard';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

export default function MarketingChannels() {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);

  const [marketingChannels, marketingChannelsLoading] =
    useCollectionData<MarketingChannel>(
      project &&
        query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            project?.id || '',
            'marketingChannels'
          ),
          orderBy('createdAt', 'desc')
        ).withConverter(marketingChannelConverter),
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );

  return (
    <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 space-y-4">
      {project && (
        <div>
          <ul className="grid grid-cols-1 gap-6">
            <li className="col-span-1 flex flex-col min-h-[5em] text-center bg-white rounded-lg ">
              <AddNewItemCard
                onClick={async () => {
                  setNewItemModalOpen(true);
                }}
              />
            </li>

            {marketingChannels &&
              marketingChannels.map((eachMarketingChannel) => (
                <MarketingChannelCard
                  key={eachMarketingChannel.id}
                  marketingChannel={eachMarketingChannel}
                  onClick={() => {}}
                />
              ))}
          </ul>

          {/* <StepsModal
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
            steps={steps}
          /> */}

          <CreateNewMarketingChannelModal
            project={project}
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
          />
        </div>
      )}
    </div>
  );
}
