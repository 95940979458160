import React, { ChangeEventHandler, useState } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  leftLabel: string;
  rightLabel: string;
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  isEditing: boolean;
  valueChanged: (value: number) => void;
}

export function RangeSlider({
  leftLabel,
  rightLabel,
  min,
  max,
  step,
  defaultValue,
  isEditing,
  valueChanged,
}: Props) {
  const [sliderValue, setSliderValue] = useState(defaultValue);
  const thumbPosition = (((sliderValue - min) / (max - min)) * 100).toFixed(2);

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-row justify-between text-xs font-medium text-gray-500">
        <label htmlFor="introvert-extrovert" className="block mb-2">
          {leftLabel}
        </label>

        <label htmlFor="introvert-extrovert" className="block mb-2">
          {rightLabel}
        </label>
      </div>
      <div className="relative w-full">
        <div
          className={`w-full h-1 bg-gray-200 rounded-lg cursor-pointer ${
            isEditing ? '' : 'pointer-events-none'
          }`}
        ></div>
        <div className="relative mx-2">
          <div
            className={twMerge(
              `absolute w-4 h-4 bg-blue-600 rounded-full cursor-pointer`,
              isEditing ? '' : 'pointer-events-none'
            )}
            style={{
              top: 'calc(-0.5rem - 2px)',
              left: `calc(${thumbPosition}% - 0.5rem)`,
            }}
          ></div>
        </div>
        <input
          id="introvert-extrovert"
          type="range"
          min={min}
          max={max}
          step={step}
          value={sliderValue}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value);
            setSliderValue(newValue);
            valueChanged(newValue);
          }}
          className={`absolute w-full h-0 opacity-0 cursor-pointer${
            isEditing ? '' : ' pointer-events-none'
          }`}
        />
      </div>
    </div>
  );
}
