import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// Solution
//

type Solution = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  title?: string;
  description?: string;
  createdAt: Date;
};

const solutionConverter: FirestoreDataConverter<Solution> = {
  toFirestore(solution: WithFieldValue<Solution>): DocumentData {
    return {
      owner: solution.owner,
      title: solution.title,
      description: solution.description,
      createdAt: solution.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Solution {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      owner: data.owner,
      title: data.title,
      description: data.description,
      createdAt: data.createdAt,
    };
  },
};

function solutionFromFirestore(document: any): Solution {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    title: document.title,
    description: document.description,
    createdAt: document.createdAt,
  };
}

export type { Solution };
export { solutionConverter, solutionFromFirestore };
