import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// IdealCustomerProfile
//

type IdealCustomerProfile = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  title?: string;
  description?: string;
  industry: string;
  companySize: string;
  estimatedRevenue: string;
  numberOfEmployees: string;
  regionOrGeographicalLocation: string;
  techStack: string[];
  numberOfBuildingsOrBranches: string;
  numberOfPartners: string;
  companyPainPoints: string[];
  companyGoals: string[];
  companyBuyingProcess: string;
  createdAt: Date;
};

const idealCustomerProfileConverter: FirestoreDataConverter<IdealCustomerProfile> =
  {
    toFirestore(
      idealCustomerProfile: WithFieldValue<IdealCustomerProfile>
    ): DocumentData {
      return {
        owner: idealCustomerProfile.owner,
        title: idealCustomerProfile.title,
        description: idealCustomerProfile.description,
        industry: idealCustomerProfile.industry,
        companySize: idealCustomerProfile.companySize,
        estimatedRevenue: idealCustomerProfile.estimatedRevenue,
        numberOfEmployees: idealCustomerProfile.numberOfEmployees,
        regionOrGeographicalLocation:
          idealCustomerProfile.regionOrGeographicalLocation,
        techStack: idealCustomerProfile.techStack,
        numberOfBuildingsOrBranches:
          idealCustomerProfile.numberOfBuildingsOrBranches,
        numberOfPartners: idealCustomerProfile.numberOfPartners,
        companyPainPoints: idealCustomerProfile.companyPainPoints,
        companyGoals: idealCustomerProfile.companyGoals,
        companyBuyingProcess: idealCustomerProfile.companyBuyingProcess,
        createdAt: idealCustomerProfile.createdAt,
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): IdealCustomerProfile {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        ref: snapshot.ref,
        owner: data.owner,
        title: data.title,
        description: data.description,
        industry: data.industry,
        companySize: data.companySize,
        estimatedRevenue: data.estimatedRevenue,
        numberOfEmployees: data.numberOfEmployees,
        regionOrGeographicalLocation: data.regionOrGeographicalLocation,
        techStack: data.techStack,
        numberOfBuildingsOrBranches: data.numberOfBuildingsOrBranches,
        numberOfPartners: data.numberOfPartners,
        companyPainPoints: data.companyPainPoints,
        companyGoals: data.companyGoals,
        companyBuyingProcess: data.companyBuyingProcess,
        createdAt: data.createdAt,
      };
    },
  };

function idealCustomerProfileFromFirestore(
  document: any
): IdealCustomerProfile {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    title: document.title,
    description: document.description,
    industry: document.industry,
    companySize: document.companySize,
    estimatedRevenue: document.estimatedRevenue,
    numberOfEmployees: document.numberOfEmployees,
    regionOrGeographicalLocation: document.regionOrGeographicalLocation,
    techStack: document.techStack,
    numberOfBuildingsOrBranches: document.numberOfBuildingsOrBranches,
    numberOfPartners: document.numberOfPartners,
    companyPainPoints: document.companyPainPoints,
    companyGoals: document.companyGoals,
    companyBuyingProcess: document.companyBuyingProcess,
    createdAt: document.createdAt,
  };
}

function idealCustomerProfileFromStreamingContent(
  partialDocument: any
): IdealCustomerProfile {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    owner: partialDocument.owner || '',
    title: partialDocument.title || 'Writing in progress...',
    description: partialDocument.description || 'Description coming up...',
    industry: partialDocument.industry || 'Writing in progress...',
    companySize: partialDocument.companySize || '',
    estimatedRevenue: partialDocument.estimatedRevenue || '',
    numberOfEmployees: partialDocument.numberOfEmployees || '',
    regionOrGeographicalLocation:
      partialDocument.regionOrGeographicalLocation || '',
    techStack: partialDocument.techStack || [],
    numberOfBuildingsOrBranches:
      partialDocument.numberOfBuildingsOrBranches || '',
    numberOfPartners: partialDocument.numberOfPartners || '',
    companyPainPoints: partialDocument.companyPainPoints || [],
    companyGoals: partialDocument.companyGoals || [],
    companyBuyingProcess: partialDocument.companyBuyingProcess || '',
    createdAt: partialDocument.createdAt || new Date(),
  };
}

export type { IdealCustomerProfile };
export {
  idealCustomerProfileConverter,
  idealCustomerProfileFromFirestore,
  idealCustomerProfileFromStreamingContent,
};
