import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// Benefit
//

type Benefit = {
  id: string;
  ref: DocumentReference<DocumentData>;
  title: string;
  description: string;
  relatedFeatures: DocumentReference<DocumentData>[];
  createdAt: Date;
};

const benefitConverter: FirestoreDataConverter<Benefit> = {
  toFirestore(benefit: WithFieldValue<Benefit>): DocumentData {
    return {
      title: benefit.title,
      description: benefit.description,
      relatedFeatures: benefit.relatedFeatures,
      createdAt: benefit.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Benefit {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      title: data.title,
      description: data.description,
      relatedFeatures: data.relatedFeatures,
      createdAt: data.createdAt,
    };
  },
};

function benefitFromFirestore(document: any): Benefit {
  return {
    id: document.id,
    ref: document.ref,
    title: document.title,
    description: document.description,
    relatedFeatures: document.relatedFeatures,
    createdAt: document.createdAt,
  };
}

function benefitFromStreamingContent(partialDocument: any): Benefit {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    title: partialDocument.title || 'Writing in progress...',
    description: partialDocument.description || 'Description coming up...',
    relatedFeatures: partialDocument.relatedFeatures || [],
    createdAt: partialDocument.createdAt || new Date(),
  };
}

export type { Benefit };
export { benefitConverter, benefitFromFirestore, benefitFromStreamingContent };
