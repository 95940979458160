import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { db, doc } from '../components/firebase';

import { Project } from '../models/ProjectModel';
import { RootState } from './store';
import dayjs from 'dayjs';

export interface ProjectState {
  currentlySelectedProject: Project | undefined;
}

export const initialProjectState: ProjectState = {
  currentlySelectedProject: undefined,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState: initialProjectState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<Project | undefined>) => {
      state.currentlySelectedProject = action.payload;
    },
  },
});

export const { setSelectedProject } = projectSlice.actions;

export const selectProject = (state: RootState) => {
  const storedProject: any = state.project.currentlySelectedProject;

  // console.log('returning storedProject: ', storedProject);

  if (!storedProject) {
    return undefined;
  }

  return {
    id: storedProject.id,
    ref: doc(db, storedProject.ref),
    owner: storedProject.owner,
    title: storedProject.title,
    description: storedProject.description,
    websiteUrl: storedProject.websiteUrl,
    imageUrl: storedProject.imageUrl,
    businessType: storedProject.businessType,
    toneOfVoice: storedProject.toneOfVoice,
    onboardingChecklist: storedProject.onboardingChecklist,
    createdAt: dayjs(storedProject.createdAt).toDate(),
  } as Project;
};

export const selectProjectId = (state: RootState) =>
  state.project.currentlySelectedProject?.id;

export const selectProjectRef = (state: RootState) =>
  state.project.currentlySelectedProject?.ref;

export default projectSlice.reducer;
