import { Benefit, benefitConverter } from '../../models/BenefitModel';
import { Feature, featureConverter } from '../../models/FeatureModel';
import React, { useState } from 'react';
import {
  collection,
  db,
  query,
  useCollectionData,
} from '../../components/firebase';

import { AddNewItemCard } from '../../components/AddNewItemCard';
import { BenefitCard } from './BenefitCard';
import { CreateNewBenefitModal } from './CreateNewBenefitModal';
import { orderBy } from 'firebase/firestore';
import { selectProjectId } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { selectUserId } from '../../app/userSlice';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

export default function Benefits() {
  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const navigate = useNavigate();

  const [benefits, benefitsLoading] = useCollectionData<Benefit>(
    projectId
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            projectId || '',
            'benefits'
          ),
          orderBy('createdAt', 'desc')
        ).withConverter(benefitConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [features, featuresLoading] = useCollectionData<Feature>(
    projectId
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            projectId || '',
            'features'
          ),
          orderBy('createdAt', 'asc')
        ).withConverter(featureConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  function getRelatedFeatures(benefit: Benefit) {
    const relatedFeatureIds = benefit.relatedFeatures.map((eachRef) => {
      return eachRef.id;
    });

    return features?.filter((eachFeature) =>
      relatedFeatureIds.includes(eachFeature.ref.id)
    );
  }

  return (
    <div className="flex flex-col max-w-4xl mx-auto py-6 px-4 space-y-4">
      {/* {!benefitsLoading &&
        !featuresLoading &&
        (!projectId || !benefits || benefits?.length === 0) && (
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No benefits had been generated yet.
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Start by running the Project Wizard in the Project Details.
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => navigate('/project-details')}
              >
                Open Project Details
              </button>
            </div>
          </div>
        )} */}

      {projectId && (
        <div>
          <ul className="grid grid-cols-1 gap-6">
            <li className="col-span-1 flex flex-col min-h-[5em] text-center bg-white rounded-lg ">
              <AddNewItemCard
                onClick={async () => {
                  setNewItemModalOpen(true);
                }}
              />
            </li>

            {benefits &&
              benefits.map((eachBenefit) => (
                <BenefitCard
                  key={eachBenefit.id}
                  benefit={eachBenefit}
                  realatedFeatures={getRelatedFeatures(eachBenefit) || []}
                  onClick={() => {}}
                />
              ))}
          </ul>

          <CreateNewBenefitModal
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
          />
        </div>
      )}
    </div>
  );
}
