import React from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  selected: boolean;
  onClick: () => void;
}

const DEFAULT_IMAGE_URL =
  'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60';

export function ChatSessionCard({ title, subtitle, selected, onClick }: Props) {
  return (
    <li
      key={title}
      className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow cursor-pointer ${
        selected ? 'ring-2 ring-purple-500' : ''
      }`}
      onClick={onClick}
    >
      <div className="flex flex-1 flex-col p-8">
        <h3 className="text-2xl font-medium text-gray-900">Chat Session</h3>
        <h3 className="mt-6 text-xl font-medium text-gray-500">{subtitle}</h3>
      </div>
    </li>
  );
}
