import React from 'react';

interface Props {
  title: string;
  subtitle: string;
}

export default function WorkCompleted({ title, subtitle }: Props) {
  return (
    <div className="h-full py-4 flex items-center justify-center">
      <div className="text-center text-gray-700">
        <p className="text-6xl">🎉</p>
        <h1 className="mt-4 text-xl font-medium">{title}</h1>
        <p className="mt-2">{subtitle}</p>
      </div>
    </div>
  );
}
