export enum OnboardingChecklistStepType {
  CompletedProfile = 'completedProfile',
  CreatedProject = 'createdProject',
  CompletedProjectDescription = 'completedProjectDescription',
  CreatedBenefit = 'createdBenefit',
  CreatedICP = 'createdICP',
  CreatedPersona = 'createdPersona',
  CreatedMarketingChannel = 'createdMarketingChannel',
  CreatedDocument = 'createdDocument',
  UsedAIDocumentEditor = 'usedAIDocumentEditor',
  UsedAIAssistant = 'usedAIAssistant',
}

export type OnboardingChecklistSteps = {
  [key: string]: boolean;
};

export const activeUserChecklistSteps = [
  // OnboardingChecklistStepType.CompleteProfile,
  OnboardingChecklistStepType.CreatedProject,
];

export const activeProjectChecklistSteps = [
  OnboardingChecklistStepType.CompletedProjectDescription,
  OnboardingChecklistStepType.CreatedBenefit,
  OnboardingChecklistStepType.CreatedICP,
  OnboardingChecklistStepType.CreatedPersona,
  OnboardingChecklistStepType.CreatedMarketingChannel,
  OnboardingChecklistStepType.CreatedDocument,
];

export function getDetailsForChecklistStepType(
  step: OnboardingChecklistStepType
) {
  switch (step) {
    case OnboardingChecklistStepType.CompletedProfile:
      return {
        title: 'Complete your profile',
        description: 'This helps Marketology adapt to your workflow better.',
        buttonTitle: 'Complete Profile',
        icon: 'user',
        link: '/profile',
      };
    case OnboardingChecklistStepType.CreatedProject:
      return {
        title: 'Create your first project',
        description:
          "Everything in Marketology happes in a project, so let's create yours now.",
        buttonTitle: 'Create Project',
        icon: 'project',
        link: '/projects',
      };
    case OnboardingChecklistStepType.CompletedProjectDescription:
      return {
        title: 'Complete your project description',
        description:
          'Marketology uses this description to create all your marketing collateral.',
        buttonTitle: 'Complete Project Description',
        icon: 'project',
        link: '/projects',
      };
    case OnboardingChecklistStepType.CreatedBenefit:
      return {
        title: 'Create your first benefit',
        description:
          'Your customers care about what you can do for them; how they benefit from using your product or service.',
        buttonTitle: 'Create Benefit',
        icon: 'project',
        link: '/benefits',
      };
    case OnboardingChecklistStepType.CreatedICP:
      return {
        title: 'Create your first ICP',
        description:
          'Starting from your project description, Marketology will create your Ideal Customer Profiles.',
        buttonTitle: 'Create ICP',
        icon: 'project',
        link: '/ideal-customer-profiles',
      };
    case OnboardingChecklistStepType.CreatedPersona:
      return {
        title: 'Create your first persona',
        description:
          "Personas are the actual people you're selling to; let's create your first one now.",
        buttonTitle: 'Create Persona',
        icon: 'project',
        link: '/personas',
      };
    case OnboardingChecklistStepType.CreatedMarketingChannel:
      return {
        title: 'Create your first marketing channel',
        description:
          'From the gargantuan number of possible marketing channels, Marketology finds the ones most likely to work for you.',
        buttonTitle: 'Create Marketing Channel',
        icon: 'project',
        link: '/marketing-channels',
      };
    case OnboardingChecklistStepType.CreatedDocument:
      return {
        title: 'Create your first document',
        description:
          "Every piece of content in Marketology is a document. Let's create your first one now.",
        buttonTitle: 'Create Document',
        icon: 'project',
        link: '/documents',
      };
    case OnboardingChecklistStepType.UsedAIDocumentEditor:
      return {
        title: 'Use the AI Document Editor',
        description:
          "We pride ourselves on creating the best AI Document Editor in the world. Let's use it now.",
        buttonTitle: 'Use AI Document Editor',
        icon: 'project',
        link: '/documents',
      };
    case OnboardingChecklistStepType.UsedAIAssistant:
      return {
        title: 'Brainstorm with the AI Assistant',
        description:
          "The AI Assistant is Marketology's most powerful tool, helping you to get unstuck and brainstorming ideas. Let's use it now.",
        buttonTitle: 'Open AI Assistant',
        icon: 'project',
        link: '/documents',
      };
  }
}
