import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  title: string;
  items: string[];
  docRef: DocumentReference;
  updatePath: string;
  isEditing: boolean;
}

export default function CompanyBulletPointsCard({
  title,
  items,
  docRef,
  updatePath,
  isEditing,
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateDocument(docRef, updatedProperties);
    },
    300
  );

  async function addBulletPoint() {
    let properties: any = {};
    properties[updatePath] = [...items, ''];
    await updateDocument(docRef, properties);
  }

  async function deleteBulletPoint(index: number) {
    let properties: any = {};
    properties[updatePath] = items.filter((item, i) => i !== index);
    await updateDocument(docRef, properties);
  }

  return (
    <Card shadow={false} styleClasses="bg-slate-50">
      <div className="flex flex-col justify-start align-top">
        <div className="flex flex-row justify-start gap-2">
          <h3 className="text-xl font-bold text-left text-gray-700">{title}</h3>

          {isEditing && (
            <button
              type="button"
              className={`inline-flex items-center rounded-md border border-transparent bg-transparent px-2 py-1 text-sm font-medium leading-4 text-gray-700 hover:shadow-sm hover:bg-gray-50 active:bg-gray-100`}
              onClick={async () => {
                await addBulletPoint();
              }}
            >
              <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          )}
        </div>
        <ul key={`${items.length}`} className="list-disc">
          {items &&
            items.length > 0 &&
            items.map((eachItem: string, index: number) => (
              <li
                key={index}
                className="ml-5 mr-auto mt-2 text-sm text-left font-regular text-gray-700"
              >
                <EditableTextField
                  text={eachItem}
                  textComponentType="h4"
                  styleClasses={
                    'text-sm font-regular text-gray-700 min-h-[1.4em] bg-slate-50'
                  }
                  index={index}
                  isEditing={isEditing}
                  onValueChange={(updatedValue) => {
                    const updatedItems = items.map((item) =>
                      item === eachItem ? updatedValue : item
                    );
                    let properties: any = {};
                    properties[updatePath] = updatedItems;
                    debouncedPropertiesUpdate(properties);
                  }}
                  onRemove={async (index?: number) => {
                    if (index) {
                      await deleteBulletPoint(index);
                    }
                  }}
                />
              </li>
            ))}
        </ul>
      </div>
    </Card>
  );
}
