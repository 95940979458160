import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// Feature
//

type Feature = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  title?: string;
  description?: string;
  createdAt: Date;
};

const featureConverter: FirestoreDataConverter<Feature> = {
  toFirestore(feature: WithFieldValue<Feature>): DocumentData {
    return {
      owner: feature.owner,
      title: feature.title,
      description: feature.description,
      createdAt: feature.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Feature {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      owner: data.owner,
      title: data.title,
      description: data.description,
      createdAt: data.createdAt,
    };
  },
};

function featureFromFirestore(document: any): Feature {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    title: document.title,
    description: document.description,
    createdAt: document.createdAt,
  };
}

export type { Feature };
export { featureConverter, featureFromFirestore };
