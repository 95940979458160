import React, { useState } from 'react';
import {
  TwitterTweet,
  twitterTweetConverter,
} from '../../../models/TwitterTweetModel';
import {
  collection,
  db,
  query,
  useCollectionData,
} from '../../../components/firebase';

import { AddNewItemCard } from '../../../components/AddNewItemCard';
import { CreateNewTwitterTweetModal } from './CreateNewTwitterTweetModal';
import { TwitterTweetCard } from './TwitterTweetCard';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../../app/projectSlice';
import { selectTeamId } from '../../../app/teamSlice';
import { useAppSelector } from '../../../app/hooks';

export default function TwitterTweets() {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);

  const [twitterTweets] = useCollectionData<TwitterTweet>(
    project &&
      query(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'twitterTweets'
        ),
        orderBy('createdAt', 'desc')
      ).withConverter(twitterTweetConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 space-y-4">
      {project && (
        <div>
          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 min-h-[200px]">
            <li className="col-span-1 flex flex-col min-h-[5em] text-center bg-white rounded-lg ">
              <AddNewItemCard
                onClick={async () => {
                  setNewItemModalOpen(true);
                }}
              />
            </li>

            {twitterTweets &&
              twitterTweets.map((eachTwitterTweet) => (
                <TwitterTweetCard
                  key={eachTwitterTweet.id}
                  twitterTweet={eachTwitterTweet}
                />
              ))}
          </ul>

          <CreateNewTwitterTweetModal
            project={project}
            open={newItemModalOpen}
            setOpen={setNewItemModalOpen}
          />
        </div>
      )}
    </div>
  );
}
