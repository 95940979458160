import React, { useRef, useState } from 'react';
import {
  faCopy,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';

import { Card } from '../Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SafeHtmlRenderer } from '../SafeHtmlRenderer/SafeHtmlRenderer';
import { TextButton } from '../Buttons/TextButton';
import Tooltip from '../Tooltip';
import { copyTextToClipboard } from '../../utils/utils';

interface Props {
  title: string;
  content: string;
}
export default function SuggestedContentCard({ title, content }: Props) {
  const htmlContentRef = useRef<HTMLDivElement | null>(null);

  const [isCopied, setIsCopied] = useState(false);
  const [votedUp, setVotedUp] = useState(false);
  const [votedDown, setVotedDown] = useState(false);

  return (
    <Card>
      <div className="m-auto -mb-2">
        <h1 className="font-bold">{title}</h1>
        <div className="mt-2">
          <SafeHtmlRenderer htmlString={content} ref={htmlContentRef} />
        </div>
        {/* <p className="mt-2 whitespace-pre-wrap">{content}</p> */}
        <div className="mt-2 flex flex-row justify-end">
          <TextButton
            onClick={() => {
              setVotedUp(true);
              setTimeout(() => {
                setVotedUp(false);
              }, 2000);
            }}
          >
            <Tooltip content="Like It" placement="bottom">
              <div className={votedUp ? 'text-green-600' : 'text-gray-500'}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </div>
            </Tooltip>
          </TextButton>

          <TextButton
            onClick={() => {
              setVotedDown(true);
              setTimeout(() => {
                setVotedDown(false);
              }, 2000);
            }}
          >
            <Tooltip content="Not Good" placement="bottom">
              <div className={votedDown ? 'text-green-600' : 'text-gray-500'}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </div>
            </Tooltip>
          </TextButton>

          <TextButton
            onClick={async () => {
              if (htmlContentRef.current) {
                const savedSelection = document
                  .getSelection()
                  ?.getRangeAt(0)
                  .cloneRange();
                const range = document.createRange();
                const selection = window.getSelection();

                if (selection) {
                  range.selectNodeContents(htmlContentRef.current);
                  selection.removeAllRanges();
                  selection.addRange(range);

                  const html = `<html><head></head><body>${htmlContentRef.current.innerHTML}</body></html>`;
                  const data = [
                    new ClipboardItem({
                      'text/html': new Blob([html], { type: 'text/html' }),
                    }),
                  ];

                  await navigator.clipboard.write(data);
                  selection.removeAllRanges();

                  if (savedSelection) {
                    selection.addRange(savedSelection);
                  }

                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 2000);
                }
              }
            }}
          >
            <Tooltip content="Copy" placement="bottom">
              <div className={isCopied ? 'text-green-600' : 'text-gray-500'}>
                <FontAwesomeIcon icon={faCopy} />
              </div>
            </Tooltip>
          </TextButton>
        </div>
      </div>
    </Card>
  );
}
