import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// Teams
//

type Team = {
  id: string;
  ref: DocumentReference<DocumentData>;
  title: string;
  imageUrl?: string;
  websiteUrl?: string;
  subscriptionStatus?: string;
  subscriptionPlan?: string;
  availableCredits?: number;
  createdAt: Date;
};

const teamConverter: FirestoreDataConverter<Team> = {
  toFirestore(team: WithFieldValue<Team>): DocumentData {
    return {
      title: team.title,
      imageUrl: team.imageUrl,
      websiteUrl: team.websiteUrl,
      subscriptionStatus: team.subscriptionStatus,
      subscriptionPlan: team.subscriptionPlan,
      availableCredits: team.availableCredits,
      createdAt: team.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Team {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      title: data.title,
      imageUrl: data.imageUrl,
      websiteUrl: data.websiteUrl,
      subscriptionStatus: data.subscriptionStatus,
      subscriptionPlan: data.subscriptionPlan,
      availableCredits: data.availableCredits,
      createdAt: data.createdAt,
    };
  },
};

function teamFromFirestore(document: any): Team {
  return {
    id: document.id,
    ref: document.ref,
    title: document.title,
    imageUrl: document.imageUrl,
    websiteUrl: document.websiteUrl,
    subscriptionStatus: document.subscriptionStatus,
    subscriptionPlan: document.subscriptionPlan,
    availableCredits: document.availableCredits,
    createdAt: document.createdAt,
  };
}

export type { Team };
export { teamConverter, teamFromFirestore };
