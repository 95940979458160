import React, { useEffect } from 'react';
import {
  WebsitePage,
  websitePageConverter,
} from '../../models/WebsitePageModel';
import { db, doc } from '../../components/firebase';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import GradientsLandingPage from './Templates/GradientsLandingPage';
import { IconButton } from '../../components/Buttons/IconButton';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';
import { useDocumentData } from 'react-firebase-hooks/firestore';

interface Props {
  websitePageId?: string;
  originalWebsiteContent?: WebsitePage;
  showBackButton?: boolean;
}
export default function WebsitePreview({
  websitePageId,
  originalWebsiteContent,
  showBackButton = false,
}: Props) {
  const { id: paramsWebsitePageId } = useParams();

  const pageId = websitePageId || paramsWebsitePageId;

  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);

  const navigate = useNavigate();

  const [websiteContent, setWebsiteContent] = React.useState<
    WebsitePage | undefined
  >(originalWebsiteContent);

  const [websitePage, websitePageLoading] = useDocumentData<
    WebsitePage | undefined
  >(
    pageId
      ? doc(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'websitePages',
          pageId
        ).withConverter(websitePageConverter)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if ((websitePage && !websitePageLoading) || originalWebsiteContent) {
      const content = { ...(websitePage?.content || originalWebsiteContent) };
      content.header = {
        logoImage: '',
        logoText: project?.title || 'Your Logo',
        logoAlt: `${project?.title || 'Company'} Logo`,
        navigation: [
          { name: 'Product', href: '#' },
          { name: 'Benefits', href: '#' },
          { name: 'Company', href: '#' },
        ],
      };
      content.tiers = [
        {
          name: 'Freelancer',
          id: 'tier-freelancer',
          href: '#',
          priceMonthly: '$24',
          description: 'The essentials to provide your best work for clients.',
          features: [
            '5 products',
            'Up to 1,000 subscribers',
            'Basic analytics',
            '48-hour support response time',
          ],
          mostPopular: false,
        },
        {
          name: 'Startup',
          id: 'tier-startup',
          href: '#',
          priceMonthly: '$32',
          description: 'A plan that scales with your rapidly growing business.',
          features: [
            '25 products',
            'Up to 10,000 subscribers',
            'Advanced analytics',
            '24-hour support response time',
            'Marketing automations',
          ],
          mostPopular: true,
        },
        {
          name: 'Enterprise',
          id: 'tier-enterprise',
          href: '#',
          priceMonthly: '$48',
          description: 'Dedicated support and infrastructure for your company.',
          features: [
            'Unlimited products',
            'Unlimited subscribers',
            'Advanced analytics',
            '1-hour, dedicated support response time',
            'Marketing automations',
          ],
          mostPopular: false,
        },
      ];
      content.footer = {
        solutions: [
          { name: 'Hosting', href: '#' },
          { name: 'Data Services', href: '#' },
          { name: 'Uptime Monitoring', href: '#' },
          { name: 'Enterprise Services', href: '#' },
        ],
        support: [
          { name: 'Pricing', href: '#' },
          { name: 'Documentation', href: '#' },
          { name: 'Guides', href: '#' },
          { name: 'API Reference', href: '#' },
        ],
        company: [
          { name: 'About', href: '#' },
          { name: 'Blog', href: '#' },
          { name: 'Jobs', href: '#' },
          { name: 'Press', href: '#' },
          { name: 'Partners', href: '#' },
        ],
        legal: [
          { name: 'Privacy', href: '#' },
          { name: 'Terms', href: '#' },
        ],
      };
      setWebsiteContent(content);
    }
  }, [websitePage?.content, websitePageLoading, originalWebsiteContent]);

  return (
    <div className="relative flex flex-col min-h-full h-full overflow-hidden">
      <div className="h-12 bg-white border-0 border-b border-gray-200 my-auto flex flex-row items-center justify-between">
        <div className={showBackButton ? 'ml-4' : 'invisible'}>
          <IconButton
            icon={ArrowLeftIcon}
            onClick={() => {
              navigate('/website-content');
            }}
          />
        </div>
        <h2>Website Page Preview</h2>
        <div className="invisible">
          <IconButton icon={ArrowLeftIcon} />
        </div>
      </div>

      <div className="relative h-full overflow-y-scroll">
        {websiteContent && <GradientsLandingPage content={websiteContent} />}
      </div>
    </div>
  );
}
