import React, { useCallback } from 'react';
import {
  faBold,
  faItalic,
  faLink,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';

import { AiAction } from './RichTextEditor';
import { Editor } from '@tiptap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RichTextEditorAssistantMenu from './RichTextEditorAssistantMenu';
import RichTextEditorTextStyleMenu from './RichTextEditorTextStyleMenu';
import { TextButton } from '../Buttons/TextButton';

const inactiveIconColor = '#9c9c9c';
const activeIconColor = '#363636';

interface Props {
  editor: Editor | null;
  isAiWorking?: boolean;
  onAiAction?: (action: AiAction) => void;
}

export default function RichTextEditorBubbleMenu({
  editor,
  isAiWorking = false,
  onAiAction,
}: Props) {
  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="px-2 py-0.5 flex flex-row flex-wrap align-middle">
      <RichTextEditorTextStyleMenu editor={editor} />

      <div className="ml-2 pr-2 border-0 border-l border-gray-200" />

      <TextButton onClick={() => editor.chain().focus().toggleBold().run()}>
        <FontAwesomeIcon
          icon={faBold}
          color={editor.isActive('bold') ? activeIconColor : inactiveIconColor}
        />
      </TextButton>
      <TextButton onClick={() => editor.chain().focus().toggleItalic().run()}>
        <FontAwesomeIcon
          icon={faItalic}
          color={
            editor.isActive('italic') ? activeIconColor : inactiveIconColor
          }
        />
      </TextButton>
      <TextButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <FontAwesomeIcon
          icon={faUnderline}
          color={
            editor.isActive('underline') ? activeIconColor : inactiveIconColor
          }
        />
      </TextButton>

      <TextButton onClick={() => editor.chain().focus().toggleStrike().run()}>
        <FontAwesomeIcon
          icon={faStrikethrough}
          color={
            editor.isActive('strike') ? activeIconColor : inactiveIconColor
          }
        />
      </TextButton>

      <div className="ml-2 pr-2 border-0 border-l border-gray-200" />

      <TextButton onClick={setLink}>
        <FontAwesomeIcon
          icon={faLink}
          color={editor.isActive('link') ? 'blue' : inactiveIconColor}
        />
      </TextButton>

      <div className="ml-2 pr-2 border-0 border-l border-gray-200" />

      <RichTextEditorAssistantMenu
        isAiWorking={isAiWorking}
        onAiAction={onAiAction}
      />
    </div>
  );
}
