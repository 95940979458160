import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// TwitterTweet
//

export enum TwitterTweetType {
  Generic = 'generic',
  UnpopularOpinion = 'unpopularOpinion',
  Thinking = 'thinking',
  HotTake = 'hotTake',
  AskMeAnything = 'askMeAnything',
  ThisOrThat = 'thisOrThat',
  Top3 = 'top3',
  Confession = 'confession',
}

export type TwitterTweet = {
  id: string;
  ref: DocumentReference<DocumentData>;
  type: TwitterTweetType;
  tweet: string;
  createdAt: Date;
};

export const twitterTweetConverter: FirestoreDataConverter<TwitterTweet> = {
  toFirestore(twitterTweet: WithFieldValue<TwitterTweet>): DocumentData {
    return {
      tweet: twitterTweet.tweet,
      createdAt: twitterTweet.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): TwitterTweet {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      type: data.type,
      tweet: data.tweet,
      createdAt: data.createdAt,
    };
  },
};

export function twitterTweetFromFirestore(document: any): TwitterTweet {
  return {
    id: document.id,
    ref: document.ref,
    type: document.type,
    tweet: document.tweet,
    createdAt: document.createdAt,
  };
}

export function twitterTweetFromStreamingContent(
  partialDocument: any
): TwitterTweet {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    type: partialDocument.type || 'generic',
    tweet: partialDocument.tweet || 'Writing in progress...',
    createdAt: partialDocument.createdAt || new Date(),
  };
}
