import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// WebsitePage
//

type WebsitePage = {
  id: string;
  ref: DocumentReference<DocumentData>;
  title: string;
  description: string;
  type: 'landingPage' | 'blogPost';
  content: any;
  createdAt: Date;
};

const websitePageConverter: FirestoreDataConverter<WebsitePage> = {
  toFirestore(websitePage: WithFieldValue<WebsitePage>): DocumentData {
    return {
      title: websitePage.title,
      description: websitePage.description,
      type: websitePage.type,
      content: websitePage.content,
      createdAt: websitePage.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): WebsitePage {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      title: data.title,
      description: data.description,
      type: data.type,
      content: data.content,
      createdAt: data.createdAt,
    };
  },
};

function websitePageFromFirestore(document: any): WebsitePage {
  return {
    id: document.id,
    ref: document.ref,
    title: document.title,
    description: document.description,
    type: document.type,
    content: document.content,
    createdAt: document.createdAt,
  };
}

function websitePageFromStreamingContent(partialDocument: any): WebsitePage {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    title: partialDocument.title || 'Writing in progress...',
    description: partialDocument.description || 'Description coming up...',
    type: partialDocument.type || 'landingPage',
    content: partialDocument.content || {},
    createdAt: partialDocument.createdAt || new Date(),
  };
}

export type { WebsitePage };
export {
  websitePageConverter,
  websitePageFromFirestore,
  websitePageFromStreamingContent,
};
