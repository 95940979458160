import Assistant from '../components/Assistant/Assistant';
import React from 'react';
import { Tab } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface Props {
  sidebarOpen: boolean;
  sidebarView: React.ReactNode;
}

export function RightSidebar({ sidebarOpen, sidebarView }: Props) {
  let tabs = [
    {
      id: 'suggestedContent',
      title: 'Content Suggestions',
      content: sidebarView,
    },
    {
      id: 'aiAssistant',
      title: 'AI Assistant',
      content: <Assistant />,
    },
  ];

  return (
    <div
      className={twMerge(
        'sticky right-0 transition-all ease-in-out duration-300 bg-slate-50 border-0 border-l border-slate-200 h-[calc(100vh_-_64px)]',
        sidebarOpen ? 'w-1/3' : 'w-0'
      )}
    >
      <div className="flex flex-col h-full w-[calc(100vw_/_3)] lg:w-[calc((100vw_-_15rem)_/_3)]">
        {sidebarView && (
          <Tab.Group>
            <Tab.List className="flex space-x-[1px] bg-blue-900/20 border-0 border-b border-gray-300">
              {tabs.map((eachTab) => (
                <Tab
                  key={eachTab.id}
                  className={({ selected }) =>
                    twMerge(
                      selected
                        ? 'text-gray-900'
                        : 'text-gray-500 hover:text-gray-700',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-2 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                    )
                  }
                >
                  {({ selected }) => (
                    <div>
                      <span>{eachTab.title}</span>
                      <span
                        aria-hidden="true"
                        className={twMerge(
                          selected ? 'bg-indigo-500' : 'bg-transparent',
                          'absolute inset-x-0 bottom-0 h-0.5'
                        )}
                      />
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels className="h-full">
              {tabs.map((eachTab, idx) => (
                <Tab.Panel key={idx} className="h-full">
                  {eachTab.content}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        )}

        {!sidebarView && <Assistant />}
      </div>
    </div>
  );
}
