import {
  ChatMessage,
  chatMessageConverter,
} from '../../models/ChatMessageModel';
import React, { useState } from 'react';
import {
  Timestamp,
  collection,
  doc,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { addDocument, db, getIdToken } from '../firebase';
import {
  selectChatSessionId,
  setSelectedChatSession,
} from '../../app/chatSessionSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import ChatInput from './ChatInput';
import ChatMessageBubble from './ChatMessageBubble';
import ChatMessageWriting from './ChatMessageWriting';
import Divider from '../Divider';
import { GhostButton } from '../Buttons/GhostButton';
import { SelectChatSessionModal } from '../../screens/SelectChatSessionModal/SelectChatSessionModal';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export default function Assistant() {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState<string | undefined>(
    undefined
  );
  const [temporaryUserMessage, setTemporaryUserMessage] = useState<
    | {
        role: 'assistant' | 'user';
        content: string;
      }
    | undefined
  >();
  const chatSessionId = useAppSelector(selectChatSessionId);

  const dispatch = useAppDispatch();

  const [messages, messagesLoading] = useCollectionData<ChatMessage>(
    project?.id && chatSessionId
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            project?.id || '',
            'chatSessions',
            chatSessionId || '',
            'messages'
          ),
          where('visible', '==', true),
          orderBy('createdAt')
        ).withConverter(chatMessageConverter)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  async function addNewMessage(text: string) {
    const newMessage: {
      role: 'assistant' | 'user';
      content: string;
      visible: boolean;
    } = {
      role: 'user',
      content: text,
      visible: true,
    };

    if (!chatSessionId) {
      setTemporaryUserMessage(newMessage);
      return;
    }

    try {
      await addDocument(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'chatSessions',
          chatSessionId || '',
          'messages'
        ),
        newMessage
      );
    } catch (error) {
      console.error('Error creating a new user message:', error);
    }
  }

  async function handleNewUserMessage(text: string) {
    if (!teamId || !project?.id) {
      return;
    }

    setIsProcessing(true);

    const idToken = await getIdToken();

    if (!idToken) {
      setIsProcessing(false);
      return;
    }

    try {
      await addNewMessage(text);

      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + '/chat/get-chat-response',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify({
            projectId: project.id,
            teamId: teamId,
            latestUserMessage: text,
            chatSessionId,
          }),
        }
      );

      const chatSessionHeader = response?.headers.get('X-Chat-Session');

      if (!chatSessionHeader) {
        alert(
          'Error starting your chat session. Please try again or contact support. ERROR - 001'
        );
        setIsProcessing(false);
        return;
      }

      const reader = response?.body?.getReader();
      if (!reader) {
        return;
      }

      let completion = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const chunk = new TextDecoder('utf-8').decode(value);

        completion += chunk;
        setStreamingMessage(completion);
      }

      try {
        const sessionJson = JSON.parse(chatSessionHeader);
        const session = {
          id: sessionJson.id,
          ref: doc(db, sessionJson.path),
          title: sessionJson.title,
          createdAt: new Timestamp(
            sessionJson.createdAt.seconds,
            sessionJson.createdAt.nanoseconds
          ).toDate(),
        };

        dispatch(setSelectedChatSession(session));
      } catch (error) {
        alert(
          'Error starting your chat session. Please try again or contact support. ERROR - 002'
        );
      }

      setTemporaryUserMessage(undefined);
    } catch (error) {
      console.error('Error creating a new user message:', error);
    } finally {
      setStreamingMessage(undefined);
      setIsProcessing(false);
    }
  }

  return (
    <div className="flex py-4 flex-col flex-grow h-full">
      <div className="w-full font-bold text-gray-500 pb-4">
        <div className="flex flex-row items-center align-middle justify-between px-4">
          <h1>AI Assistant</h1>
          <GhostButton
            title={'Other Chats'}
            onClick={() => {
              setSelectionModalOpen(true);
            }}
          />
        </div>
      </div>

      <Divider type="horizontal" />

      <div className="flex flex-col justify-between h-full overflow-hidden">
        <div className="pt-4 pb-10 px-4 overflow-y-scroll">
          <div className="flex flex-col gap-y-4">
            {messages &&
              messages.map((message, index) => (
                <ChatMessageBubble key={index} message={message} />
              ))}

            {!chatSessionId && (
              <ChatMessageBubble
                key={0}
                message={{
                  role: 'assistant',
                  content: 'How may I help you today?',
                }}
              />
            )}

            {temporaryUserMessage && (
              <ChatMessageBubble
                key={1}
                message={{
                  role: temporaryUserMessage.role,
                  content: temporaryUserMessage.content,
                }}
              />
            )}

            {isProcessing && <ChatMessageWriting text={streamingMessage} />}
          </div>
        </div>

        <div className="">
          <Divider type="horizontal" />
          <div className="px-4">
            <ChatInput
              onClearChat={() => {
                dispatch(setSelectedChatSession(undefined));
              }}
              onSend={async (text: string) => {
                await handleNewUserMessage(text);
              }}
            />
          </div>
        </div>
      </div>

      <SelectChatSessionModal
        open={selectionModalOpen}
        setOpen={setSelectionModalOpen}
      />
    </div>
  );
}
