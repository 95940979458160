import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { db, doc } from '../components/firebase';

import { Document } from '../models/DocumentModel';
import { RootState } from './store';
import { StreamingDocument } from '../models/StreamingDocumentModel';
import dayjs from 'dayjs';

export interface DocumentState {
  document: Document | undefined;
  streamingDocument: StreamingDocument | undefined;
}

export const initialDocumentState: DocumentState = {
  document: undefined,
  streamingDocument: undefined,
};

export const documentSlice = createSlice({
  name: 'document',
  initialState: initialDocumentState,
  reducers: {
    setDocument: (state, action: PayloadAction<Document | undefined>) => {
      state.document = action.payload;
    },
    setStreamingDocument: (
      state,
      action: PayloadAction<StreamingDocument | undefined>
    ) => {
      state.streamingDocument = action.payload;
    },
  },
});

export const { setDocument, setStreamingDocument } = documentSlice.actions;

export const selectDocument = (state: RootState) => {
  const storedDocument: any = state.document.document;

  if (!storedDocument) {
    return undefined;
  }

  return {
    id: storedDocument.id,
    ref: doc(db, storedDocument.ref),
    type: storedDocument.tupe,
    title: storedDocument.title,
    content: storedDocument.content,
    createdAt: dayjs(storedDocument.createdAt).toDate(),
  } as Document;
};

export const selectStreamingDocument = (state: RootState) => {
  return state.document.streamingDocument;
};

export const selectDocumentId = (state: RootState) =>
  state.document.document?.id;

export default documentSlice.reducer;
