import { Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import OnboardingChecklist from './OnboardingChecklist';
import { twMerge } from 'tailwind-merge';

export default function OnboardingChecklistModal() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={twMerge(
              'inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-medium leading-4 focus:outline-none',
              open
                ? 'bg-indigo-600 hover:bg-indigo-700 text-white'
                : 'bg-gray-50 hover:bg-gray-100 text-gray-700'
            )}
          >
            <span className="sr-only">Open Marketology Guide</span>
            <span>Marketology Guide</span>
            <ChevronDownIcon
              className={twMerge(
                open ? 'text-white' : 'text-indigo-800',
                'm-0 ml-2 h-4 w-4  transition duration-150 ease-in-out group-hover:text-opacity-100'
              )}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-[500px] -translate-x-1/2 transform px-4 sm:px-0">
              {({ close }) => (
                <div className="overflow-hidden max-w-lg rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 h-full">
                  <OnboardingChecklist
                    type="modal"
                    onStepClick={() => {
                      close();
                    }}
                  />
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
