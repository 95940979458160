import { Listbox, Menu, Transition } from '@headlessui/react';
import {
  faArrowsRotate,
  faBrain,
  faDownLeftAndUpRightToCenter,
  faGlasses,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';

import { AiAction } from './RichTextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import Spinner from '../Spinner';
import { twMerge } from 'tailwind-merge';

interface Props {
  isAiWorking?: boolean;
  onAiAction?: (action: AiAction) => void;
}

export default function RichTextEditorAssistantMenu({
  isAiWorking = false,
  onAiAction,
}: Props) {
  const options = [
    {
      id: AiAction.Rephrase,
      title: 'Rephrase',
      icon: (
        <>
          <FontAwesomeIcon icon={faArrowsRotate} />
        </>
      ),
    },
    {
      id: AiAction.Shorten,
      title: 'Shorten',
      icon: (
        <>
          <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
        </>
      ),
    },
    {
      id: AiAction.Expand,
      title: 'Expand',
      icon: (
        <>
          <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
        </>
      ),
    },
    {
      id: AiAction.ImproveFlow,
      title: 'Improve flow',
      icon: (
        <>
          <FontAwesomeIcon icon={faGlasses} />
        </>
      ),
    },
    {
      id: AiAction.CustomPrompt,
      title: 'Custom',
      icon: (
        <>
          <FontAwesomeIcon icon={faBrain} />
        </>
      ),
    },
  ];

  function handleSelectedOption(action: AiAction) {
    onAiAction?.(action);
  }

  return (
    <Menu>
      {({ open }) => (
        <div className="relative">
          <Menu.Button className="relative flex flex-row w-full align-middle items-center justify-between cursor-pointer rounded-md bg-white py-1.5 px-2 text-gray-900 sm:text-sm sm:leading-6 min-w-[50px] hover:bg-gray-50">
            <div className="flex flex-row gap-2 items-center align-middle text-indigo-700">
              {isAiWorking ? (
                <Spinner size="xs" />
              ) : (
                <div className="px-[1px]">
                  <FontAwesomeIcon icon={faBrain} />
                </div>
              )}
              <p className="font-semibold">AI Assistant</p>
            </div>
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 mt-1 -mx-2 max-h-60 w-[calc(100%_+_32px)] overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option) => (
                <Menu.Item key={option.id}>
                  {({ active }) => (
                    <div
                      className={twMerge(
                        'py-2 px-4 flex flex-row gap-4 font-medium cursor-pointer select-none',
                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-500'
                      )}
                      onClick={async () => {
                        handleSelectedOption(option.id);
                      }}
                    >
                      <span>{option.icon}</span>
                      <span>{option.title}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
}
