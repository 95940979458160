import { EditableTextField } from '../EditableTextField';
import React from 'react';

interface Props {
  text: string;
  index: number;
  isEditing: boolean;
  onValueChange?: (value: string) => void;
  onRemove?: (index: number) => void;
}

export default function BulletPointRow({
  text,
  index,
  isEditing,
  onValueChange,
  onRemove,
}: Props) {
  return (
    <li className="ml-5 mr-auto mt-2 text-sm text-left font-regular text-gray-700">
      <EditableTextField
        text={text}
        textComponentType="h4"
        styleClasses={'text-sm font-regular text-gray-700 min-h-[1.4em]'}
        isEditing={isEditing}
        onValueChange={onValueChange}
        onRemove={() => onRemove?.(index)}
      />
    </li>
  );
}
