import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { db, doc } from '../components/firebase';

import { RootState } from './store';
import { Team } from '../models/TeamModel';
import dayjs from 'dayjs';

export interface TeamState {
  currentlySelectedTeam: Team | undefined;
}

export const initialTeamState: TeamState = {
  currentlySelectedTeam: undefined,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState: initialTeamState,
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<Team | undefined>) => {
      state.currentlySelectedTeam = action.payload;
    },
  },
});

export const { setSelectedTeam } = teamSlice.actions;

export const selectTeam = (state: RootState) => {
  const storedTeam: any = state.team.currentlySelectedTeam;

  // console.log('returning storedTeam: ', storedTeam);

  if (!storedTeam) {
    return undefined;
  }

  return {
    id: storedTeam.id,
    ref: doc(db, storedTeam.ref),
    title: storedTeam.title,
    imageUrl: storedTeam.imageUrl,
    websiteUrl: storedTeam.websiteUrl,
    subscriptionStatus: storedTeam.subscriptionStatus,
    subscriptionPlan: storedTeam.subscriptionPlan,
    availableCredits: storedTeam.availableCredits,
    createdAt: dayjs(storedTeam.createdAt).toDate(),
  } as Team;
};

export const selectTeamId = (state: RootState) =>
  state.team.currentlySelectedTeam?.id;

export default teamSlice.reducer;
