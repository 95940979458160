import React, { useEffect, useState } from 'react';
import { db, doc, updateDocument } from '../../../components/firebase';

import { Card } from '../../../components/Card';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { selectTeam } from '../../../app/teamSlice';
import { useAppSelector } from '../../../app/hooks';

export default function SettingsTeam() {
  const team = useAppSelector(selectTeam);

  const [teamName, setTeamName] = useState<string | undefined>(team?.title);
  const [teamWebsiteUrl, setTeamWebsiteUrl] = useState<string | undefined>(
    team?.websiteUrl
  );

  useEffect(() => {
    if (team) {
      setTeamName(team.title);
      setTeamWebsiteUrl(team.websiteUrl);
    }
  }, [team]);

  async function saveTeamDetails() {
    if (!team) {
      return;
    }

    await updateDocument(doc(db, 'teams', team?.id || ''), {
      title: teamName || '',
      websiteUrl: teamWebsiteUrl || '',
    });

    // Intercom('update', {
    //   company: {
    //     id: team?.id || '',
    //     name: teamName || '',
    //     website: teamWebsiteUrl || '',
    //   },
    // });
  }

  return (
    <Card shadow={true}>
      <div className="flex flex-col justify-start">
        <div className="flex flex-row flex-grow gap-4 flex-wrap">
          <div className="flex-grow">
            <label className="block text-md font-medium text-gray-700">
              Team Name
            </label>
            <div className="mt-2">
              <input
                key={'team-name'}
                id="teamName"
                name="teamName"
                type="text"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                defaultValue={team?.title}
                onChange={(e) => {
                  setTeamName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex-grow">
            <label className="block text-md font-medium text-gray-700">
              Website URL
            </label>
            <div className="mt-2">
              <input
                key={'team-website-url'}
                id="teamWebsiteUrl"
                name="teamWebsiteUrl"
                type="text"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                defaultValue={team?.websiteUrl}
                onChange={(e) => {
                  setTeamWebsiteUrl(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-8">
          <label className="block text-md font-medium text-gray-700">
            Available Credits
          </label>
          <div className="mt-2">
            <input
              key={'team-credits'}
              id="teamCredits"
              name="teamCredits"
              type="text"
              className="block w-full rounded-md border-gray-300 bg-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={team?.availableCredits}
              disabled={true}
            />
          </div>
        </div>

        <div className="mt-8">
          <PrimaryButton
            title={'Save'}
            disabled={
              teamName === team?.title && teamWebsiteUrl === team?.websiteUrl
            }
            onClick={saveTeamDetails}
          />
        </div>
      </div>
    </Card>
  );
}
