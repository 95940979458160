import { BeakerIcon, InboxIcon } from '@heroicons/react/24/solid';

import React from 'react';

interface Props {
  title?: string;
  description?: string;
  imageUrl?: string;
  selected: boolean;
  onClick: () => void;
}

export function ProjectCard({
  title,
  description,
  imageUrl,
  selected,
  onClick,
}: Props) {
  return (
    <li
      key={title}
      className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow cursor-pointer ${
        selected ? 'ring-2 ring-purple-500' : ''
      }`}
      onClick={onClick}
    >
      <div className="flex flex-1 flex-col p-8">
        <div className="mx-auto h-24 w-24 flex-shrink-0 rounded-full overflow-clip">
          {imageUrl && (
            <img
              className="mx-auto h-24 w-24 flex-shrink-0 rounded-full"
              src={imageUrl}
              alt="project logo"
            />
          )}

          {!imageUrl && (
            <div className="h-24 w-24 bg-slate-200 rounded-full flex items-center justify-center">
              <BeakerIcon className="h-12 w-12 text-white " />
            </div>
          )}
        </div>
        <h3 className="mt-6 text-2xl font-medium text-gray-900">{title}</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dd className="text-sm text-gray-500 max-h-60 text-ellipsis overflow-hidden">
            {description}
          </dd>
        </dl>
      </div>
    </li>
  );
}
