export enum Language {
  EnglishUS = 'en_US',
  EnglishUK = 'en_UK',
  Arabic = 'ar',
  Bengali = 'bn',
  ChineseSimplified = 'zh_CN',
  ChineseTraditional = 'zh_TW',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  Finnish = 'fi',
  French = 'fr',
  German = 'de',
  Greek = 'el',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Marathi = 'mr',
  Norwegian = 'no',
  Polish = 'pl',
  Portuguese = 'pt',
  Russian = 'ru',
  Romanian = 'ro',
  Spanish = 'es',
  Swedish = 'sv',
  Tamil = 'ta',
  Telugu = 'te',
  Thai = 'th',
  Turkish = 'tr',
  Urdu = 'ur',
}

export function getLanguagePayload(language: Language) {
  switch (language) {
    case Language.EnglishUS:
      return {
        language: 'en',
        languageCode: 'en-US',
        languageName: 'English',
        emojiFlag: '🇺🇸',
      };
    case Language.EnglishUK:
      return {
        language: 'en',
        languageCode: 'en-GB',
        languageName: 'English',
        emojiFlag: '🇬🇧',
      };
    case Language.Arabic:
      return {
        language: 'ar',
        languageCode: 'ar',
        languageName: 'Arabic',
        emojiFlag: '🇸🇦',
      };
    case Language.Bengali:
      return {
        language: 'bn',
        languageCode: 'bn',
        languageName: 'Bengali',
        emojiFlag: '🇧🇩',
      };
    case Language.ChineseSimplified:
      return {
        language: 'zh',
        languageCode: 'zh-CN',
        languageName: 'Chinese Simplified',
        emojiFlag: '🇨🇳',
      };
    case Language.ChineseTraditional:
      return {
        language: 'zh',
        languageCode: 'zh-TW',
        languageName: 'Chinese Traditional',
        emojiFlag: '🇹🇼',
      };
    case Language.Czech:
      return {
        language: 'cs',
        languageCode: 'cs',
        languageName: 'Czech',
        emojiFlag: '🇨🇿',
      };
    case Language.Danish:
      return {
        language: 'da',
        languageCode: 'da',
        languageName: 'Danish',
        emojiFlag: '🇩🇰',
      };
    case Language.Dutch:
      return {
        language: 'nl',
        languageCode: 'nl',
        languageName: 'Dutch',
        emojiFlag: '🇳🇱',
      };
    case Language.Finnish:
      return {
        language: 'fi',
        languageCode: 'fi',
        languageName: 'Finnish',
        emojiFlag: '🇫🇮',
      };
    case Language.French:
      return {
        language: 'fr',
        languageCode: 'fr',
        languageName: 'French',
        emojiFlag: '🇫🇷',
      };
    case Language.German:
      return {
        language: 'de',
        languageCode: 'de',
        languageName: 'German',
        emojiFlag: '🇩🇪',
      };
    case Language.Greek:
      return {
        language: 'el',
        languageCode: 'el',
        languageName: 'Greek',
        emojiFlag: '🇬🇷',
      };
    case Language.Hebrew:
      return {
        language: 'he',
        languageCode: 'he',
        languageName: 'Hebrew',
        emojiFlag: '🇮🇱',
      };
    case Language.Hindi:
      return {
        language: 'hi',
        languageCode: 'hi',
        languageName: 'Hindi',
        emojiFlag: '🇮🇳',
      };
    case Language.Hungarian:
      return {
        language: 'hu',
        languageCode: 'hu',
        languageName: 'Hungarian',
        emojiFlag: '🇭🇺',
      };
    case Language.Indonesian:
      return {
        language: 'id',
        languageCode: 'id',
        languageName: 'Indonesian',
        emojiFlag: '🇮🇩',
      };
    case Language.Italian:
      return {
        language: 'it',
        languageCode: 'it',
        languageName: 'Italian',
        emojiFlag: '🇮🇹',
      };
    case Language.Japanese:
      return {
        language: 'ja',
        languageCode: 'ja',
        languageName: 'Japanese',
        emojiFlag: '🇯🇵',
      };
    case Language.Korean:
      return {
        language: 'ko',
        languageCode: 'ko',
        languageName: 'Korean',
        emojiFlag: '🇰🇷',
      };
    case Language.Marathi:
      return {
        language: 'mr',
        languageCode: 'mr',
        languageName: 'Marathi',
        emojiFlag: '🇮🇳',
      };
    case Language.Norwegian:
      return {
        language: 'no',
        languageCode: 'no',
        languageName: 'Norwegian',
        emojiFlag: '🇳🇴',
      };
    case Language.Polish:
      return {
        language: 'pl',
        languageCode: 'pl',
        languageName: 'Polish',
        emojiFlag: '🇵🇱',
      };
    case Language.Portuguese:
      return {
        language: 'pt',
        languageCode: 'pt',
        languageName: 'Portuguese',
        emojiFlag: '🇵🇹',
      };
    case Language.Romanian:
      return {
        language: 'ro',
        languageCode: 'ro',
        languageName: 'Romanian',
        emojiFlag: '🇷🇴',
      };
    case Language.Russian:
      return {
        language: 'ru',
        languageCode: 'ru',
        languageName: 'Russian',
        emojiFlag: '🇷🇺',
      };
    case Language.Spanish:
      return {
        language: 'es',
        languageCode: 'es',
        languageName: 'Spanish',
        emojiFlag: '🇪🇸',
      };
    case Language.Swedish:
      return {
        language: 'sv',
        languageCode: 'sv',
        languageName: 'Swedish',
        emojiFlag: '🇸🇪',
      };
    case Language.Tamil:
      return {
        language: 'ta',
        languageCode: 'ta',
        languageName: 'Tamil',
        emojiFlag: '🇮🇳',
      };
    case Language.Telugu:
      return {
        language: 'te',
        languageCode: 'te',
        languageName: 'Telugu',
        emojiFlag: '🇮🇳',
      };
    case Language.Thai:
      return {
        language: 'th',
        languageCode: 'th',
        languageName: 'Thai',
        emojiFlag: '🇹🇭',
      };
    case Language.Turkish:
      return {
        language: 'tr',
        languageCode: 'tr',
        languageName: 'Turkish',
        emojiFlag: '🇹🇷',
      };
    case Language.Urdu:
      return {
        language: 'ur',
        languageCode: 'ur',
        languageName: 'Urdu',
        emojiFlag: '🇵🇰',
      };
  }
}
