import { CheckIcon } from '@heroicons/react/20/solid';
import Spinner from '../components/Spinner';
import { twMerge } from 'tailwind-merge';

export enum StepStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Done = 'Done',
}

interface Props {
  steps: {
    name: string;
    description: string;
    status: StepStatus;
    children: React.ReactNode;
  }[];
  clickable?: boolean;
  minStepClickable?: number;
  maxStepClickable?: number;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
}

export default function Steps({
  steps,
  clickable = false,
  minStepClickable,
  maxStepClickable,
  currentStep,
  setCurrentStep,
}: Props) {
  function handleStepClick(stepIdx: number) {
    if (clickable) {
      if (minStepClickable && stepIdx < minStepClickable) {
        return;
      }
      if (maxStepClickable && stepIdx > maxStepClickable) {
        return;
      }

      setCurrentStep(stepIdx);
    }
  }

  function lastContinuousCompletedStepIndex() {
    let lastContinuousCompletedStepIndex = 0;
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].status === StepStatus.Done) {
        lastContinuousCompletedStepIndex = i;
      } else {
        break;
      }
    }
    return lastContinuousCompletedStepIndex;
  }

  function getStepForStatus(
    status: StepStatus,
    stepIdx: number,
    title: string
  ) {
    if (stepIdx === currentStep) {
      return (
        <>
          {getProgressLineForStepIndex(stepIdx)}
          <div
            className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
            aria-current="step"
          >
            {status === StepStatus.InProgress && (
              <div className="-mt-[1px]">
                <Spinner size="md" />
              </div>
            )}
            <span
              className="absolute h-2.5 w-2.5 rounded-full bg-indigo-600 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              aria-hidden="true"
            />
            <span className="sr-only">{title}</span>
          </div>
          <div>
            <span className="text-sm font-medium">{title}</span>
          </div>
        </>
      );
    }

    switch (status) {
      case StepStatus.NotStarted:
        return (
          <>
            {getProgressLineForStepIndex(stepIdx)}

            <div
              className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
              onClick={() => handleStepClick(stepIdx)}
            >
              <span
                className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                aria-hidden="true"
              />
              <span className="sr-only">{title}</span>
            </div>
            <div>
              <span className="text-sm font-medium">{title}</span>
            </div>
          </>
        );
      case StepStatus.InProgress:
        return (
          <>
            {getProgressLineForStepIndex(stepIdx)}

            <div
              className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white hover:border-indigo-900"
              onClick={() => handleStepClick(stepIdx)}
            >
              <div className="-mt-[1px]">
                <Spinner size="md" />
              </div>
              <span
                className="absolute h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                aria-hidden="true"
              />

              <span className="sr-only">{title}</span>
            </div>
            <div>
              <span className="text-sm font-medium">{title}</span>
            </div>
          </>
        );
      case StepStatus.Done:
        return (
          <div className="flex flex-col items-center mx-auto">
            {getProgressLineForStepIndex(stepIdx)}
            <div
              className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900"
              onClick={() => handleStepClick(stepIdx)}
            >
              <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
              <span className="sr-only">{title}</span>
            </div>
            <div>
              <span className="text-sm font-medium">{title}</span>
            </div>
          </div>
        );
    }
  }

  function getProgressLineForStepIndex(stepIdx: number) {
    return (
      <div className="absolute inset-0 flex" aria-hidden="true">
        <div
          className={twMerge(
            'h-0.5 mt-4 w-1/2',
            stepIdx === 0
              ? 'bg-transparent'
              : lastContinuousCompletedStepIndex() >= stepIdx
              ? 'bg-indigo-600'
              : 'bg-gray-200'
          )}
        />
        <div
          className={twMerge(
            'h-0.5 mt-4 w-1/2 bg-indigo-600',
            lastContinuousCompletedStepIndex() > stepIdx
              ? 'bg-indigo-600'
              : stepIdx < steps.length - 1
              ? 'bg-gray-200'
              : 'bg-transparent'
          )}
        />
      </div>
    );
  }

  return (
    <nav aria-label="Progress" className="w-full">
      <div className="max-w-5xl mx-auto">
        <ol className="flex items-center justify-center">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className={twMerge('relative flex flex-1')}>
              <div className={twMerge('flex flex-col items-center mx-auto')}>
                {getStepForStatus(step.status, stepIdx, step.name)}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
}
