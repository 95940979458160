import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { db, doc } from '../components/firebase';

import { ChatSession } from '../models/ChatSessionModel';
import { RootState } from './store';
import dayjs from 'dayjs';

export interface ChatSessionState {
  chatSession: ChatSession | undefined;
}

export const initialChatSessionState: ChatSessionState = {
  chatSession: undefined,
};

export const userSlice = createSlice({
  name: 'chatSession',
  initialState: initialChatSessionState,
  reducers: {
    setSelectedChatSession: (
      state,
      action: PayloadAction<ChatSession | undefined>
    ) => {
      state.chatSession = action.payload;
    },
  },
});

export const { setSelectedChatSession } = userSlice.actions;

export const selectChatSession = (state: RootState) => {
  const storedChatSession: any = state.chatSession.chatSession;

  // console.log('returning storedChatSession: ', storedChatSession);

  if (!storedChatSession) {
    return undefined;
  }

  return {
    id: storedChatSession.id,
    ref: doc(db, storedChatSession.ref),
    title: storedChatSession.title,
    createdAt: dayjs(storedChatSession.createdAt).toDate(),
  } as ChatSession;
};

export const selectChatSessionId = (state: RootState) =>
  state.chatSession.chatSession?.id;

export default userSlice.reducer;
