/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowPathIcon,
  Bars3Icon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { CheckIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';

const dummyContent = {
  header: {
    // logo: 'https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg',
    logoImage: '',
    logoText: 'Workflow',
    logoAlt: 'Workflow Logo',
    navigation: [
      { name: 'Product', href: '#' },
      { name: 'Benefits', href: '#' },
      { name: 'Company', href: '#' },
    ],
  },
  hero: {
    title: 'The all-in-one platform to grow your business',
    subtitle:
      'From landing pages to marketing automations, we have you covered.',
  },
  benefits: {
    title: 'Everything you need to deploy your app',
    subtitle:
      'Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum pulvinar et feugiat blandit at. In mi viverra elit nunc.',
    benefits: [
      {
        name: 'Push to deploy',
        description:
          'Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.',
        icon: CloudArrowUpIcon,
      },
      {
        name: 'SSL certificates',
        description:
          'Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.',
        icon: LockClosedIcon,
      },
      {
        name: 'Simple queues',
        description:
          'Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.',
        icon: ArrowPathIcon,
      },
      {
        name: 'Advanced security',
        description:
          'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
        icon: FingerPrintIcon,
      },
      {
        name: 'Simple queues',
        description:
          'Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.',
        icon: ArrowPathIcon,
      },
      {
        name: 'Advanced security',
        description:
          'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
        icon: FingerPrintIcon,
      },
    ],
  },
  testimonials: {
    title: 'What our customers are saying',
    subtitle:
      'Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi.',
    testimonials: [
      {
        name: 'Whitney Francis',
        role: 'CEO, Acme',
        avatar:
          'https://tailwindui.com/img/ecommerce-images/home-page-03-testimonial-01.jpg',
        quote:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      {
        name: 'Leonard Krasner',
        role: 'Founder, Pixelmotiv',
        avatar:
          'https://tailwindui.com/img/ecommerce-images/home-page-03-testimonial-02.jpg',
        quote:
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        name: 'Floyd Miles',
        role: 'CTO, Tesla',
        avatar:
          'https://tailwindui.com/img/ecommerce-images/home-page-03-testimonial-03.jpg',
        quote:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
      },
    ],
  },
  tiers: [
    {
      name: 'Freelancer',
      id: 'tier-freelancer',
      href: '#',
      priceMonthly: '$24',
      description: 'The essentials to provide your best work for clients.',
      features: [
        '5 products',
        'Up to 1,000 subscribers',
        'Basic analytics',
        '48-hour support response time',
      ],
      mostPopular: false,
    },
    {
      name: 'Startup',
      id: 'tier-startup',
      href: '#',
      priceMonthly: '$32',
      description: 'A plan that scales with your rapidly growing business.',
      features: [
        '25 products',
        'Up to 10,000 subscribers',
        'Advanced analytics',
        '24-hour support response time',
        'Marketing automations',
      ],
      mostPopular: true,
    },
    {
      name: 'Enterprise',
      id: 'tier-enterprise',
      href: '#',
      priceMonthly: '$48',
      description: 'Dedicated support and infrastructure for your company.',
      features: [
        'Unlimited products',
        'Unlimited subscribers',
        'Advanced analytics',
        '1-hour, dedicated support response time',
        'Marketing automations',
      ],
      mostPopular: false,
    },
  ],
  faqs: [
    {
      id: 1,
      question: 'Can I try before subscribing?',
      answer:
        "Yes, of course! In fact, we have a fully featured 30-day trial with no credit card required. Simply sign up, try it for a month and decide if it is for you - no hard feelings if it's not, we're all grown-ups.",
    },
    {
      id: 2,
      question: 'What payment methods do you support?',
      answer:
        'Like many other apps, we use Stripe (which is the industry standard and very secure), so you can use most credit and debit cards to subscribe.',
    },
    {
      id: 3,
      question: 'What is your refund policy?',
      answer:
        "We're very flexible. If, for whatever reason you don't think it is for you (after your trial ends), just let us know and we'll refund the previous month - no questions asked.",
    },
  ],
  bottomCta: {
    title: 'Ready to dive in?',
    subtitle:
      'Start your free 30-day trial today and see how easy it is to build and grow your business.',
    cta: {
      href: '#',
      text: 'Get Started',
    },
  },
  footer: {
    solutions: [
      { name: 'Hosting', href: '#' },
      { name: 'Data Services', href: '#' },
      { name: 'Uptime Monitoring', href: '#' },
      { name: 'Enterprise Services', href: '#' },
    ],
    support: [
      { name: 'Pricing', href: '#' },
      { name: 'Documentation', href: '#' },
      { name: 'Guides', href: '#' },
      { name: 'API Reference', href: '#' },
    ],
    company: [
      { name: 'About', href: '#' },
      { name: 'Blog', href: '#' },
      { name: 'Jobs', href: '#' },
      { name: 'Press', href: '#' },
      { name: 'Partners', href: '#' },
    ],
    legal: [
      { name: 'Privacy', href: '#' },
      { name: 'Terms', href: '#' },
    ],
  },
};

const benefitImages = [
  {
    name: 'Data Points',
    src: 'https://firebasestorage.googleapis.com/v0/b/m9y48fsa.appspot.com/o/public%2Fassets%2Fdata_points_illustration.svg?alt=media&token=22bbcc16-ae70-4840-bdbd-ec9d053537ba',
    alt: 'Two people adding analytic icons to a dashboard, illustration.',
  },
  {
    name: 'Team Collaboration',
    src: 'https://firebasestorage.googleapis.com/v0/b/m9y48fsa.appspot.com/o/public%2Fassets%2Fteam_collaboration_illustration.svg?alt=media&token=5ddf0929-42bc-4f6b-8ab2-363994e7d171',
    alt: 'Three people working together to draw up an app dashboard, illustration.',
  },
  {
    name: 'Team Up',
    src: 'https://firebasestorage.googleapis.com/v0/b/m9y48fsa.appspot.com/o/public%2Fassets%2Fteam_up_illustration.svg?alt=media&token=eeb19837-59e0-46fb-a6d1-30348414ff2b',
    alt: 'Two people sitting down and working together in two different apps, illustration.',
  },
];

interface Props {
  content?: any;
}

export default function GradientsLandingPage({ content }: Props) {
  content = content || dummyContent;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  function getImageForBenefitAtIndex(index: number) {
    return benefitImages[index % benefitImages.length].src;
  }

  return (
    <div className="bg-white overflow-y-auto">
      {/* Header */}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">{content.header?.logoText || ''}</span>

              {content.header?.logoImage && (
                <img
                  className="h-8 w-auto"
                  src={content.header?.logoImage}
                  alt={content.header?.logoText + ' Logo'}
                />
              )}

              {!content.header?.logoImage && (
                <span className="text-2xl font-bold text-gray-900">
                  {content.header?.logoText || 'Your Logo'}
                </span>
              )}
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {content.header?.navigation.map((item: any) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">{content.header?.logoText}</span>

                {content.header?.logoImage && (
                  <img
                    className="h-8 w-auto"
                    src={content.header?.logoImage}
                    alt={content.header?.logoText + ' Logo'}
                  />
                )}

                {!content.header?.logoImage && (
                  <span className="text-2xl font-bold text-gray-900">
                    {content.header?.logoText || 'Your Logo'}
                  </span>
                )}
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {content.header?.navigation.map((item: any) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="isolate">
        {/* Hero section */}
        <div className="relative pt-14">
          <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
            <svg
              className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#9b2541ea-d39d-499b-bd42-aeea3e93f5ff)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="9b2541ea-d39d-499b-bd42-aeea3e93f5ff"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  {content.hero?.title}
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {content.hero?.subtitle}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started
                  </a>
                  <a
                    href="#"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <div className="mt-16 max-w-3xl mx-auto flow-root sm:mt-32">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/m9y48fsa.appspot.com/o/public%2Fassets%2Fapp_example_screenshot.jpg?alt=media&token=86cab1f4-0665-4424-8b50-d02816b71ef6"
                    alt="App screenshot"
                    width={1422}
                    height={800}
                    className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <svg
              className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
            >
              <path
                fill="url(#b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>

        {/* Logo cloud */}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="flex justify-center">
            <p className="text-sm leading-8 text-gray-600">
              [Build trust with social proof: logos of press coverage or of your
              biggest customers]
            </p>
          </div>

          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div className="mt-10 flex justify-center">
            <p className="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
              <span className="hidden md:inline">
                Transistor saves up to $40,000 per year, per employee by working
                with us.
              </span>
              <a href="#" className="font-semibold text-indigo-600">
                <span className="absolute inset-0" aria-hidden="true" /> Read
                our case study <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>

        {/* Feature section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              Benefits
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {content.benefits?.name || content.benefits?.title}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {content.benefits?.description}
            </p>
          </div>

          {/* Alternating Feature Sections */}
          {content.benefits?.benefits?.length > 0 && (
            <div className="flex flex-col mx-auto max-w-7xl">
              {content.benefits?.benefits?.map(
                (benefit: any, index: number) => (
                  <div
                    key={benefit?.name}
                    className="flex flex-row mt-32 px-6 overflow-clip items-center gap-6"
                  >
                    {index % 2 !== 0 && (
                      <div className="w-1/2">
                        <img
                          src={getImageForBenefitAtIndex(index)}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                    )}

                    <div className="w-1/2">
                      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        {benefit?.name}
                      </h2>
                      <p className="mt-6 text-gray-600">
                        {benefit?.description}
                      </p>
                      <div className="mt-10 flex">
                        <a
                          href="#"
                          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Get Started <span aria-hidden="true">&rarr;</span>
                        </a>
                      </div>
                    </div>
                    {index % 2 === 0 && (
                      <div className="w-1/2">
                        <img
                          src={getImageForBenefitAtIndex(index)}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          )}

          {/* Column Benefits */}
          {/* <div className="mx-auto mt-20 max-w-7xl sm:mt-32 px-6">
            <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {content.benefits.benefits.slice(3, 6).map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div> */}
        </div>

        {/* Testimonial section */}
        <div className="mx-auto mt-32 max-w-7xl sm:px-6 lg:px-8">
          <div className="relative overflow-hidden bg-gray-900 py-20 px-6 shadow-xl sm:rounded-3xl sm:py-24 sm:px-10 md:px-12 lg:px-20">
            <img
              className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
              src="https://images.unsplash.com/photo-1601381718415-a05fb0a261f3?ixid=MXwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8ODl8fHxlbnwwfHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1216&q=80"
              alt=""
            />
            <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
            <svg
              viewBox="0 0 1097 845"
              aria-hidden="true"
              className="absolute -top-56 -left-80 w-[68.5625rem] transform-gpu blur-3xl"
            >
              <path
                fill="url(#68eb76c4-2bc9-4928-860e-70adf05719f4)"
                fillOpacity=".45"
                d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
              />
              <defs>
                <linearGradient
                  id="68eb76c4-2bc9-4928-860e-70adf05719f4"
                  x1="1097.04"
                  x2="-141.165"
                  y1=".22"
                  y2="363.075"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#776FFF" />
                  <stop offset={1} stopColor="#FF4694" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              viewBox="0 0 1097 845"
              aria-hidden="true"
              className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:w-[68.5625rem] md:transform-gpu md:blur-3xl"
            >
              <path
                fill="url(#68eb76c4-2bc9-4928-860e-70adf05719f4)"
                fillOpacity=".25"
                d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
              />
            </svg>
            <div className="relative mx-auto max-w-2xl lg:mx-0">
              {/* <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/workcation-logo-white.svg"
                alt=""
              /> */}
              <figure>
                <blockquote className="text-lg font-semibold text-white sm:text-xl sm:leading-8">
                  <p>"{content.testimonials?.testimonials?.[0]?.quote}"</p>
                </blockquote>
                <figcaption className="mt-6 text-base text-white">
                  <div className="font-semibold">
                    {content.testimonials?.testimonials?.[0]?.name}
                  </div>
                  <div className="mt-1">
                    {content.testimonials?.testimonials?.[0]?.role}
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>

        {/* Pricing section */}
        <div className="py-24 sm:pt-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Pricing
              </h2>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Pricing plans for teams of&nbsp;all&nbsp;sizes
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              Big or small, we've got you covered.
            </p>
            <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {content.tiers?.map((tier: any, tierIdx: number) => (
                <div
                  key={tier?.id}
                  className={twMerge(
                    tier?.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                    tierIdx === 0 ? 'lg:rounded-r-none' : '',
                    tierIdx === content.tiers?.length - 1
                      ? 'lg:rounded-l-none'
                      : '',
                    'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
                  )}
                >
                  <div>
                    <div className="flex items-center justify-between gap-x-4">
                      <h3
                        id={tier?.id}
                        className={twMerge(
                          tier.mostPopular
                            ? 'text-indigo-600'
                            : 'text-gray-900',
                          'text-lg font-semibold leading-8'
                        )}
                      >
                        {tier?.name}
                      </h3>
                      {tier?.mostPopular ? (
                        <p className="rounded-full bg-indigo-600/10 py-1 px-2.5 text-xs font-semibold leading-5 text-indigo-600">
                          Most popular
                        </p>
                      ) : null}
                    </div>
                    <p className="mt-4 text-sm leading-6 text-gray-600">
                      {tier?.description}
                    </p>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        {tier?.priceMonthly}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        /month
                      </span>
                    </p>
                    <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                      {tier?.features?.map((feature: any) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    href={tier?.href}
                    aria-describedby={tier?.id}
                    className={twMerge(
                      tier?.mostPopular
                        ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                      'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    )}
                  >
                    Start Free Trial
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* FAQs */}
        {content.faqs && (
          <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pt-12 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pb-32">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
              {content.faqs?.map((faq: any) => (
                <div
                  key={faq?.id}
                  className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
                >
                  <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                    {faq?.question}
                  </dt>
                  <dd className="mt-4 lg:col-span-7 lg:mt-0">
                    <p className="text-base leading-7 text-gray-600">
                      {faq?.answer}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        )}

        {/* CTA section */}
        <div className="relative -z-10 mt-32 px-6 lg:px-8">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:right-[calc(50%-6rem)] sm:top-auto sm:bottom-0 sm:translate-y-0 sm:transform-gpu sm:justify-end">
            <svg
              viewBox="0 0 1108 632"
              aria-hidden="true"
              className="w-[69.25rem] flex-none"
            >
              <path
                fill="url(#191ef669-4d29-44ea-9496-5d65eb27150d)"
                fillOpacity=".25"
                d="M235.233 229.055 57.541 310.091.83.615l234.404 228.44L555.251 83.11c-65.036 115.261-134.286 322.756 109.01 230.655C968.382 198.638 1031-19.583 1092.23 172.304c48.98 153.51-34.51 321.107-82.37 385.717L810.952 307.442 648.261 631.576 235.233 229.055Z"
              />
              <defs>
                <linearGradient
                  id="191ef669-4d29-44ea-9496-5d65eb27150d"
                  x1="1220.59"
                  x2="-85.053"
                  y1="198.898"
                  y2="-7.05"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {content.bottomCta?.title}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {content.bottomCta?.subtitle}
            </p>
            <div className="mt-10 flex flex-col items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {content.bottomCta?.cta?.text}
              </a>
              <p className="mt-2 text-sm leading-8 text-gray-600">
                No Card Needed.
              </p>
            </div>
          </div>
          <div className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block">
            <svg
              viewBox="0 0 1155 678"
              aria-hidden="true"
              className="w-[72.1875rem]"
            >
              <path
                fill="url(#23e1b96e-c791-45fa-975a-a04d29498207)"
                fillOpacity=".3"
                d="M317.219 518.975 203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079Z"
              />
              <defs>
                <linearGradient
                  id="23e1b96e-c791-45fa-975a-a04d29498207"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </main>

      {/* Footer */}
      <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
        <footer
          aria-labelledby="footer-heading"
          className="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
        >
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <img
              className="h-7"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Company name"
            />
            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Solutions
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {content.footer?.solutions?.map((item: any) => (
                      <li key={item?.name}>
                        <a
                          href={item?.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Support
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {content.footer?.support?.map((item: any) => (
                      <li key={item?.name}>
                        <a
                          href={item?.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Company
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {content.footer?.company?.map((item: any) => (
                      <li key={item?.name}>
                        <a
                          href={item?.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    Legal
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {content.footer?.legal?.map((item: any) => (
                      <li key={item?.name}>
                        <a
                          href={item?.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
