import OnboardingChecklist from '../../components/Onboarding/OnboardingChecklist';
import React from 'react';

export default function Dashboard() {
  return (
    <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 space-y-4">
      <div className="flex">
        <div className="m-auto w-full">
          <OnboardingChecklist type={'page'} />
        </div>
      </div>
    </div>
  );
}
