import { Benefit } from '../../models/BenefitModel';
import { BenefitCard } from './BenefitCard';
import { Feature } from '../../models/FeatureModel';
import React from 'react';

interface Props {
  benefits: Benefit[];
  features: Feature[];
}

export default function StreamingBenefits({ benefits, features }: Props) {
  function getRelatedFeatures(benefit: Benefit) {
    const relatedFeatureIds = benefit.relatedFeatures.map((eachRef) => {
      return eachRef.id;
    });

    return features?.filter((eachFeature) =>
      relatedFeatureIds.includes(eachFeature.ref.id)
    );
  }

  return (
    <div className="flex flex-col max-w-4xl mx-auto">
      {(!benefits || benefits?.length === 0) && (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No benefits had been generated yet.
          </h3>
        </div>
      )}

      {benefits && benefits.length > 0 && (
        <ul className="grid grid-cols-1 gap-6">
          {benefits &&
            benefits.map((eachBenefit) => (
              <BenefitCard
                key={
                  eachBenefit.id +
                  `${
                    eachBenefit.title.length + eachBenefit.description.length
                  }}`
                }
                benefit={eachBenefit}
                realatedFeatures={getRelatedFeatures(eachBenefit) || []}
                onClick={() => {}}
              />
            ))}
        </ul>
      )}
    </div>
  );
}
