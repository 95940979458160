import {
  DataCollectionBuildingBlock,
  DataCollectionBuildingBlockType,
} from './DataCollectionBuildingBlockModel';
import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import { Language } from './LanguageModel';

//
// Document
//

export enum DocumentType {
  Blank = 'blank',
  Generic = 'generic',
  LinkedInPost = 'linkedInPost',
  TwitterPost = 'twitterPost',
  TwitterThread = 'twitterThread',
  FacebookPost = 'facebookPost',
  InstagramPostCaption = 'instagramPostCaption',
  PressRelease = 'pressRelease',
  SalesEmail = 'salesEmail',
}

export enum DocumentGenerationComponentType {
  Title = 'title',
  Content = 'content',
  ToneOfVoice = 'toneOfVoice',
}

export type Document = {
  id: string;
  ref: DocumentReference<DocumentData>;
  type: DocumentType;
  title: string;
  content: string;
  originalPrompt: any;
  language: string;
  createdAt: Date;
};

export const documentConverter: FirestoreDataConverter<Document> = {
  toFirestore(document: WithFieldValue<Document>): DocumentData {
    return {
      content: document.content,
      createdAt: document.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Document {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      type: data.type,
      title: data.title,
      content: data.content,
      originalPrompt: data.originalPrompt,
      language: data.language,
      createdAt: data.createdAt,
    };
  },
};

export function documentFromFirestore(document: any): Document {
  return {
    id: document.id,
    ref: document.ref,
    type: document.type,
    title: document.title,
    content: document.content,
    originalPrompt: document.originalPrompt,
    language: document.language,
    createdAt: document.createdAt,
  };
}

export function documentFromStreamingContent(partialDocument: any): Document {
  return {
    id: partialDocument.id || '',
    ref: partialDocument.ref || '',
    type: partialDocument.type || DocumentType.Generic,
    title: partialDocument.title || '',
    content: partialDocument.content || 'Writing in progress...',
    originalPrompt: partialDocument.originalPrompt || '',
    language: partialDocument.language || Language.EnglishUS,
    createdAt: partialDocument.createdAt || new Date(),
  };
}

export function getTitleFromDocumentType(type: DocumentType): string {
  switch (type) {
    case DocumentType.Blank:
      return 'Blank Document';
    case DocumentType.Generic:
      return 'Generic Content';
    case DocumentType.LinkedInPost:
      return 'LinkedIn Post';
    case DocumentType.TwitterPost:
      return 'Twitter Post';
    case DocumentType.TwitterThread:
      return 'Twitter Thread';
    case DocumentType.FacebookPost:
      return 'Facebook Post';
    case DocumentType.InstagramPostCaption:
      return 'Instagram Post Caption';
    case DocumentType.PressRelease:
      return 'Press Release';
    default:
      return 'Document';
  }
}

export function getDescriptionFromDocumentType(type: DocumentType): string {
  switch (type) {
    case DocumentType.Blank:
      return 'A blank document.';
    case DocumentType.Generic:
      return 'Anything, really.';
    case DocumentType.LinkedInPost:
      return 'A LinkedIn post.';
    case DocumentType.TwitterPost:
      return 'Twitter Post, 280 characters or less.';
    case DocumentType.TwitterThread:
      return 'A Twitter Thread';
    case DocumentType.FacebookPost:
      return 'A Facebook Post';
    case DocumentType.InstagramPostCaption:
      return 'Instagram Post Caption';
    case DocumentType.PressRelease:
      return 'Press Release';
    default:
      return 'A Generic Document';
  }
}

export function getStructureTemplateFromDocumentType(
  type: DocumentType
): DataCollectionBuildingBlock[] {
  switch (type) {
    case DocumentType.Blank:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
      ];

    case DocumentType.LinkedInPost:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A LinkedIn post about generative AI for marketing.',
          initialValue:
            'Topic: [YOUR TOPIC HERE]\n\n' +
            'Talking points:\n' +
            '- [POINT 1]\n' +
            '- [POINT 2]\n' +
            '- [POINT 3]\n',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];

    case DocumentType.TwitterPost:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A LinkedIn post about generative AI for marketing.',
          initialValue:
            'Topic: [YOUR TOPIC HERE]\n\nMax length: 280 characters.',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];

    case DocumentType.TwitterThread:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A LinkedIn post about generative AI for marketing.',
          initialValue:
            'Topic: [YOUR TOPIC HERE]\n\n' +
            'Number of tweets: [NUMBER]\n' +
            'Talking points:\n' +
            '- [POINT 1]\n' +
            '- [POINT 2]\n' +
            '- [POINT 3]\n',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];

    case DocumentType.FacebookPost:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI Facebook Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A Facebook post about generative AI for marketing.',
          initialValue: 'Topic: [YOUR TOPIC HERE]\n\nMax length: 3 paragraphs.',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];

    case DocumentType.InstagramPostCaption:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A LinkedIn post about generative AI for marketing.',
          initialValue: 'Topic: [YOUR TOPIC HERE]\n\n',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];

    case DocumentType.PressRelease:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A LinkedIn post about generative AI for marketing.',
          initialValue:
            'Topic: [YOUR TOPIC HERE]\n\n' +
            'Company details:\n' +
            'Date:\n' +
            'Location:\n' +
            'Talking points:\n' +
            '- [POINT 1]\n' +
            '- [POINT 2]\n' +
            '- [POINT 3]\n',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];

    default:
      return [
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'documentTitle',
          title: 'Title',
          placeholder: 'e.g. Gen-AI LinkedIn Post',
        },
        {
          type: DataCollectionBuildingBlockType.MultiLineTextInput,
          payloadPath: 'description',
          title: 'What do you need',
          placeholder:
            'e.g. A LinkedIn post about generative AI for marketing.',
        },
        {
          type: DataCollectionBuildingBlockType.SingleLineTextInput,
          payloadPath: 'toneOfVoice',
          title: 'Tone of Voice',
          placeholder: 'e.g. Authorative, Casual, Funny, etc.',
        },
      ];
  }
}
