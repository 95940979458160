import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';

import { RadioGroup } from '@headlessui/react';
import { selectPlan } from '../../../components/firebase';
import { selectTeam } from '../../../app/teamSlice';
import { twMerge } from 'tailwind-merge';
import { useAppSelector } from '../../../app/hooks';
import { useState } from 'react';

const STARTER_PLAN_ID = 'STARTER';
const GROWTH_MONTHLY_PLAN_ID = 'GROWTH_MONTHLY';
const GROWTH_YEARLY_PLAN_ID = 'GROWTH_YEARLY';

const frequencies = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' },
];
const tiers: any = [
  {
    name: 'Starter',
    id: 'tier-starter',
    featured: false,
    buttonTitle: 'Select Starter',
    description: 'All you need to get started with small projects.',
    price: { monthly: 'Free', yearly: 'Free' },
    mainFeatures: [
      '1,000 words / month',
      'AI-powered writing assistant & Editor',
      'Customer Personas',
    ],
  },
  {
    name: 'Growth',
    id: 'tier-growth',
    featured: true,
    buttonTitle: 'Subscribe to Growth',
    description: 'The most popular plan helping you grow your business.',
    price: { monthly: '$49', yearly: '$490' },
    mainFeatures: [
      '50,000 words / month',
      '3 Seats',
      'AI-Powered Marketing Strategy',
      'Full AI-Content Generation + Templates',
      'Access to All Features',
    ],
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    featured: false,
    buttonTitle: 'Contact Us',
    description: 'Perfect for large businesses and agencies.',
    price: { monthly: '$1k+', yearly: '$12k+' },
    mainFeatures: [
      'Personalized AI Brand Voice',
      'Dedicated Account Manager',
      'Personalized Onboarding & Training',
      'Premium Support',
    ],
  },
];
const sections: any = [
  {
    name: 'Team',
    features: [
      {
        name: 'Invite your team',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'Seats',
        tiers: {
          Starter: 'Only for you',
          Growth: '3 seats included',
          Enterprise: '3+ seats',
        },
      },
    ],
  },
  {
    name: 'AI Chat',
    features: [
      {
        name: 'AI Chat',
        tiers: { Starter: true, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Brainstorming',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
    ],
  },
  {
    name: 'AI Goodness',
    features: [
      {
        name: 'AI Generated Content',
        tiers: {
          Starter: '1,000 words / month',
          Growth: '50,000 words / month',
          Enterprise: 'Unlimited words / month',
        },
      },
      {
        name: 'AI Generated Customer Profiles',
        tiers: { Starter: true, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Generated Ideal Customer Profiles',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Generated Features to Benefits',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Generated Marketing Channels',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'Best Available AI Document Editor',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Generated Website Content',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Generated Blog Content (coming soon)',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'AI Generated Social Media Content',
        tiers: { Starter: false, Growth: true, Enterprise: true },
      },
      {
        name: 'Personalized AI Brand Voice',
        tiers: { Starter: false, Growth: false, Enterprise: true },
      },
    ],
  },
  {
    name: 'Other perks',
    features: [
      {
        name: 'Personalized Onboarding and Training',
        tiers: { Starter: false, Growth: false, Enterprise: true },
      },
      {
        name: 'Dedicated Account Manager',
        tiers: { Starter: false, Growth: false, Enterprise: true },
      },
      {
        name: 'Customer support',
        tiers: {
          Starter: 'Community',
          Growth: 'Email',
          Enterprise: 'Priority Support',
        },
      },
    ],
  },
];

export default function Pricing() {
  const team = useAppSelector(selectTeam);

  const [frequency, setFrequency] = useState(frequencies[0]);
  const [isPurchasingPlan, setIsPurchasingPlan] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const isCurrentPlan = (tierId: string) => {
    if (team?.subscriptionPlan) {
      switch (tierId) {
        case 'tier-starter':
          return team.subscriptionPlan === STARTER_PLAN_ID;
        case 'tier-growth':
          return (
            team.subscriptionPlan === GROWTH_MONTHLY_PLAN_ID ||
            team.subscriptionPlan === GROWTH_YEARLY_PLAN_ID
          );
        default:
          return false;
      }
    }
    return false;
  };

  const buttonTitle = (tierId: string) => {
    if (isCurrentPlan(tierId)) {
      return 'Current Plan';
    }

    if (isPurchasingPlan === tierId) {
      return 'Working...';
    }

    switch (tierId) {
      case 'tier-starter':
        return 'Select Starter';
      case 'tier-growth':
        return `Subscribe to Growth (${frequency.label})`;
      default:
        return 'Contact Us';
    }
  };

  async function handleSubscribe(tierId: string, frequency: string) {
    setIsPurchasingPlan(tierId);
    try {
      let response: any;
      switch (tierId) {
        case 'tier-starter':
          response = await selectPlan(STARTER_PLAN_ID);
          navigate(response.redirectUrl);
          break;
        case 'tier-growth':
          switch (frequency) {
            case 'monthly':
              response = await selectPlan(GROWTH_MONTHLY_PLAN_ID);
              window.location.href = response.redirectUrl;
              break;
            case 'yearly':
              response = await selectPlan(GROWTH_YEARLY_PLAN_ID);
              window.location.href = response.redirectUrl;
              break;
            default:
              break;
          }
          break;
        case 'tier-enterprise':
          window.location.href =
            'mailto:hello@marketology.ai?subject=Interested in the Enterprise plan';
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error purchasing selecting plan:', error);
    } finally {
      setIsPurchasingPlan(undefined);
    }
  }

  return (
    <div className="isolate overflow-hidden rounded-2xl">
      <div className="flow-root bg-gray-900 pt-24 pb-16 sm:pt-32 lg:pb-0">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="relative z-10">
            <h2 className="mx-auto max-w-4xl text-center text-5xl font-bold tracking-tight text-white">
              Simple pricing. Focus on growth.
            </h2>
            <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-white/60">
              Grow your business with AI-powered marketing.
            </p>
            <p className="mx-auto mt-0 max-w-2xl text-center text-lg leading-8 text-white/60">
              Create Customer Personas and the perfect content for them, in
              seconds.
            </p>
            <div className="mt-16 flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
              >
                <RadioGroup.Label className="sr-only">
                  Payment frequency
                </RadioGroup.Label>
                {frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      twMerge(
                        checked ? 'bg-indigo-500' : '',
                        'cursor-pointer rounded-full py-1 px-2.5'
                      )
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
            <svg
              viewBox="0 0 1208 1024"
              aria-hidden="true"
              className="absolute left-1/2 -bottom-48 h-[64rem] translate-y-1/2 -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:bottom-auto lg:-top-48 lg:translate-y-0"
            >
              <ellipse
                cx={604}
                cy={512}
                fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
                rx={604}
                ry={512}
              />
              <defs>
                <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
            <div
              className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
              aria-hidden="true"
            />
            {tiers.map((tier: any) => (
              <div
                key={tier.id}
                className={twMerge(
                  'ring-1',
                  tier.featured
                    ? 'z-10 bg-white shadow-xl ring-gray-900/10'
                    : 'bg-gray-800/80 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0',

                  'relative rounded-2xl'
                )}
              >
                <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                  <h3
                    id={tier.id}
                    className={twMerge(
                      tier.featured ? 'text-gray-900' : 'text-white',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    <div className="relative flex flex-row justify-between">
                      {tier.name}
                      {isCurrentPlan(tier.id) && (
                        <CheckCircleIcon className="absolute right-0 top-0 w-8 h-8 text-green-500" />
                      )}
                    </div>
                  </h3>
                  <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                    <div className="mt-2 flex items-center gap-x-4">
                      <p
                        className={twMerge(
                          tier.featured ? 'text-gray-900' : 'text-white',
                          'text-4xl font-bold tracking-tight'
                        )}
                      >
                        {tier.price[frequency.value]}
                      </p>

                      {tier.price[frequency.value] !== 'Free' && (
                        <div className="text-sm leading-5">
                          <p
                            className={
                              tier.featured ? 'text-gray-900' : 'text-white'
                            }
                          >
                            USD
                          </p>
                          <p
                            className={
                              tier.featured ? 'text-gray-500' : 'text-gray-400'
                            }
                          >{`Billed ${frequency.value}`}</p>
                        </div>
                      )}
                    </div>
                    <button
                      aria-describedby={tier.id}
                      className={twMerge(
                        tier.featured
                          ? 'bg-indigo-600 shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600'
                          : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                        'rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                      )}
                      disabled={
                        isPurchasingPlan !== undefined || isCurrentPlan(tier.id)
                      }
                      onClick={async () => {
                        await handleSubscribe(tier.id, frequency.value);
                      }}
                    >
                      <div className="flex flex-row justify-center">
                        {buttonTitle(tier.id)}
                        {/* {isCurrentPlan(tier.id) && (
                          <CheckCircleIcon className="w-5 h-5 ml-2 mt-0.5 text-green-400/80" />
                        )} */}
                      </div>
                    </button>
                  </div>
                  <div className="mt-8 flow-root sm:mt-10">
                    <ul
                      className={twMerge(
                        tier.featured
                          ? 'divide-gray-900/5 border-gray-900/5 text-gray-600'
                          : 'divide-white/5 border-white/5 text-white',
                        '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                      )}
                    >
                      {tier.mainFeatures.map((mainFeature: any) => (
                        <li key={mainFeature} className="flex gap-x-3 py-2">
                          <CheckIcon
                            className={twMerge(
                              tier.featured
                                ? 'text-green-600'
                                : 'text-gray-500',
                              'h-6 w-5 flex-none'
                            )}
                            aria-hidden="true"
                          />
                          {mainFeature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative bg-gray-50 lg:pt-14">
        <div className="mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
          {/* Feature comparison (up to lg) */}
          <section
            aria-labelledby="mobile-comparison-heading"
            className="lg:hidden"
          >
            <h2 id="mobile-comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="mx-auto max-w-2xl space-y-16">
              {tiers.map((tier: any) => (
                <div key={tier.id} className="border-t border-gray-900/10">
                  <div
                    className={twMerge(
                      tier.featured
                        ? 'border-indigo-600'
                        : 'border-transparent',
                      '-mt-px w-72 border-t-2 pt-10 md:w-80'
                    )}
                  >
                    <h3
                      className={twMerge(
                        tier.featured ? 'text-indigo-600' : 'text-gray-900',
                        'text-sm font-semibold leading-6'
                      )}
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                  </div>

                  <div className="mt-10 space-y-10">
                    {sections.map((section: any) => (
                      <div key={section.name}>
                        <h4 className="text-sm font-semibold leading-6 text-gray-900">
                          {section.name}
                        </h4>
                        <div className="relative mt-6">
                          {/* Fake card background */}
                          <div
                            aria-hidden="true"
                            className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                          />

                          <div
                            className={twMerge(
                              tier.featured
                                ? 'ring-2 ring-indigo-600'
                                : 'ring-1 ring-gray-900/10',
                              'relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'
                            )}
                          >
                            <dl className="divide-y divide-gray-200 text-sm leading-6">
                              {section.features.map((feature: any) => (
                                <div
                                  key={feature.name}
                                  className="flex items-center justify-between py-3 px-4 sm:grid sm:grid-cols-2 sm:px-0"
                                >
                                  <dt className="pr-4 text-gray-600">
                                    {feature.name}
                                  </dt>
                                  <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                    {typeof feature.tiers[tier.name] ===
                                    'string' ? (
                                      <span
                                        className={
                                          tier.featured
                                            ? 'font-semibold text-indigo-600'
                                            : 'text-gray-900'
                                        }
                                      >
                                        {feature.tiers[tier.name]}
                                      </span>
                                    ) : (
                                      <>
                                        {feature.tiers[tier.name] === true ? (
                                          <CheckIcon
                                            className="mx-auto h-5 w-5 text-green-600"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <XMarkIcon
                                            className="mx-auto h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        )}

                                        <span className="sr-only">
                                          {feature.tiers[tier.name] === true
                                            ? 'Yes'
                                            : 'No'}
                                        </span>
                                      </>
                                    )}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>

                          {/* Fake card border */}
                          <div
                            aria-hidden="true"
                            className={twMerge(
                              tier.featured
                                ? 'ring-2 ring-indigo-600'
                                : 'ring-1 ring-gray-900/10',
                              'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Feature comparison (lg+) */}
          <section
            aria-labelledby="comparison-heading"
            className="hidden lg:block"
          >
            <h2 id="comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="grid grid-cols-4 gap-x-8 border-t border-gray-900/10 before:block">
              {tiers.map((tier: any) => (
                <div key={tier.id} aria-hidden="true" className="-mt-px">
                  <div
                    className={twMerge(
                      tier.featured
                        ? 'border-indigo-600'
                        : 'border-transparent',
                      'border-t-2 pt-10'
                    )}
                  >
                    <p
                      className={twMerge(
                        tier.featured ? 'text-indigo-600' : 'text-gray-900',
                        'text-sm font-semibold leading-6'
                      )}
                    >
                      {tier.name}
                    </p>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="-mt-6 space-y-16">
              {sections.map((section: any) => (
                <div key={section.name}>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {section.name}
                  </h3>
                  <div className="relative -mx-8 mt-10">
                    {/* Fake card backgrounds */}
                    <div
                      className="absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                    </div>

                    <table className="relative w-full border-separate border-spacing-x-8">
                      <thead>
                        <tr className="text-left">
                          <th scope="col">
                            <span className="sr-only">Feature</span>
                          </th>
                          {tiers.map((tier: any) => (
                            <th key={tier.id} scope="col">
                              <span className="sr-only">{tier.name} tier</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {section.features.map(
                          (feature: any, featureIdx: number) => (
                            <tr key={feature.name}>
                              <th
                                scope="row"
                                className="w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900"
                              >
                                {feature.name}
                                {featureIdx !== section.features.length - 1 ? (
                                  <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                                ) : null}
                              </th>
                              {tiers.map((tier: any) => (
                                <td
                                  key={tier.id}
                                  className="relative w-1/4 px-4 py-0 text-center"
                                >
                                  <span className="relative h-full w-full py-3">
                                    {typeof feature.tiers[tier.name] ===
                                    'string' ? (
                                      <span
                                        className={twMerge(
                                          tier.featured
                                            ? 'font-semibold text-indigo-600'
                                            : 'text-gray-900',
                                          'text-sm leading-6'
                                        )}
                                      >
                                        {feature.tiers[tier.name]}
                                      </span>
                                    ) : (
                                      <>
                                        {feature.tiers[tier.name] === true ? (
                                          <CheckIcon
                                            className="mx-auto h-5 w-5 text-green-600"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <XMarkIcon
                                            className="mx-auto h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        )}

                                        <span className="sr-only">
                                          {feature.tiers[tier.name] === true
                                            ? 'Yes'
                                            : 'No'}
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </td>
                              ))}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>

                    {/* Fake card borders */}
                    <div
                      className="pointer-events-none absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      {tiers.map((tier: any) => (
                        <div
                          key={tier.id}
                          className={twMerge(
                            tier.featured
                              ? 'ring-2 ring-indigo-600'
                              : 'ring-1 ring-gray-900/10',
                            'rounded-lg'
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
