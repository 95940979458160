import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

//
// ChannelPersonaLink
//

type ChannelPersonaLink = {
  id: string;
  ref: DocumentReference<DocumentData>;
  owner: DocumentReference<DocumentData>;
  marketingChannel: DocumentReference<DocumentData>;
  persona: DocumentReference<DocumentData>;
  confidenceRelevanceScore: number;
  targetingCriteria: string[];
  channelPersonaTips: string[];
  createdAt: Date;
};

const channelPersonaLinkConverter: FirestoreDataConverter<ChannelPersonaLink> =
  {
    toFirestore(
      channelPersonaLink: WithFieldValue<ChannelPersonaLink>
    ): DocumentData {
      return {
        owner: channelPersonaLink.owner,
        marketingChannel: channelPersonaLink.marketingChannel,
        persona: channelPersonaLink.persona,
        confidenceRelevanceScore: channelPersonaLink.confidenceRelevanceScore,
        targetingCriteria: channelPersonaLink.targetingCriteria,
        channelPersonaTips: channelPersonaLink.channelPersonaTips,
        createdAt: channelPersonaLink.createdAt,
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): ChannelPersonaLink {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        ref: snapshot.ref,
        owner: data.owner,
        marketingChannel: data.marketingChannel,
        persona: data.persona,
        confidenceRelevanceScore: data.confidenceRelevanceScore,
        targetingCriteria: data.targetingCriteria,
        channelPersonaTips: data.channelPersonaTips,
        createdAt: data.createdAt,
      };
    },
  };

function channelPersonaLinkFromFirestore(document: any): ChannelPersonaLink {
  return {
    id: document.id,
    ref: document.ref,
    owner: document.owner,
    marketingChannel: document.marketingChannel,
    persona: document.persona,
    confidenceRelevanceScore: document.confidenceRelevanceScore,
    targetingCriteria: document.targetingCriteria,
    channelPersonaTips: document.channelPersonaTips,
    createdAt: document.createdAt,
  };
}

export type { ChannelPersonaLink };
export { channelPersonaLinkConverter, channelPersonaLinkFromFirestore };
