import {
  DocumentData,
  DocumentReference,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';

import { OnboardingChecklistStepType } from '../models/OnboardingChecklistStepModel';
import { Project } from '../models/ProjectModel';
import { User } from '../models/UserModel';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { store } from '../app/store';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBZSGrPeknHqyNB0_z2rvRvhEfDMB9ypck',
  authDomain: 'm9y48fsa.firebaseapp.com',
  projectId: 'm9y48fsa',
  storageBucket: 'm9y48fsa.appspot.com',
  messagingSenderId: '204657041611',
  appId: '1:204657041611:web:86bb19461beea737c2bb4e',
  measurementId: 'G-HNJ0LSP75J',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app, 'europe-west1');
const db = getFirestore(app);
const analytics = getAnalytics(app);

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

window.FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN || false;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''
  ),
  isTokenAutoRefreshEnabled: true,
});

//
// Auth
//

// onAuthStateChanged(auth, (user) => {
// console.log('onAuthStateChanged user:', user);
// store.dispatch(setUser(user?.toJSON()));
// if (user) {
//   // User is signed in, see docs for a list of available properties
//   // https://firebase.google.com/docs/reference/js/firebase.User
//   const uid = user.uid;
//   // ...
// } else {
//   // User is signed out
//   // ...
// }
// });

async function sendSignInEmail(email: string) {
  try {
    const cloudFunction = httpsCallable(functions, 'sendSignInEmail');
    await cloudFunction({ email });
  } catch (error: any) {
    console.error('Error while sending sign in email.', error);
    alert(error.message);
  }
}

async function verifyOtp(otp: string, email: string) {
  try {
    const cloudFunction = httpsCallable(functions, 'verifyOTP');
    const result: HttpsCallableResult<any> = await cloudFunction({
      email,
      otp,
    });
    await logInWithToken(result.data.token);
  } catch (error: any) {
    console.error('Error while sending sign in email.', error);
    alert(error.message);
  }
}

async function logInWithToken(token: string) {
  try {
    await signInWithCustomToken(auth, token);
  } catch (error: any) {
    console.error('Error while signing in with token.', error.message);
    alert(error.message);
  }
}

async function getIdToken() {
  try {
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
  } catch (error: any) {
    console.error('Error while getting id token.', error.message);
    alert(error.message);
  }
}

async function logOut() {
  try {
    await signOut(auth);
    Intercom('shutdown');
  } catch (error: any) {
    console.error('Error while signing out.', error.message);
    alert(error.message);
  }
}

export {
  auth,
  db,
  doc,
  getDoc,
  query,
  where,
  collection,
  useCollectionData,
  sendSignInEmail,
  verifyOtp,
  getIdToken,
  logOut,
};

export type { DocumentReference };

//
// Plans
//

async function selectPlan(selectedPlanId: string) {
  try {
    const cloudFunction = httpsCallable(functions, 'selectPlan');
    const response = await cloudFunction({
      teamId: store.getState().team.currentlySelectedTeam?.id,
      selectedPlanId,
      stripeSuccessRedirectUrl: window.location.origin + '/settings/billing',
      stripeCancelRedirectUrl: window.location.origin + '/settings/billing',
      contactEmail: auth.currentUser?.email,
    });
    return response.data;
  } catch (error: any) {
    console.error('Error while selecting your plan.', error);
    alert(error.message);
  }
}

export { selectPlan };

//
// Firestore Database
//

async function updateDocument(
  docRef: DocumentReference<DocumentData>,
  data: any
) {
  try {
    await updateDoc(docRef, data);
  } catch (error: any) {
    console.error('Error updating document.', error.message);
    alert(error.message);
  }
}

async function addDocument(collectionRef: any, data: { [key: string]: any }) {
  try {
    const docRef: DocumentReference<DocumentData> = await addDoc(
      collectionRef,
      {
        ...data,
        owner: doc(db, 'users', auth.currentUser?.uid || ''),
        createdAt: serverTimestamp(),
      }
    );

    const document = await getDoc(docRef);
    return { ...document.data(), id: document.id, ref: document.ref };
  } catch (error: any) {
    console.error('Error adding document.', error.message);
    alert(error.message);
  }
}

async function deleteDocument(docRef: DocumentReference<DocumentData>) {
  try {
    await deleteDoc(docRef);
  } catch (error: any) {
    console.error('Error deleting document.', error.message);
    alert(error.message);
  }
}

export type { Timestamp };
export { addDocument, updateDocument, deleteDocument };

//
// Content Generation
//

async function createTopBenefits(payload: any) {
  try {
    const cloudFunction = httpsCallable(functions, 'createTopBenefits', {
      timeout: 540 * 1000,
    });
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating top benefits.', error);
    throw error;
  }
}

async function createTopIdealCustomerProfiles(payload: any) {
  try {
    const cloudFunction = httpsCallable(
      functions,
      'createTopIdealCustomerProfiles',
      {
        timeout: 540 * 1000,
      }
    );
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating top ideal customer profiles.', error);
    throw error;
  }
}

async function createNewIdealCustomerProfile(payload: any) {
  try {
    const cloudFunction = httpsCallable(
      functions,
      'createIdealCustomerProfile',
      {
        timeout: 540 * 1000,
      }
    );
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating ideal customer profile.', error);
    throw error;
  }
}

async function createTopCustomerPersonas(payload: any) {
  try {
    const cloudFunction = httpsCallable(
      functions,
      'createTopCustomerPersonas',
      {
        timeout: 540 * 1000,
      }
    );
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating top customer personas .', error);
    throw error;
  }
}

async function createNewCustomerPersona(payload: any) {
  try {
    const cloudFunction = httpsCallable(functions, 'createCustomerPersona', {
      timeout: 540 * 1000,
    });
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating customer persona.', error);
    throw error;
  }
}

async function createTopMarketingChannels(payload: any) {
  try {
    const cloudFunction = httpsCallable(
      functions,
      'createTopMarketingChannels',
      {
        timeout: 540 * 1000,
      }
    );
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating top marketing channels.', error);
    throw error;
  }
}

async function createNewMarketingChannel(payload: any) {
  try {
    const cloudFunction = httpsCallable(functions, 'createMarketingChannel', {
      timeout: 540 * 1000,
    });
    await cloudFunction(payload);
  } catch (error: any) {
    console.error('Error while creating marketing channel.', error);
    throw error;
  }
}

async function createNewWebsitePage(payload: any) {
  try {
    const cloudFunction = httpsCallable(functions, 'createNewWebsitePage', {
      timeout: 540 * 1000,
    });
    const result = await cloudFunction(payload);
    return result.data;
  } catch (error: any) {
    console.error('Error while creating website page.', error);
    throw error;
  }
}

export {
  createTopBenefits,
  createTopIdealCustomerProfiles,
  createNewIdealCustomerProfile,
  createTopCustomerPersonas,
  createNewCustomerPersona,
  createTopMarketingChannels,
  createNewMarketingChannel,
  createNewWebsitePage,
};

//
// AI Assistant
//

async function getAssistantResponse(
  teamId: string,
  projectId: string,
  latestUserMessage: string,
  chatContext: any,
  chatSessionId?: string
) {
  try {
    const cloudFunction = httpsCallable(functions, 'getAssistantResponse');
    const response: any = await cloudFunction({
      teamId,
      projectId,
      chatSessionId,
      latestUserMessage,
      chatContext: null,
    });
    return response.data.chatSession;
  } catch (error: any) {
    console.error('Error while getting assistant response.', error);
    throw error;
  }
}

export { getAssistantResponse };

//
// Onboarding
//

export async function updateUserCompletedChecklistStep(
  step: OnboardingChecklistStepType,
  user: User
) {
  if (user.onboardingChecklist?.[step]) {
    return;
  }

  try {
    let properties: any = {};
    properties['onboardingChecklist'] = {
      ...user.onboardingChecklist,
      [step]: true,
    };
    await updateDoc(user.ref, properties);
  } catch (error: any) {
    console.error(
      'Error updating completed checklist step for user.',
      error.message
    );
  }
}

export async function updateProjectCompletedChecklistStep(
  step: OnboardingChecklistStepType,
  project: Project,
  state: boolean = true
) {
  if (project.onboardingChecklist?.[step] === state) {
    return;
  }

  try {
    let properties: any = {};
    properties['onboardingChecklist'] = {
      ...project.onboardingChecklist,
      [step]: state,
    };
    await updateDoc(project.ref, properties);
  } catch (error: any) {
    console.error(
      'Error updating completed checklist step for project.',
      error.message
    );
  }
}
