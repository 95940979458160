import { db, doc, updateDocument } from '../../../components/firebase';

import { Card } from '../../../components/Card';
import FormSingleLineTextInput from '../../../components/InputFields/FormSingleLineTextInput';
import { Project } from '../../../models/ProjectModel';
import React from 'react';
import { TwitterTweetType } from '../../../models/TwitterTweetModel';
import { selectProject } from '../../../app/projectSlice';
import { selectTeamId } from '../../../app/teamSlice';
import { useAppSelector } from '../../../app/hooks';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  tweetType: TwitterTweetType;
}

export function CreateNewTwitterTweetDetails({ tweetType }: Props) {
  const currentTeamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);

  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      const docId = updatedProperties.id;
      delete updatedProperties.id;

      await updateDocument(
        doc(db, 'teams', currentTeamId || '', 'projects', docId),
        updatedProperties
      );
    },
    100
  );

  return (
    <Card>
      <div className="flex flex-col justify-start">
        <div className="">
          <FormSingleLineTextInput
            title="Topic"
            text={''}
            placeholder="Twitter marketing tips"
            onChange={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
          />
        </div>

        <div className="mt-4">
          <FormSingleLineTextInput
            title="Tone of Voice"
            text={''}
            placeholder="Authorative, Casual, Funny, etc."
            onChange={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
          />
        </div>
      </div>
    </Card>
  );
}
