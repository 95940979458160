import React from 'react';

export default function Loading() {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <h1>Loading...</h1>
      </div>
    </div>
  );
}
