import {
  ChannelPersonaLink,
  channelPersonaLinkConverter,
} from '../../models/ChannelPersonaLinkModel';
import {
  CustomerPersona,
  customerPersonaConverter,
} from '../../models/CustomerPersonaModel';
import {
  PencilSquareIcon,
  PhotoIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import {
  collection,
  db,
  deleteDocument,
  doc,
  query,
  useCollectionData,
  where,
} from '../../components/firebase';

import BulletPointsCard from '../../components/CardComponents/BulletPointsCard';
import ConfirmationModal from '../../components/ConfirmationModal';
import DropdownMenu from '../../components/DropdownMenu';
import HighlightedListCard from '../../components/CardComponents/HighlightedListCard';
import LandscapeProfileCard from '../../components/CardComponents/LandscapeProfileCard';
import { MarketingChannel } from '../../models/MarketingChannelModel';
import MarketingChannelOverviewCard from '../../components/CardComponents/MarketingChannelOverviewCard';
import ParagraphCard from '../../components/CardComponents/ParagraphCard';
import { saveElementAsPNG } from '../../utils/screenshotUtils';
import { selectProjectId } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';
import { useDocumentData } from 'react-firebase-hooks/firestore';

interface Props {
  marketingChannel: MarketingChannel;
  relatedPersona?: CustomerPersona;
  streamingChannelPersonaLink?: ChannelPersonaLink;
  onClick: () => void;
}

export function MarketingChannelCard({
  marketingChannel,
  relatedPersona,
  streamingChannelPersonaLink,
  onClick,
}: Props) {
  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [displayPersona, setDisplayPersona] = useState<
    CustomerPersona | undefined
  >(relatedPersona);
  const [displayChannelPersonaLink, setDisplayChannelPersonaLink] = useState<
    ChannelPersonaLink | undefined
  >(streamingChannelPersonaLink);

  const [channelPersonaLinks] = useCollectionData<ChannelPersonaLink>(
    marketingChannel.ref
      ? query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            projectId || '',
            'channelPersonaLinks'
          ),
          where('marketingChannel', '==', marketingChannel.ref)
        ).withConverter(channelPersonaLinkConverter)
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [selectedPersona] = useDocumentData<CustomerPersona | undefined>(
    channelPersonaLinks?.[0]
      ? doc(db, channelPersonaLinks?.[0].persona.path || '').withConverter(
          customerPersonaConverter
        )
      : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (selectedPersona) {
      setDisplayPersona(selectedPersona);
    }
  }, [selectedPersona]);

  useEffect(() => {
    if (channelPersonaLinks?.[0]) {
      setDisplayChannelPersonaLink(channelPersonaLinks?.[0]);
    }
  }, [channelPersonaLinks]);

  async function deleteMarketingChannel() {
    await deleteDocument(marketingChannel.ref);
  }

  async function saveAsPNG() {
    const element = document.getElementById(
      `marketing-channel-card-${marketingChannel.id}`
    );
    if (element) {
      await saveElementAsPNG(element, 'marketology-customer-persona');
    }
  }

  const card = (
    <li
      key={marketingChannel.id}
      id={`marketing-channel-card-${marketingChannel.id}`}
      className={`col-span-1 flex flex-col flex-grow w-full bg-slate-100 rounded-xl shadow`}
      onClick={onClick}
    >
      <div className="flex flex-col">
        {/* Title */}

        <div className="flex flex-row flex-wrap align-middle justify-between rounded-md gap-2 mt-1 h-8 text-slate-500">
          <h3 className="my-auto mx-4 text-sm">
            <span className="font-semibold">Marketing Channel</span>
          </h3>

          <div className="flex flex-row align-middle my-auto mr-3">
            <div className={`mr-4 ${isEditing ? '' : 'invisible'}`}>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Done
              </button>
            </div>

            <div className="my-auto">
              <DropdownMenu
                items={[
                  {
                    title: 'Edit',
                    icon: PencilSquareIcon,
                    onClick: () => {
                      setIsEditing(true);
                    },
                  },
                  {
                    title: 'Save as PNG',
                    icon: PhotoIcon,
                    onClick: async () => {
                      if (isEditing) {
                        setIsEditing(false);
                      }
                      setTimeout(() => {
                        saveAsPNG();
                      }, 1000);
                    },
                  },
                  {
                    title: 'Delete',
                    icon: TrashIcon,
                    color: 'text-red-500',
                    onClick: async () => {
                      setDeleteConfirmationOpen(true);
                    },
                  },
                ]}
              />
            </div>
            <ConfirmationModal
              title="Delete Marketing Channel?"
              description="Are you sure you want to delete this marketing channel? You can't undo this action."
              open={deleteConfirmationOpen}
              setOpen={setDeleteConfirmationOpen}
              onConfirm={async () => {
                await deleteMarketingChannel();
              }}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-row p-4 pt-1 gap-4 rounded-xl">
          {/* Left Column */}
          <div className="flex flex-col shrink w-[20%] gap-4">
            <div>
              <MarketingChannelOverviewCard
                title={marketingChannel.title || ''}
                type={marketingChannel.type}
                keyMetrics={[]}
                confidenceRelevanceScore={
                  displayChannelPersonaLink?.confidenceRelevanceScore || -1
                }
                channelRef={marketingChannel.ref}
                channelPersonaLinkRef={displayChannelPersonaLink?.ref}
                isEditing={isEditing}
              />
            </div>

            <div>
              <ParagraphCard
                title="Description"
                description={marketingChannel.description || ''}
                docRef={marketingChannel.ref}
                updatePath="description"
                isEditing={isEditing}
              />
            </div>
          </div>

          {/* Center Column */}
          <div className="flex flex-col shrink w-[40%] gap-4">
            {displayPersona && (
              <div className="">
                <LandscapeProfileCard
                  firstName={displayPersona.firstName}
                  lastName={displayPersona.lastName}
                  jobTitle={
                    displayPersona.career.jobTitle ||
                    displayPersona.career.companyType
                  }
                  imageUrl={displayPersona.imageUrl || ''}
                  demographics={displayPersona.demographics}
                  psychographics={displayPersona.psychographics.attitudes}
                  docRef={displayPersona.ref}
                  updatePath={''}
                  isEditing={isEditing}
                />
              </div>
            )}

            <div className="">
              <BulletPointsCard
                title="Strengths"
                items={marketingChannel.strengths}
                docRef={marketingChannel.ref}
                updatePath="strengths"
                isEditing={isEditing}
              />
            </div>

            <div className="">
              <BulletPointsCard
                title="Challenges"
                items={marketingChannel.challenges}
                docRef={marketingChannel.ref}
                updatePath="challenges"
                isEditing={isEditing}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="flex flex-col w-[40%] gap-4">
            <div className="">
              <HighlightedListCard
                title="Targeting Criteria"
                items={displayChannelPersonaLink?.targetingCriteria || []}
                docRef={displayChannelPersonaLink?.ref}
                updatePath="targetingCriteria"
                isEditing={isEditing}
              />
            </div>

            {marketingChannel.keyMetrics && (
              <div className="flex flex-row gap-4">
                <BulletPointsCard
                  title="Key Metrics"
                  items={marketingChannel.keyMetrics || []}
                  docRef={marketingChannel.ref}
                  updatePath="channelSpecificTips"
                  isEditing={isEditing}
                />
              </div>
            )}

            <div className="flex flex-row gap-4">
              <BulletPointsCard
                title="Channel Specific Tips"
                items={displayChannelPersonaLink?.channelPersonaTips || []}
                docRef={displayChannelPersonaLink?.ref}
                updatePath="channelSpecificTips"
                isEditing={isEditing}
              />
            </div>

            {/* Footer */}
            <div className="flex flex-row flex-wrap align-middle justify-end rounded-md gap-2 text-slate-500">
              <h3 className="text-sm">
                Created with{' '}
                <span className="font-semibold">Marketology.ai</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </li>
  );

  return card;
}
