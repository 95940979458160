import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Pricing from './Plan/Pricing';
import React from 'react';
import SettingsProfile from './Profile/SettingsProfile';
import SettingsTeam from './Team/SettingsTeam';
import { twMerge } from 'tailwind-merge';

const navigationItems = [
  { name: 'My Profile', href: '/settings/profile' },
  { name: 'My Team', href: '/settings/team' },
  { name: 'Billing', href: '/settings/billing' },
];

export default function Settings() {
  let location = useLocation();
  const navigate = useNavigate();

  const isSelected = (index: number) => {
    return index === getSelectedSidebarMenuIndex();
  };

  function getSelectedSidebarMenuIndex() {
    const path = location.pathname;
    return navigationItems.findIndex((item) => path.startsWith(item.href));
  }

  return (
    <div className="flex flex-1 flex-col max-w-4xl mx-auto py-6 px-4 ">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={navigationItems[getSelectedSidebarMenuIndex()]?.name}
        >
          {navigationItems.map((tab, tabIdx) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {navigationItems.map((tab, tabIdx) => (
            <div
              key={tab.name}
              className={twMerge(
                isSelected(tabIdx)
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === navigationItems.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              )}
              aria-current={isSelected(tabIdx) ? 'page' : undefined}
              onClick={() => {
                navigate(tab.href);
              }}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={twMerge(
                  isSelected(tabIdx) ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </div>
          ))}
        </nav>
      </div>

      <div className="mt-8">
        <Routes>
          <Route path="/profile" element={<SettingsProfile />} />
          <Route path="/team" element={<SettingsTeam />} />
          <Route path="/billing" element={<Pricing />} />
        </Routes>
      </div>
    </div>
  );
}
