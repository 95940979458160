import React, { useState } from 'react';

interface Props {
  htmlId?: string;
  htmlName?: string;
  title: string;
  description?: string;
  text?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export default function FormSingleLineTextInput({
  htmlId,
  htmlName,
  title,
  description,
  text = '',
  placeholder,
  onChange,
}: Props) {
  const [localText, setLocalText] = useState(text);

  return (
    <div>
      <label className="block text-md font-medium text-gray-700">
        {title} <span className="text-purple-500">*</span>
      </label>
      <div className="mt-2">
        <input
          id={htmlId}
          name={htmlName}
          type="text"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={placeholder}
          value={localText}
          onChange={(e) => {
            setLocalText(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
}
