import { Problem, problemConverter } from '../../models/ProblemModel';
import React, { useEffect } from 'react';
import {
  auth,
  collection,
  db,
  doc,
  query,
  useCollectionData,
  where,
} from '../../components/firebase';

import { Project } from '../../models/ProjectModel';
import { ProjectFeatureSelection } from './ProjectFeatureSelection';
import { ProjectHeader } from '../../components/ProjectHeader';
import { ProjectProblemSelection } from './ProjectProblemSelection';
import { orderBy } from 'firebase/firestore';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
  project: Project;
  payload: any;
  setPayload: (payload: any) => void;
}

export default function ProjectSelection({
  project,
  payload,
  setPayload,
}: Props) {
  const teamId = useAppSelector(selectTeamId);

  const [problems] = useCollectionData<Problem>(
    project &&
      query(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'problems'
        ),
        where('owner', '==', doc(db, 'users', auth.currentUser?.uid || '')),
        orderBy('createdAt', 'asc')
      ).withConverter(problemConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [features] = useCollectionData<Problem>(
    project &&
      query(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'features'
        ),
        where('owner', '==', doc(db, 'users', auth.currentUser?.uid || '')),
        orderBy('createdAt', 'asc')
      ).withConverter(problemConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (features) {
      let featuresPayload = new Set();
      features?.forEach((eachFeature) => {
        featuresPayload.add(eachFeature.id);
      });
      setPayload({ ...payload, features: featuresPayload });
    }
  }, [features]);

  return (
    <div className="flex flex-col space-y-4">
      {project && (
        <div>
          <div className="m-[-1em]">
            <ProjectHeader shadow={false} />
          </div>
          <div className="mt-5">
            <h2 className="text-2xl pt-4">Problems And Solutions</h2>
            <p className="mt-1 mb-4 text-sm text-gray-500">
              Select the problems and solutions you want to include in this
              Ideal Customer Profile.
            </p>

            <div className="m-[-1em]">
              {problems &&
                problems.map((eachProblem) => (
                  <ProjectProblemSelection
                    key={eachProblem.id}
                    project={project}
                    problem={eachProblem}
                    payload={payload}
                    setPayload={setPayload}
                  />
                ))}
            </div>
          </div>

          <div className="mt-5">
            <h2 className="text-2xl pt-4">Features</h2>
            <p className="mt-1 mb-4 text-sm text-gray-500">
              Do the same for the features you want to include in this Ideal
              Customer Profile.
            </p>

            {features &&
              features.map((eachFeature) => (
                <ProjectFeatureSelection
                  key={eachFeature.id}
                  feature={eachFeature}
                  payload={payload}
                  setPayload={setPayload}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
