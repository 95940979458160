import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

import { Benefit } from '../../models/BenefitModel';
import BulletPointsCard from '../../components/CardComponents/BulletPointsCard';
import ConfirmationModal from '../../components/ConfirmationModal';
import DropdownMenu from '../../components/DropdownMenu';
import { Feature } from '../../models/FeatureModel';
import TitleAndDescriptionCard from '../../components/CardComponents/TitleAndDescriptionCard';
import { deleteDocument } from '../../components/firebase';

interface Props {
  benefit: Benefit;
  realatedFeatures: Feature[];
  onClick: () => void;
}

export function BenefitCard({ benefit, realatedFeatures, onClick }: Props) {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  async function deleteBenefit() {
    await deleteDocument(benefit.ref);
  }

  return (
    <li
      className={`col-span-1 flex flex-col bg-slate-100 rounded-xl shadow`}
      onClick={onClick}
    >
      <div className="flex flex-col">
        {/* Title */}

        <div className="flex flex-row flex-wrap align-middle justify-between rounded-md gap-2 mt-1 h-8 text-slate-500">
          <h3 className="my-auto mx-4 text-sm">
            <span className="font-semibold">Benefit</span>
          </h3>

          <div className="flex flex-row align-middle my-auto mr-3">
            <div className={`mr-4 ${isEditing ? '' : 'invisible'}`}>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Done
              </button>
            </div>

            <div className="my-auto">
              <DropdownMenu
                items={[
                  {
                    title: 'Edit',
                    icon: PencilSquareIcon,
                    onClick: () => {
                      setIsEditing(true);
                    },
                  },
                  {
                    title: 'Delete',
                    icon: TrashIcon,
                    color: 'text-red-500',
                    onClick: async () => {
                      setDeleteConfirmationOpen(true);
                    },
                  },
                ]}
              />
            </div>
            <ConfirmationModal
              title="Delete Benefit?"
              description="Are you sure you want to delete this benefit? You can't undo this action."
              open={deleteConfirmationOpen}
              setOpen={setDeleteConfirmationOpen}
              onConfirm={async () => {
                await deleteBenefit();
              }}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-row p-4 pt-1 gap-4 rounded-xl">
          {/* Left Column */}
          <div className="flex flex-col shrink w-[50%] gap-4">
            <div>
              <TitleAndDescriptionCard
                title={benefit.title || ''}
                description={benefit.description || ''}
                docRef={benefit.ref}
                updatePath="description"
                isEditing={isEditing}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="flex flex-col w-[50%] gap-4">
            {realatedFeatures.length > 0 && (
              <div className="">
                <BulletPointsCard
                  title={`Based On Feature${
                    realatedFeatures.length !== 1 ? 's' : ''
                  }`}
                  items={realatedFeatures.map((feature) => feature.title || '')}
                  docRef={benefit.ref}
                  updatePath="strengths"
                  isEditing={false}
                />
              </div>
            )}

            {/* Footer */}
            <div className="flex flex-row flex-wrap align-middle justify-end rounded-md gap-2 text-slate-500">
              <h3 className="text-sm">
                Created with{' '}
                <span className="font-semibold">Marketology.ai</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
