import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  icon?: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & { title?: string; titleId?: string }
  >;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function PrimaryButton({
  title,
  icon: Icon,
  disabled = false,
  onClick,
}: Props) {
  return (
    <button
      type="button"
      className={twMerge(
        'inline-flex items-center justify-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm',
        disabled
          ? 'cursor-not-allowed bg-gray-300'
          : 'bg-indigo-600 hover:bg-indigo-700'
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon && <Icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
      {title}
    </button>
  );
}
