import React, { useEffect } from 'react';

import { EditorSuggestion } from '../../models/EditorSuggestionModel';
import SuggestedContentCard from './SuggestedContentCard';

interface Props {
  suggestions: EditorSuggestion[];
}

export default function SuggestedContent({ suggestions }: Props) {
  return (
    <div className="h-full overflow-y-scroll">
      <div className="flex flex-col py-12 px-4 gap-4">
        {suggestions.map((eachSuggestion, index) => (
          <SuggestedContentCard
            key={`${index}-${eachSuggestion.content.length}`}
            title={`Suggestion ${index + 1}`}
            content={eachSuggestion.content || ''}
          />
        ))}
      </div>
    </div>
  );
}
