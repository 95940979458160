import React, { useEffect, useState } from 'react';
import {
  WebsitePage,
  websitePageFromStreamingContent,
} from '../../models/WebsitePageModel';
import { getMaxStepsClickable, updateStatus } from '../../utils/utils';

import { ComputerDesktopIcon } from '@heroicons/react/24/solid';
import { Project } from '../../models/ProjectModel';
import { StepStatus } from '../../layout/Steps';
import { StepsModal } from '../../layout/StepsModal';
import WebsitePreview from './WebsitePreview';
import WorkCompleted from '../ProjectDetails/WorkCompleted';
import { getIdToken } from '../../components/firebase';
import { selectTeamId } from '../../app/teamSlice';
import untruncateJson from 'untruncate-json';
import { useAppSelector } from '../../app/hooks';

interface Props {
  project: Project;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function CreateNewLandingPageModal({ project, open, setOpen }: Props) {
  const teamId = useAppSelector(selectTeamId);

  const [currentStep, setCurrentStep] = useState(0);
  const [landingPageId, setLandingPageId] = useState<string | undefined>();

  const [isWorking, setIsWorking] = useState(false);

  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>([
    StepStatus.NotStarted,
    StepStatus.NotStarted,
    StepStatus.NotStarted,
  ]);

  const [streamingContent, setStreamingContent] = useState<WebsitePage>();

  async function addNewWebsitePage(
    projectId: string,
    teamId: string,
    pageType: 'landingPage' | 'blogPost'
  ) {
    setIsWorking(true);
    setCurrentStep(1);
    updateStatus(0, StepStatus.Done, setStepStatuses);
    updateStatus(1, StepStatus.InProgress, setStepStatuses);

    const formattedPayload = {
      projectId,
      teamId,
      pageType,
      problems: {},
      features: [],
    };

    const idToken = await getIdToken();

    if (!idToken) {
      setIsWorking(false);
      setCurrentStep(0);
      return;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + '/websites/create-website-page',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
          },
          body: JSON.stringify(formattedPayload),
        }
      );

      const reader = response?.body?.getReader();
      if (!reader) {
        return;
      }

      let completion = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const chunk = new TextDecoder('utf-8').decode(value);

        completion += chunk;

        const jsonString = untruncateJson(completion);

        try {
          const contentJson = JSON.parse(jsonString);
          const websitePage = websitePageFromStreamingContent({
            title: contentJson.hero.title,
            description: contentJson.hero.subtitle,
            type: 'landingPage',
            content: contentJson,
          });

          setStreamingContent(websitePage);
        } catch (error) {
          console.error('Error parsing the streaming content:', error);
        }
      }

      updateStatus(1, StepStatus.Done, setStepStatuses);
      updateStatus(2, StepStatus.Done, setStepStatuses);
      setCurrentStep(2);
    } catch (error: any) {
      console.error('Error creating the new landing page:', error);
      alert(error.message);
      setCurrentStep(0);
      updateStatus(0, StepStatus.NotStarted, setStepStatuses);
      updateStatus(1, StepStatus.NotStarted, setStepStatuses);
    } finally {
      setIsWorking(false);
    }
  }

  async function handlePageTypeSelected(type: 'landingPage' | 'blogPost') {
    if (
      !teamId ||
      isWorking ||
      stepStatuses[stepStatuses.length - 1] === StepStatus.Done
    ) {
      return;
    }

    await addNewWebsitePage(project.id, teamId, type);
  }

  return (
    <StepsModal
      title="Create New Website Page"
      open={open}
      setOpen={setOpen}
      steps={[
        {
          name: 'Select Page Type',
          description: 'The page type.',
          status: stepStatuses[0],
          children: (
            <div className="py-8">
              <h3 className="text-xl font-medium text-gray-900">
                Select Page Type:
              </h3>

              <ul className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                <li
                  className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center cursor-pointer border border-slate-200`}
                  onClick={() => handlePageTypeSelected('landingPage')}
                >
                  <div className="flex flex-1 flex-col p-8">
                    <div className="mx-auto h-24 w-24 flex-shrink-0 rounded-full overflow-clip">
                      <div className="h-24 w-24 bg-slate-200 rounded-full flex items-center justify-center">
                        <ComputerDesktopIcon className="h-12 w-12 text-white " />
                      </div>
                    </div>
                    <h3 className="mt-6 text-2xl font-medium text-gray-900">
                      Primary Landing Page
                    </h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dd className="text-sm text-gray-500 max-h-60 text-ellipsis overflow-hidden">
                        The primary page of your website that is designed to
                        convert visitors into leads, specifically tailored to
                        your project.
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
          ),
        },
        {
          name: 'Generate Landing Page',
          description: 'Generate the landing page.',
          status: stepStatuses[1],
          children: (
            <div className="py-8 flex flex-col items-center justify-center">
              {streamingContent && (
                <WebsitePreview
                  originalWebsiteContent={streamingContent.content}
                />
              )}
            </div>
          ),
        },
        {
          name: 'Done',
          description: 'All done!.',
          status: stepStatuses[2],
          children: (
            <WorkCompleted
              title={'Your Landing Content Page Is Ready!'}
              subtitle={
                "You can close this now - your new page is in the Website's Content section."
              }
            />
          ),
          footer: (
            <button
              type="button"
              className="mx-auto flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={async () => {
                setOpen(false);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Close'}</p>
              </div>
            </button>
          ),
        },
      ]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      stepsClickable={isWorking || stepStatuses[2] === StepStatus.Done}
      minStepClickable={0}
      maxStepClickable={getMaxStepsClickable(stepStatuses)}
      gradientBackground
    />
  );
}
