import React, { useState } from 'react';

import { EditableTextField } from './EditableTextField';

interface Props {
  title: string;
  index: number;
  isEditing: boolean;
  onValueChange?: (value: string) => void;
  onRemove?: (index: number) => void;
}

export function TextChip({
  title,
  index,
  isEditing,
  onValueChange,
  onRemove,
}: Props) {
  const [localText, setLocalText] = useState(title);

  return (
    // <div className=" bg-gray-200 rounded-full text-gray-900 pt-1.5 pb-1 px-3">
    // <EditableTextField
    //   text={title}
    //   textComponentType="h4"
    //   styleClasses="text-[0.65em] leading-2 font-medium uppercase bg-gray-200"
    //   textareaStyleClasses="hover:bg-slate-300"
    //   index={index}
    //   isEditing={isEditing}
    //   onValueChange={onValueChange}
    //   onRemove={() => {
    //     onRemove?.(index);
    //   }}
    // />
    // </div>
    <div className="flex flex-row bg-gray-200 rounded-full text-gray-900">
      <div className="relative pt-1.5 pb-1 px-3">
        <h4 className="text-[0.65em] leading-3 font-medium uppercase">
          {localText}
        </h4>

        <div className="absolute p-0 m-0 border-0 inset-0 flex-1 justify-center items-center z-1">
          {isEditing && (
            <textarea
              id="description"
              name="description"
              className="py-0 px-3 m-0 w-full h-full border-0 resize-none rounded-full overflow-hidden text-[0.65em] font-medium uppercase bg-gray-200 hover:bg-slate-300"
              defaultValue={title}
              onChange={(e) => {
                setLocalText(e.target.value);
                onValueChange?.(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && localText === '') {
                  onRemove?.(index);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
