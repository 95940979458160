//
// StreamingMarketingChannel
//

import { ChannelPersonaLink } from './ChannelPersonaLinkModel';
import { MarketingChannel } from './MarketingChannelModel';

export type StreamingMarketingChannel = {
  title?: string;
  type: string;
  description?: string;
  keyMetrics: string[];
  strengths: string[];
  challenges: string[];
  optimizationTips: string[];
  personas: [
    {
      id?: string;
      confidenceRelevanceScore?: number;
      targetingCriteria?: string[];
      channelPersonaTips?: string[];
    }
  ];
};

export function streamingMarketingChannelFromStreamingContent(
  partialDocument: any
): StreamingMarketingChannel {
  return {
    title: partialDocument.title || 'Thinking...',
    type: partialDocument.type || '',
    description: partialDocument.description || '',
    keyMetrics: partialDocument.keyMetrics || [],
    strengths: partialDocument.strengths || [],
    challenges: partialDocument.challenges || [],
    optimizationTips: partialDocument.optimizationTips || [],
    personas: partialDocument.personas || [],
  };
}

export function marketingChannelFromStreamingMC(
  streamingMC: any
): MarketingChannel {
  return {
    id: streamingMC.id || '',
    ref: streamingMC.ref || '',
    owner: streamingMC.owner || '',
    title: streamingMC.title || 'Thinking...',
    type: streamingMC.type || '',
    description: streamingMC.description || '',
    keyMetrics: streamingMC.keyMetrics || [],
    strengths: streamingMC.strengths || [],
    challenges: streamingMC.challenges || [],
    optimizationTips: streamingMC.optimizationTips || [],
    createdAt: streamingMC.createdAt || new Date(),
  };
}

export function channelPersonaLinkFromStreamingMC(
  streamingMC: any
): ChannelPersonaLink {
  return {
    id: streamingMC.id || '',
    ref: streamingMC.ref || '',
    owner: streamingMC.owner || '',
    marketingChannel: streamingMC.marketingChannel || '',
    persona: streamingMC.persona || '',
    confidenceRelevanceScore:
      streamingMC.personas?.[0]?.confidenceRelevanceScore || -1,
    targetingCriteria: streamingMC.personas?.[0]?.targetingCriteria || [],
    channelPersonaTips: streamingMC.personas?.[0]?.channelPersonaTips || [],
    createdAt: streamingMC.createdAt || new Date(),
  };
}
