import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore';

import { OnboardingChecklistSteps } from './OnboardingChecklistStepModel';

//
// Users
//

interface User {
  id: string;
  ref: DocumentReference<DocumentData>;
  email: string;
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
  currentTeam?: DocumentReference<DocumentData>;
  onboardingChecklist: OnboardingChecklistSteps;
  intercomHMAC: string;
  createdAt: Date;
}

const userConverter: FirestoreDataConverter<User> = {
  toFirestore(user: WithFieldValue<User>): DocumentData {
    return {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      profileImageUrl: user.profileImageUrl,
      currentTeam: user.currentTeam,
      onboardingChecklist: user.onboardingChecklist,
      intercomHMAC: user.intercomHMAC,
      createdAt: user.createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): User {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      profileImageUrl: data.profileImageUrl,
      currentTeam: data.currentTeam,
      onboardingChecklist: data.onboardingChecklist,
      intercomHMAC: data.intercomHMAC,
      createdAt: data.createdAt,
    };
  },
};

function userFromFirestore(document: any): User {
  return {
    id: document.id,
    ref: document.ref,
    email: document.email,
    firstName: document.firstName,
    lastName: document.lastName,
    profileImageUrl: document.profileImageUrl,
    currentTeam: document.currentTeam,
    onboardingChecklist: document.onboardingChecklist,
    intercomHMAC: document.intercomHMAC,
    createdAt: document.createdAt,
  };
}

// function userToStore(user: User): any {
//   return {
//     id: user.id,
//     ref: user.ref.path,
//     email: user.email,
//     firstName: user.firstName,
//     lastName: user.lastName,
//     profileImageUrl: user.profileImageUrl,
//     currentTeam: user.currentTeam?.path,
//     createdAt: user.createdAt,
//   };
// }

// function storeToUser(user: any): User {
//   return {
//     id: user.id,
//     ref: doc(db, user.ref),
//     email: user.email,
//     firstName: user.firstName,
//     lastName: user.lastName,
//     profileImageUrl: user.profileImageUrl,
//     currentTeam: doc(db, user.currentTeam?.path),
//     createdAt: user.createdAt,
//   };
// }

export type { User };
export { userConverter, userFromFirestore };
