import {
  Middleware,
  createListenerMiddleware,
  isAnyOf,
} from '@reduxjs/toolkit';

import { RootState } from './store';
import { setDocument } from './documentSlice';
import { setSelectedChatSession } from './chatSessionSlice';
import { setSelectedProject } from './projectSlice';
import { setSelectedTeam } from './teamSlice';
import { setUser } from './userSlice';

// single listener middleware for multiple actions
export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isAnyOf(setUser, setSelectedTeam, setSelectedProject),
  effect: (action, listenerApi) => {
    switch (action.type) {
      case setUser.type:
        localStorage.setItem(
          'user',
          JSON.stringify((listenerApi.getState() as RootState).user)
        );
        break;
      case setSelectedTeam.type:
        localStorage.setItem(
          'team',
          JSON.stringify((listenerApi.getState() as RootState).team)
        );
        break;
      case setSelectedProject.type:
        localStorage.setItem(
          'project',
          JSON.stringify((listenerApi.getState() as RootState).project)
        );
        break;
      default:
        break;
    }
  },
});

export const serializerMiddleware: Middleware<{}, RootState> =
  (storeApi) => (next) => (action) => {
    switch (action.type) {
      case setUser.type:
        return next({
          ...action,
          payload: {
            id: action.payload.id,
            ref: action.payload.ref.path,
            email: action.payload.email,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            profileImageUrl: action.payload.profileImageUrl,
            currentTeam: action.payload.currentTeam?.path,
            onboardingChecklist: action.payload.onboardingChecklist,
            intercomHMAC: action.payload.intercomHMAC,
            createdAt: action.payload.createdAt.toDate().toString(),
          },
        });
      case setSelectedTeam.type:
        return next({
          ...action,
          payload: {
            id: action.payload.id,
            ref: action.payload.ref.path,
            title: action.payload.title,
            imageUrl: action.payload.imageUrl,
            websiteUrl: action.payload.websiteUrl,
            subscriptionStatus: action.payload.subscriptionStatus,
            subscriptionPlan: action.payload.subscriptionPlan,
            availableCredits: action.payload.availableCredits,
            createdAt: action.payload.createdAt.toDate().toString(),
          },
        });
      case setSelectedProject.type:
        return next({
          ...action,
          payload: {
            id: action.payload.id,
            ref: action.payload.ref.path,
            owner: action.payload.owner.path,
            title: action.payload.title,
            description: action.payload.description,
            websiteUrl: action.payload.websiteUrl,
            imageUrl: action.payload.imageUrl,
            businessType: action.payload.businessType,
            toneOfVoice: action.payload.toneOfVoice,
            onboardingChecklist: action.payload.onboardingChecklist,
            createdAt: action.payload.createdAt.toDate().toString(),
          },
        });
      case setSelectedChatSession.type:
        return next({
          ...action,
          payload: action.payload
            ? {
                id: action.payload.id,
                ref: action.payload.ref.path,
                title: action.payload.title,
                createdAt: action.payload.createdAt.toString(),
              }
            : null,
        });
      case setDocument.type:
        return next({
          ...action,
          payload: action.payload
            ? {
                id: action.payload.id,
                ref: action.payload.ref.path,
                type: action.payload.type,
                title: action.payload.title,
                content: action.payload.content,
                createdAt: action.payload.createdAt.toString(),
              }
            : null,
        });
      default:
        return next(action);
    }
  };
