import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export async function saveElementAsPNG(element: any, filename: string) {
  const canvas = await html2canvas(element, {
    scale: 2, // Increase the scale for better quality (larger image)
    useCORS: true,
    allowTaint: false,
    logging: true,
    backgroundColor: null,
  });
  const imgData = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = imgData;
  link.download = `${filename}.png`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function saveElementAsPDF(element: HTMLElement, filename: string) {
  const canvas = await html2canvas(element);
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: 'letter',
  });
  pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / 96, canvas.height / 96);
  pdf.save(`${filename}.pdf`);
}
