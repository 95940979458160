import { DocumentReference, updateDocument } from '../firebase';

import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import { ImageDetailsChip } from '../ImageDetailsChip';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { TextChip } from '../TextChip';
import { titleCase } from '../../utils/utils';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  firstName: string;
  lastName: string;
  jobTitle: string;
  imageUrl: string;
  demographics: { [key: string]: string };
  psychographics: string[];
  docRef: DocumentReference;
  updatePath: string;
  isEditing: boolean;
}

export default function LandscapeProfileCard({
  firstName,
  lastName,
  jobTitle,
  imageUrl,
  demographics,
  psychographics,
  docRef,
  updatePath,
  isEditing,
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      // console.log('updatedProperties', updatedProperties);
      await updateDocument(docRef, updatedProperties);
    },
    300
  );

  async function addPsychographic() {
    let properties: any = {};
    properties['description.psychographics.attitudes'] = [
      ...psychographics,
      '',
    ];
    await updateDocument(docRef, properties);
  }

  async function deletePsychographic(index: number) {
    let properties: any = {};
    properties['description.psychographics.attitudes'] = psychographics.filter(
      (item, i) => i !== index
    );
    await updateDocument(docRef, properties);
  }

  return (
    <Card shadow={false}>
      <div className="flex flex-row gap-4">
        {/* Profile Image */}
        <img
          className="rounded-md object-cover w-1/3"
          src={imageUrl}
          alt="Customer persona profile"
        />

        <div className="flex flex-col">
          {/* Name and Job Title */}
          <div className="flex flex-col">
            {/* Name */}
            <div className="flex flex-row justify-center gap-2">
              <EditableTextField
                text={firstName}
                textComponentType="h3"
                styleClasses={
                  'text-2xl font-bold text-gray-700 hover:bg-slate-100'
                }
                isEditing={isEditing}
                onValueChange={(updatedValue) => {
                  let properties: any = {};
                  properties['description.firstName'] = updatedValue;
                  debouncedPropertiesUpdate(properties);
                }}
              />

              <EditableTextField
                text={lastName}
                textComponentType="h3"
                styleClasses={
                  'text-2xl font-bold text-gray-700 hover:bg-slate-100'
                }
                isEditing={isEditing}
                onValueChange={(updatedValue) => {
                  let properties: any = {};
                  properties['description.lastName'] = updatedValue;
                  debouncedPropertiesUpdate(properties);
                }}
              />
            </div>

            {/* Job Title */}
            <div className="mt-2 mx-auto">
              <EditableTextField
                text={jobTitle}
                textComponentType="h4"
                styleClasses={
                  'text-sm font-bold uppercase text-blue-600 text-center hover:bg-slate-100'
                }
                isEditing={isEditing}
                onValueChange={(updatedValue) => {
                  let properties: any = {};
                  properties['description.career.jobTitle'] = updatedValue;
                  debouncedPropertiesUpdate(properties);
                }}
              />
            </div>
          </div>

          {/* Demographics */}
          <div className="flex flex-row flex-wrap justify-center gap-4 mt-6">
            <ImageDetailsChip
              emoji="🗓️"
              text={demographics.age}
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.demographics.age'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />
            <ImageDetailsChip
              emoji="👤"
              text={demographics.gender}
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.demographics.gender'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />
            <ImageDetailsChip
              emoji="💰"
              text={demographics.income}
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.demographics.income'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />
            <ImageDetailsChip
              emoji="📍"
              text={demographics.location}
              isEditing={isEditing}
              onValueChange={(updatedValue) => {
                let properties: any = {};
                properties['description.demographics.location'] = updatedValue;
                debouncedPropertiesUpdate(properties);
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
