import React, { useState } from 'react';

import { AiAction } from './RichTextEditor';
import { Card } from '../Card';
import { EditableTextField } from '../EditableTextField';
import { MagicButton } from '../Buttons/MagicButton';
import { Modal } from '../../layout/Modal';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAiAction: (action: AiAction, prompt?: string) => void;
}

export default function RichTextEditorCustomAiPromptModal({
  open,
  setOpen,
  onAiAction,
}: Props) {
  const [localText, setLocalText] = useState<string>('');

  return (
    <Modal open={open} setOpen={setOpen} width="lg">
      <div className="flex flex-col text-gray-700 gap-y-6">
        <div>
          <h2 className="text-xl font-bold text-gray-700">Custom Prompt</h2>
          <p className="mt-2 text-gray-500">
            Enter a custom prompt for the AI to generate text from.
          </p>
        </div>
        <EditableTextField
          htmlId="custom-ai-prompt"
          htmlName="custom-ai-prompt"
          text=""
          placeholder="e.g. Rephrase it, making it more concise. Also, make it hilarious."
          textComponentType="p"
          styleClasses={
            'block p-3 min-w-full w-full min-h-[100px] rounded-md border border-gray-300 hover:bg-white focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          }
          isEditing={true}
          onValueChange={(updatedValue) => {
            setLocalText(updatedValue);
          }}
        />

        <MagicButton
          title="Generate Text"
          onClick={async () => {
            onAiAction(AiAction.CustomPrompt, localText);
            setOpen(false);
          }}
        />
      </div>
    </Modal>
  );
}
