import {
  DataCollectionBuildingBlock,
  DataCollectionBuildingBlockType,
} from '../../models/DataCollectionBuildingBlockModel';
import {
  DocumentType,
  getStructureTemplateFromDocumentType,
  getTitleFromDocumentType,
} from '../../models/DocumentModel';
import React, { useState } from 'react';

import { Card } from '../../components/Card';
import FormMultiLineTextInput from '../../components/InputFields/FormMultiLineTextInput';
import FormSingleLineTextInput from '../../components/InputFields/FormSingleLineTextInput';

interface Props {
  documentType: DocumentType;
  onPayloadChange?: (payload: any) => void;
}

export function CreateNewGenericDocumentDetails({
  documentType,
  onPayloadChange,
}: Props) {
  const [payload, setPayload] = useState<any>({});

  const structure = getStructureTemplateFromDocumentType(documentType);

  function getComponentForStructureBlock(block: DataCollectionBuildingBlock) {
    switch (block.type) {
      case DataCollectionBuildingBlockType.SingleLineTextInput:
        return (
          <FormSingleLineTextInput
            title={block.title}
            text={''}
            placeholder={block.placeholder}
            onChange={(value: string) => {
              const updatedPayload = { ...payload };
              updatedPayload[block.payloadPath] = value;
              setPayload(updatedPayload);
              onPayloadChange?.(payload);
            }}
          />
        );

      case DataCollectionBuildingBlockType.MultiLineTextInput:
        return (
          <FormMultiLineTextInput
            title={block.title}
            text={block.initialValue}
            placeholder={block.placeholder}
            description={block.description}
            onChange={(value: string) => {
              const updatedPayload = { ...payload };
              updatedPayload[block.payloadPath] = value;
              setPayload(updatedPayload);
              onPayloadChange?.(payload);
            }}
          />
        );
      default:
        break;
    }
  }

  return (
    <Card>
      <div className="flex flex-col justify-start">
        <div className="">
          <div className="mt-2 font-bold">
            {getTitleFromDocumentType(documentType)}
          </div>
        </div>

        {structure.map((block) => {
          return (
            <div className="mt-4">{getComponentForStructureBlock(block)}</div>
          );
        })}
      </div>
    </Card>
  );
}
