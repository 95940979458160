import { DocumentType } from './DocumentModel';

//
// StreamingDocument
//

export enum StreamingDocumentStatus {
  Pending = 'pending',
  InProgress = 'inProgress',
  Done = 'done',
}

export type StreamingDocument = {
  id: string;
  type: DocumentType;
  status: StreamingDocumentStatus;
  title?: string;
  prompt?: any;
  content?: string;
};

export function streamingDocumentFromStreamingContent(
  partialStreamingDocument: any
): StreamingDocument {
  return {
    id: partialStreamingDocument.id,
    type: partialStreamingDocument.type || DocumentType.Generic,
    status: partialStreamingDocument.status || StreamingDocumentStatus.Pending,
    title: partialStreamingDocument.title || '',
    prompt: partialStreamingDocument.prompt || '',
    content: partialStreamingDocument.content || 'Writing in progress...',
  };
}
