import { Dialog, Menu, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';

import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Project } from '../models/ProjectModel';
import { SelectProjectModal } from '../screens/SelectProjectModal/SelectProjectModal';
import { selectProject } from '../app/projectSlice';
import { useAppSelector } from '../app/hooks';

interface Props {}

export function ProjectSelectionDropdown({}: Props) {
  const currentlySelectedProject = useAppSelector(selectProject);

  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  function getProjectTitle() {
    if (!currentlySelectedProject) {
      return 'Select Project';
    } else if (
      currentlySelectedProject.title === undefined ||
      currentlySelectedProject.title.length === 0
    ) {
      return 'Name Your Project';
    } else {
      return currentlySelectedProject.title;
    }
  }

  return (
    <div className="px-2">
      <div
        className="group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100 cursor-pointer"
        onClick={() => {
          setSelectionModalOpen(true);
        }}
      >
        <span className="flex w-full items-center justify-between">
          <span className="flex min-w-0 items-center justify-between space-x-3">
            {/* <img
              className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
              src={
                'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80'
              }
              alt=""
            /> */}
            <span className="flex min-w-0 flex-1 flex-col">
              <span className="text-md font-bold text-gray-900">
                {getProjectTitle()}
              </span>
              {currentlySelectedProject?.businessType && (
                <span className="truncate text-sm text-gray-500">
                  {currentlySelectedProject?.businessType}
                </span>
              )}
            </span>
          </span>
          <ChevronUpDownIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </span>
      </div>

      <SelectProjectModal
        open={selectionModalOpen}
        setOpen={setSelectionModalOpen}
      />
    </div>
  );
}
