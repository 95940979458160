// import 'tippy.js/dist/tippy.css';

import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import {
  EditorSuggestion,
  editorSuggestionFromStreamingContent,
} from '../../models/EditorSuggestionModel';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { getIdToken, updateDocument } from '../firebase';
import {
  selectStreamingDocument,
  setStreamingDocument,
} from '../../app/documentSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Document } from '../../models/DocumentModel';
import Link from '@tiptap/extension-link';
import RichTextEditorBubbleMenu from './RichTextEditorBubbleMenu';
import RichTextEditorCustomAiPromptModal from './RichTextEditorCustomAiPromptModal';
import RichTextEditorMenuBar from './RichTextEditorMenuBar';
import StarterKit from '@tiptap/starter-kit';
import { StreamingDocumentStatus } from '../../models/StreamingDocumentModel';
import SuggestedContent from '../SuggestedContent/SuggestedContent';
import Underline from '@tiptap/extension-underline';
import { selectProjectId } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { streamResponse } from '../../utils/utils';
import untruncateJson from 'untruncate-json';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  document: Document;
  setSidebarView?: (node: ReactNode) => void;
  setSidebarOpen?: (open: boolean) => void;
}

export enum AiAction {
  Rephrase = 'rephrase',
  Shorten = 'shorten',
  Expand = 'expand',
  ImproveFlow = 'improveFlow',
  CustomPrompt = 'custom',
}
export default function RichTextEditor({
  document,
  setSidebarView,
  setSidebarOpen,
}: Props) {
  const teamId = useAppSelector(selectTeamId);
  const projectId = useAppSelector(selectProjectId);
  const streamingDocument = useAppSelector(selectStreamingDocument);

  const [customPromptModalOpen, setCustomPromptModalOpen] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
      Underline,
    ],
    editorProps: {
      attributes: {
        class:
          'prose m-2 mx-3 max-w-full min-h-[400px] h-full focus:outline-none',
      },
    },
    content: document.content || `<p></p>`,
    onUpdate: ({ editor }) => {
      const content = editor.getJSON();
      debouncedUpdate(content);
    },
    onBlur: async ({ editor }) => {
      const content = editor.getJSON();
      await updateDocument(document.ref, { content: content });
    },
  });

  const editorRef = useRef<HTMLDivElement>(null);

  const [isWorking, setIsWorking] = useState(false);
  const [suggestions, setSuggestions] = useState<EditorSuggestion[]>([]);
  const [selectionCoordinates, setSelectionCoordinates] = useState<
    | {
        from: number;
        to: number;
      }
    | undefined
  >();

  const [selectedText, setSelectedText] = useState<string | undefined>(
    undefined
  );

  const [streamingContent, setStreamingContent] = useState<
    string | undefined
  >();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!editor || !streamingDocument) {
      return;
    }

    (async () => {
      if (
        streamingDocument &&
        streamingDocument.status === StreamingDocumentStatus.Pending
      ) {
        setIsWorking(true);
        dispatch(
          setStreamingDocument({
            ...streamingDocument,
            status: StreamingDocumentStatus.InProgress,
          })
        );
        const formattedPayload = {
          teamId,
          projectId,
          documentId: document.id,
          documentType: streamingDocument.type,
          documentTitle: streamingDocument.title,
          documentPrompt: streamingDocument.prompt,
        };

        try {
          const completion = await streamInitialContent(formattedPayload);

          await updateDocument(document.ref, {
            originalPrompt: streamingDocument.prompt,
            content: completion,
          });

          dispatch(setStreamingDocument(undefined));
        } catch (error) {
          console.error('Error streaming document content: ', error);
          dispatch(
            setStreamingDocument({
              ...streamingDocument,
              status: StreamingDocumentStatus.Pending,
            })
          );
        } finally {
          setIsWorking(false);
        }
      }
    })();
  }, [editor, streamingDocument]);

  useEffect(() => {
    if (editor) {
      editor.on('selectionUpdate', handleSelectionChange);
    }
    return () => {
      if (editor) {
        editor.off('selectionUpdate', handleSelectionChange);
      }
    };
  }, [editor]);

  useEffect(() => {
    if (suggestions.length > 0) {
      setSidebarView?.(<SuggestedContent suggestions={suggestions} />);
    }
  }, [suggestions]);

  async function streamInitialContent(payload: any) {
    const idToken = await getIdToken();

    if (!idToken) {
      return;
    }

    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + '/documents/initial-content',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken,
        },
        body: JSON.stringify(payload),
      }
    );

    const reader = response?.body?.getReader();
    if (!reader) {
      return;
    }

    let completion = '';
    let parsedCompletion = '';

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        break;
      }

      const chunk = new TextDecoder('utf-8').decode(value);

      completion += chunk;

      const jsonString = untruncateJson(completion);
      try {
        const json = JSON.parse(jsonString);
        parsedCompletion = json.content;
        editor?.commands.setContent(parsedCompletion);
        setStreamingContent(parsedCompletion);
      } catch (error) {
        console.error('Error parsing json: ', error);
      }
    }

    return parsedCompletion;
  }

  function handleSelectionChange() {
    if (editor && editorRef.current) {
      const { from, to } = editor.state.selection;
      if (from !== to) {
        setSelectionCoordinates({ from, to });
        const selectedText = editor.state.doc.textBetween(from, to);
        setSelectedText(selectedText);
      }
    }
  }

  async function handleAiAction(action: AiAction, userPrompt?: string) {
    let customPrompt = userPrompt;

    if (action === AiAction.CustomPrompt && !customPrompt) {
      setCustomPromptModalOpen(true);
      return;
    }

    setSidebarView?.(
      <SuggestedContent
        suggestions={[editorSuggestionFromStreamingContent({})]}
      />
    );
    setSidebarOpen?.(true);

    setIsWorking(true);

    const formattedPayload = {
      teamId,
      projectId,
      documentId: document.id,
      documentContent: editor?.getHTML(),
      documentPrompt: {
        actionType: action,
        selectionCoordinates: selectionCoordinates,
        selectedText: selectedText,
        customPrompt: customPrompt,
      },
    };

    try {
      await streamResponse(
        '/documents/update-content',
        formattedPayload,
        (item: any) => {
          return editorSuggestionFromStreamingContent(item);
        },
        setSuggestions
      );
    } catch (error: any) {
      console.error('Error creating the top ideal customer profiles:', error);
      alert(error.message);
    } finally {
      setIsWorking(false);
    }
  }

  const debouncedUpdate = useDebouncedCallback(async (content) => {
    await updateDocument(document.ref, { content: content });
  }, 1000);

  return (
    <div className="h-full flex flex-col justify-center">
      <div
        ref={editorRef}
        className="flex flex-col justify-center h-full min-h-full mx-auto mt-4 rounded-t-md overflow-clip"
      >
        <div className="flex flex-row items-center bg-white max-w-3xl">
          <div className="mx-auto">
            <RichTextEditorMenuBar
              editor={editor}
              isAiWorking={isWorking}
              onAiAction={async (action) => {
                await handleAiAction(action);
              }}
            />
          </div>
        </div>
        <div className="border-0 border-b border-gray-200 " />
        {editor && (
          <BubbleMenu
            editor={editor}
            tippyOptions={{
              duration: 100,
              maxWidth: 500,
              theme: 'editor-bubble-menu',
              placement: 'bottom',
              arrow: false,
              offset: [0, 0],
              zIndex: 40,
            }}
          >
            <div className="bg-white p-2 shadow-xl rounded-md">
              <RichTextEditorBubbleMenu
                editor={editor}
                isAiWorking={isWorking}
                onAiAction={async (action) => {
                  await handleAiAction(action);
                }}
              />
            </div>
          </BubbleMenu>
        )}
        {editor && (
          <div className="h-full overflow-auto">
            <div className="h-full max-w-3xl mx-auto">
              <EditorContent
                editor={editor}
                style={{
                  padding: '8px 8px 32px 8px',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '6px',
                  borderBottomRightRadius: '6px',
                }}
              />
            </div>
          </div>
        )}
      </div>

      <RichTextEditorCustomAiPromptModal
        open={customPromptModalOpen}
        setOpen={setCustomPromptModalOpen}
        onAiAction={async (action: AiAction, prompt?: string | undefined) => {
          await handleAiAction(action, prompt);
        }}
      />
    </div>
  );
}
