import { EditableTextField } from './EditableTextField';

interface Props {
  emoji?: string;
  text?: string;
  isEditing: boolean;
  onValueChange?: (value: string) => void;
  onRemove?: (index: number) => void;
}

export function ImageDetailsChip({
  emoji,
  text = '',
  isEditing,
  onValueChange,
  onRemove,
}: Props) {
  return (
    <div className="flex flex-row gap-1 text-sm text-gray-900">
      {emoji && <h4 className="font-medium">{emoji}</h4>}
      <EditableTextField
        text={text}
        textComponentType="h4"
        styleClasses={'text-sm font-medium text-gray-900'}
        isEditing={isEditing}
        onValueChange={onValueChange}
      />
    </div>
  );
}
