import React, { useState } from 'react';

import ProjectFeaturesSection from './ProjectFeaturesSection';
import { ProjectHeader } from '../../components/ProjectHeader';
import ProjectProblemsSection from './ProjectProblemsSection';
import { ProjectWizardModal } from './ProjectWizardModal';
import { SelectProjectModal } from '../SelectProjectModal/SelectProjectModal';
import { selectProject } from '../../app/projectSlice';
import { useAppSelector } from '../../app/hooks';

export default function ProjectDetails() {
  const project = useAppSelector(selectProject);
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);
  const [wizardModalOpen, setWizardModalOpen] = useState(false);

  return (
    <div className="flex flex-col max-w-4xl mx-auto py-6 px-4 space-y-4">
      {!project && (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No project selected
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            Start by selecting a project.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setSelectionModalOpen(true)}
            >
              Select Your Project
            </button>
          </div>

          <SelectProjectModal
            open={selectionModalOpen}
            setOpen={setSelectionModalOpen}
          />
        </div>
      )}
      {project && (
        <div>
          <div className="flex flex-col">
            <button
              type="button"
              className="mb-8 mx-auto h-full flex flex-col rounded-xl shadow bg-indigo-600 text-white cursor-pointer"
              onClick={() => {
                setWizardModalOpen(true);
              }}
            >
              <div className="m-auto py-4 px-8">
                <p>{'Start The Wizard -> 🪄🎩'}</p>
              </div>
            </button>

            <ProjectWizardModal
              open={wizardModalOpen}
              setOpen={setWizardModalOpen}
            />
          </div>

          <ProjectHeader />

          <div className="mt-5">
            <ProjectProblemsSection />
          </div>

          <div className="my-5">
            <ProjectFeaturesSection />
          </div>
        </div>
      )}
    </div>
  );
}
