import { Problem, problemConverter } from '../../models/ProblemModel';
import React, { useEffect } from 'react';
import {
  addDocument,
  auth,
  collection,
  db,
  doc,
  query,
  useCollectionData,
  where,
} from '../../components/firebase';

import { PlusIcon } from '@heroicons/react/24/outline';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import { ProjectProblem } from './ProjectProblem';
import { orderBy } from 'firebase/firestore';
import { selectProject } from '../../app/projectSlice';
import { selectTeamId } from '../../app/teamSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
  onProblemCountChange?: (count: number) => void;
}

export default function ProjectProblemsSection({
  onProblemCountChange,
}: Props) {
  const teamId = useAppSelector(selectTeamId);
  const project = useAppSelector(selectProject);

  const [problems, problemsLoading, problemsError, problemsSnapshot] =
    useCollectionData<Problem>(
      project &&
        query(
          collection(
            db,
            'teams',
            teamId || '',
            'projects',
            project?.id || '',
            'problems'
          ),
          where('owner', '==', doc(db, 'users', auth.currentUser?.uid || '')),
          orderBy('createdAt', 'asc')
        ).withConverter(problemConverter),
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );

  useEffect(() => {
    onProblemCountChange?.(problems?.length || 0);
  }, [problems]);

  async function addNewProblem() {
    const newProblem = {
      title: '',
    };

    try {
      await addDocument(
        collection(
          db,
          'teams',
          teamId || '',
          'projects',
          project?.id || '',
          'problems'
        ),
        newProblem
      );
    } catch (error) {
      console.error('Error creating the new problem:', error);
    }
  }

  return (
    <div className="flex flex-col space-y-4">
      {project && (
        <div className="mt-5">
          <h2 className="text-2xl pt-4">Problems And Solutions</h2>
          <p className="mt-1 text-sm text-gray-500">
            What problems does this project solve?
          </p>

          {problems &&
            problems.map((eachProblem) => (
              <div key={'problem-' + eachProblem.id} className="mt-2">
                <ProjectProblem project={project} problem={eachProblem} />
              </div>
            ))}

          <div className="flex justify-center mt-5">
            <PrimaryButton
              title="New Problem"
              icon={PlusIcon}
              onClick={async () => {
                await addNewProblem();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
