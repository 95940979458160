import {
  OnboardingChecklistStepType,
  getDetailsForChecklistStepType,
} from '../../models/OnboardingChecklistStepModel';

import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

interface Props {
  stepType: OnboardingChecklistStepType;
  completed: boolean;
  onClick?: (stepType: OnboardingChecklistStepType) => void;
}

export default function OnboardingChecklistItem({
  stepType,
  completed,
  onClick,
}: Props) {
  const { title, description, link } = getDetailsForChecklistStepType(stepType);
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        'bg-white hover:bg-gray-50',
        !completed ? 'cursor-pointer' : ''
      )}
      onClick={() => {
        if (completed || !link) {
          return;
        }
        navigate(link);
        onClick?.(stepType);
      }}
    >
      <div
        className={twMerge(
          completed ? 'bg-green-700 bg-opacity-30' : '',
          'relative flex px-5 py-4 focus:outline-none'
        )}
      >
        <div className="flex gap-x-4 w-full items-center justify-start">
          <div className="shrink-0 text-white">
            <CheckIcon completed={completed} />
          </div>

          <div className="flex flex-col items-start text-sm">
            <p
              className={`font-semibold  ${
                completed ? 'text-green-900 line-through' : 'text-gray-900'
              }`}
            >
              {title}
            </p>
            {!completed && (
              <span
                className={`inline mt-1 ${
                  completed ? 'text-green-800 line-through' : 'text-gray-500'
                }`}
              >
                <span>{description}</span>{' '}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckIcon({ completed }: { completed: boolean }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" className="h-5 w-5">
      {completed ? (
        <>
          <circle cx={12} cy={12} r={12} fill="rgb(22 101 52)" />
          <path
            d="M7 13l3 3 7-7"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <circle cx={12} cy={12} r={12} fill="#efefef" />
      )}
    </svg>
  );
}
